import { useMutation, UseMutationOptions } from "react-query";
import axios, { AxiosError } from "axios";
import client from "../client";
import { ENDPOINTS, KNOCKOUT } from "../../constants";
import { ITournamentDay } from "./useCreateTournamentMutation";

export interface IEditTournamentOptions {
  name: string;
  street: string;
  postalCode: string;
  streetNumber: number;
  city: string;
  addition?: string;
  teamsPerPoule: number;
  playingFields: number;
  matchDurationMinutes: number;
  swapTimeMinutesPoules: number;
  swapTimeMinutesKnockout: number;
  knockoutStart: KNOCKOUT;
  extraPouleRound: boolean;
  breakTimeMinutes?: number;
  days: ITournamentDay[];
}

function useEditTournamentMutation(
  tournamentId: string,
  options?: UseMutationOptions<any, AxiosError, IEditTournamentOptions>
) {
  return useMutation(
    ["tournament/edit", tournamentId],
    (_data: IEditTournamentOptions) => {
      let data = { ..._data };
      // @ts-ignore
      data.days = data.days.map(({ startDate, ...d }, index) => ({ ...d, tournamentDay: index, date: startDate }));
      return axios.all([client.patch(ENDPOINTS.TOURNAMENT_EDIT.replace("{tournamentId}", tournamentId), data)]);
    },
    options
  );
}

export default useEditTournamentMutation;
