import React, { useEffect, useMemo, useState } from "react";
import { isNumber } from "lodash";
import { ScoreInput } from "./scoreInput";
import { SaveIcon, EditIcon } from "components/icons";
import classNames from "classnames";
import useStore from "../../../../store";
import { IMatchScore } from "../../../../data/api/types";

interface IGameScoreProps {
  id: string;
  value1: number | null;
  value2: number | null;
  matchToEdit?: string;
  onEdit(matchId: string): void;
  onConfirmEdit(data: IMatchScore): void;
  isConfirmingEdit: boolean;
  isFieldNumberChanged: boolean;
}

export const GameScore: React.VFC<IGameScoreProps> = ({
  id,
  isConfirmingEdit,
  value1,
  value2,
  matchToEdit,
  onEdit,
  onConfirmEdit,
  isFieldNumberChanged,
}) => {
  const tournamentId = useStore((s) => s.tournamentId!);
  const [value, setValue] = useState({ value1, value2 });

  useEffect(() => {
    setValue({ value1, value2 });
  }, [value1, value2]);

  const handleConfirmEdit = () => {
    if (value.value1 !== null || value.value2 !== null || isFieldNumberChanged) {
      onConfirmEdit({ team1Score: value.value1, team2Score: value.value2, id, tournamentId });
    }
  };

  const canSubmit = useMemo(
    () => (isNumber(value.value1) && isNumber(value.value2)) || isFieldNumberChanged,
    [value, isFieldNumberChanged]
  );

  return (
    <>
      <td className="py-4">
        <ScoreInput disabled={id !== matchToEdit} value1={value.value1} value2={value.value2} onChange={setValue} />
      </td>
      <td className="px-3 py-4">
        <div className="w-full flex justify-center">
          <div className="w-9 h-9 bg-app-purple-ultra-light border border-app-purple-primary rounded-full hover:bg-app-purple-primary">
            {id === matchToEdit ? (
              <SaveIcon
                onClick={handleConfirmEdit}
                className={classNames(
                  "w-9 h-9 text-app-purple-primary hover:text-white cursor-pointer",
                  isConfirmingEdit ? "cursor-loading" : canSubmit ? "cursor-pointer" : "cursor-not-allowed"
                )}
                aria-disabled={isConfirmingEdit}
              />
            ) : (
              <EditIcon
                onClick={() => onEdit(id)}
                className="w-9 h-9 text-app-purple-primary hover:text-white cursor-pointer"
              />
            )}
          </div>
        </div>
      </td>
    </>
  );
};
