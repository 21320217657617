import React, { ChangeEvent } from "react";
import classNames from "classnames";
import { isNumber } from "lodash";

interface ScoreInputProps {
  value1: number | null;
  value2: number | null;
  disabled: boolean;
  onChange(data: { value1: number | null; value2: number | null }): void;
}

export const ScoreInput: React.VFC<ScoreInputProps> = ({ value1, value2, disabled, onChange }) => {
  const handleOnChange = (type: "1" | "2") => (evt: ChangeEvent<HTMLInputElement>) => {
    if (type === "1") {
      onChange({ value1: Number(evt.target.value), value2 });
    } else {
      onChange({ value2: Number(evt.target.value), value1 });
    }
  };

  const hasValue = isNumber(value1) && isNumber(value2);

  return (
    <div className="flex justify-end">
      <div
        className={classNames("flex items-center justify-between w-20 rounded-sm", {
          "border border-app-dark-light bg-white": !disabled || !hasValue,
          "bg-app-green-primary": disabled && hasValue,
        })}
      >
        <input
          type="number"
          name="team1Score"
          id="team1Score"
          maxLength={2}
          value={value1 ?? undefined}
          placeholder="-"
          className={classNames(
            "appearance-none block border-0  w-9 text-center focus:outline-none focus:ring-0 bg-transparent px-2 py-2 rounded sm:text-sm text-base",
            {
              "text-app-dark-primary placeholder-app-dark-primary": !disabled && !hasValue,
              "text-white placeholder-app-dark-light": disabled && hasValue,
            }
          )}
          readOnly={disabled}
          onChange={handleOnChange("1")}
        />
        <span
          className={classNames("text-center", {
            "text-app-dark-primary": !disabled && !hasValue,
            "text-app-dark-light": disabled && hasValue,
          })}
        >
          |
        </span>
        <input
          type="number"
          name="team2Score"
          id="team2Score"
          maxLength={2}
          value={value2 ?? undefined}
          placeholder="-"
          readOnly={disabled}
          className={classNames(
            "appearance-none block border-0  w-9 text-center focus:outline-none focus:ring-0 bg-transparent px-2 py-2 rounded sm:text-sm text-base",
            {
              "text-app-dark-primary placeholder-app-dark-primary": !disabled && !hasValue,
              "text-white placeholder-app-dark-light": disabled && hasValue,
            }
          )}
          onChange={handleOnChange("2")}
        />
      </div>
    </div>
  );
};
