import React, { useEffect, useMemo, useState } from "react";
import { ScoreInput } from "./scoreInput";
import useStore from "../../../../store";
import { RefereeSelector } from "./selectReferee";

interface IGameScoreProps {
  id: string;
  refereeId: string;
  updateMatchScore(matchId: string, value1: number | null, value2: number | null): void;
  updateFieldAndReferee(matchId: string, refereeId: string, field: number | null): void;
  value1: number | null;
  value2: number | null;
  field: number;
  referees: string[];
  disabled: boolean;
}

export const EditableTableGroup: React.VFC<IGameScoreProps> = ({
  id,
  refereeId,
  updateFieldAndReferee,
  updateMatchScore,
  value1,
  value2,
  field,
  referees,
  disabled,
}) => {
  const tournamentId = useStore((s) => s.tournamentId!);
  const [selectedRefereeId, setSelectedRefereeId] = useState(refereeId);
  const [fieldNumber, setFieldNumber] = useState(typeof field === "number" ? field + 1 : null);

  const handleOnChangeScore = (value1: number | null, value2: number | null) => {
    updateMatchScore(id, value1, value2);
  };

  const handleChangeReferee = (referee: string) => {
    updateFieldAndReferee(id, referee, fieldNumber);
  };

  const handleChangeField = (fieldNumber: number | null) => {
    updateFieldAndReferee(id, refereeId, fieldNumber || null);
  };

  function isEmpty(value: any) {
    return typeof value === "undefined" || value === null || value === "";
  }

  return (
    <>
      <td className="py-4">
        {disabled ? (
          <span>{selectedRefereeId}</span>
        ) : (
          <RefereeSelector
            disabled={disabled}
            selectedReferee={selectedRefereeId}
            onChange={handleChangeReferee}
            referees={referees}
          />
        )}
      </td>
      <td className="py-4">
        <input
          className={`
           block border-app-dark-light w-11  text-center focus:outline-none focus:ring-0 bg-transparent px-2 py-2  sm:text-sm text-base
          rounded-sm border ${disabled ? "border-none " : "bg-white appearance-none"}  `}
          placeholder="-"
          // @ts-ignore
          value={typeof fieldNumber === "number" ? fieldNumber : ""}
          type="number"
          min={1}
          disabled={disabled}
          onChange={(e) => {
            setFieldNumber(parseInt(e.target.value, 10));
            handleChangeField(parseInt(e.target.value, 10));
          }}
        ></input>
      </td>
      <td className="py-4">
        <ScoreInput
          disabled={false}
          value1={value1}
          value2={value2}
          onChange={(data) => {
            handleOnChangeScore(data.value1, data.value2);
          }}
        />
      </td>
    </>
  );
};
