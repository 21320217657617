import client from "../client";
import { useQuery } from "react-query";
import { ENDPOINTS } from "../../constants";
const QUERY_KEY = "referees";

export function useReferees(tournamentId: string, options?: {}) {
  return useQuery(
    [QUERY_KEY, tournamentId],
    async () => {
      const { data } = await client.get(ENDPOINTS.TOURNAMENT_REFEREE.replace("{tournamentId}", tournamentId));
      // console.log(data);
      return data || [];
    },
    options
  );
}

/* JOOST */