import React, { forwardRef } from "react";

export const EditIcon = forwardRef<SVGSVGElement, React.ComponentPropsWithoutRef<"svg">>((props, ref) => {
  return (
    <svg
      {...props}
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      aria-hidden="true"
      viewBox="0 0 36 36"
    >
      <path
        fillRule="evenodd"
        d="M24.664 9.127a1.667 1.667 0 011.816.361l.032.033a1.666 1.666 0 010 2.355l-.51.51a.952.952 0 01-1.346 0l-1.043-1.042a.951.951 0 010-1.346l.51-.51c.155-.155.339-.277.54-.361zm-2.224 2.438c.252 0 .495.1.673.279l1.043 1.043a.951.951 0 010 1.346L12.351 26.01a.951.951 0 01-.31.205l-1.727.714a.952.952 0 01-1.243-1.243l.714-1.728a.952.952 0 01.206-.309l11.774-11.803a.952.952 0 01.674-.28z"
        clipRule="evenodd"
      />
    </svg>
  );
});
