import { useTeamsQuery } from "data/api/useTeams";
import { useEffect, useState } from "react";
import useStore from "store";

export const IsMixedPoule = () => {
  const [isMixed, setIsMixed] = useState(false);
  const { tournamentId, addError } = useStore((s) => s);
  const { data: tournamentData, isLoading, refetch } = useTeamsQuery(tournamentId!, { enabled: !!tournamentId });

  useEffect(() => {
    if (tournamentData?.men.length && tournamentData?.women.length) {
      setIsMixed(true);
    }
  }, [tournamentData, isLoading]);

  return [isMixed];
};
