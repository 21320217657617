import React from "react";
import { IMatchScore, IKnockoutGame } from "../../../data/api/types";
import { GameScore } from "../components/gameScore";
import { useKnockoutGamesTable } from "./useKnockoutGamesTable";

interface IKnockoutGamesTableProps {
  data: IKnockoutGame[];
  loading: boolean;
  matchToEdit?: string;
  onEdit(matchId: string): void;
  onConfirmEdit(data: IMatchScore): void;
  isConfirmingEdit: boolean;
  updateData(index: number, id: string, value: number): void;
  isFieldNumberChanged: boolean;
}

export const KnockoutGamesTable: React.VFC<IKnockoutGamesTableProps> = ({
  data,
  loading,
  matchToEdit,
  onEdit,
  onConfirmEdit,
  isConfirmingEdit,
  updateData,
  isFieldNumberChanged,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useKnockoutGamesTable(
    data,
    updateData,
    matchToEdit
  );

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto">
        <div className="py-2 align-middle inline-block min-w-full">
          <div className="overflow-y-auto sm:rounded-md">
            <table {...getTableProps()} className="min-w-full">
              <thead className="bg-neutral-200 sticky top-0">
                <tr>
                  {headerGroups.map((headerGroup) =>
                    headerGroup.headers.map((column) => (
                      <th
                        scope="col"
                        className="px-6 py-3 text-left font-semibold text-app-dark-primary tracking-wider"
                        {...column.getHeaderProps()}
                      >
                        {column.render("Header")}
                      </th>
                    ))
                  )}

                  <th scope="col" className="relative py-3 flex justify-end">
                    <span className="w-20 flex justify-start">
                      <span>Uitslag</span>
                    </span>
                  </th>
                  <th scope="col" className="relative  px-3 py-3">
                    <span className="sr-only">scroe</span>
                  </th>
                </tr>
              </thead>
              <tbody {...getTableBodyProps()} className="bg-white">
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            className="px-6 py-4 whitespace-nowrap text-base text-app-dark-primary"
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                      <GameScore
                        key={row.original.id}
                        id={row.original.id}
                        value1={row.original.team1Score}
                        value2={row.original.team2Score}
                        matchToEdit={matchToEdit}
                        onEdit={onEdit}
                        onConfirmEdit={onConfirmEdit}
                        isConfirmingEdit={isConfirmingEdit}
                        isFieldNumberChanged={isFieldNumberChanged}
                      />
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {!rows.length && (
              <tr className="flex items-center justify-center py-3 capitalize">
                {loading ? "loading" : "Geen teams om weer te geven!"}
              </tr>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
