import React, { useMemo, useState } from "react";
import { RouteChildrenProps } from "react-router";
import { usePoolsQuery } from "../../../data/api/usePools";
import useStore from "../../../store";
import { IPoolTeam, TeamClass } from "../../../data/api/types";
import { map, sortBy, uniqBy } from "lodash";
import { IsMixedPoule } from "../utils/helpers/isMixedPouleChecker";
import { ToggleButtons } from "components/ToggleButtons";
import useTournamentSettingsQuery from "data/api/useTournamentSettingsQuery";
import { Modal } from "components/modal";
import { SwapTeamsModal } from "./swapTeamsDialog";
import { useSwapTeamsMutation } from "data/api/useTeams/useSwapTeams";
import { useTournamentStatus } from "data/api/useTournamentStatus";
import { useWindowSize } from "hooks/useWindowSize";

type tPools = {
  id: string;
  name: string;
  teams: IPoolTeam[];
};

function isOdd(num: number) {
  return num % 2;
}

const PoolsPage: React.VFC<RouteChildrenProps> = () => {
  const { tournamentId, addError } = useStore((s) => s);
  const { data, isLoading, refetch } = usePoolsQuery(tournamentId!, { enabled: !!tournamentId });
  const { data: tournamentData, isLoading: tournamentDataIsLoading } = useTournamentSettingsQuery(tournamentId!, {
    enabled: !!tournamentId,
  });
  const {width, height} = useWindowSize();

  const tournamentStatus = useTournamentStatus(tournamentId || "");

  const { mutateAsync: swapTeam } = useSwapTeamsMutation({
    onError: (err) => {
      addError(err.response?.data.message);
    },
  });
  const [teamClass, setTeamClass] = useState(TeamClass.MEN);
  const [isMixed] = IsMixedPoule();
  const [swapModalIsOpen, setSwapModalIsOpen] = useState(false);

  const poolOptions = useMemo(() => {
    let selectedPools = data?.[teamClass];
    if (selectedPools?.length === 0) {
      if (teamClass === TeamClass.WOMEN) {
        selectedPools = data?.men;
        setTeamClass(TeamClass.MEN);
      } else if (teamClass === TeamClass.MEN) {
        selectedPools = data?.women;
        setTeamClass(TeamClass.WOMEN);
      }
    }
    if (selectedPools) {
      const options = sortBy<tPools>(
        uniqBy(
          map(selectedPools, (p) => ({ id: p.name, name: `Poule ${p.name}`, teams: p.teams })),
          "id"
        ),
        (p) => p.id
      );

      return options;
    }
  }, [data, teamClass]);

  // const tournamentStarted = useMemo(() => {
  //   if (tournamentData && tournamentData.days.length) {
  //     const sortedDays = tournamentData.days.sort((a: ITournamentDay, b: ITournamentDay) => {
  //       // @ts-ignore
  //       return a.startDate - b.startDate;
  //     });
  //     return !moment().isBefore(moment(sortedDays[0].startDate).add(moment.duration(sortedDays[0].startTime)));
  //   } else {
  //     return false;
  //   }
  // }, [tournamentData]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          overflowY: "auto",
          height: "calc(100vh - 220px)",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
        className="w-full space-y-6 flex flex-col"
      >
        {isMixed && (
          <ToggleButtons<TeamClass>
            secondary
            onChange={setTeamClass}
            value={teamClass}
            buttons={[
              { value: TeamClass.MEN, label: "Heren" },
              { value: TeamClass.WOMEN, label: "Dames" },
            ]}
          />
        )}
        <div className={`grid  gap-7 md:grid-cols-2 ${width && width > 1400 ? "lg:grid-cols-4" : "lg:grid-cols-3"} xxl:grid-cols-4 max-h-[300px] overflow-y `}>
          {poolOptions &&
            poolOptions.map((poule) => {
              return (
                <div
                  // @ts-ignore
                  style={{
                    boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.05)",
                  }}
                  className="border border-neutral-500  rounded-lg "
                >
                  <div className="text-purple-primary text-lg py-3 px-4  ">{poule.name}</div>
                  <div className="">
                    {poule.teams &&
                      poule.teams.map((team, index) => {
                        return (
                          <div
                            className={`
                            ${isOdd(index) ? "bg-white" : "bg-neutral-100"} 
                            ${poule.teams.length - 1 === index ? "rounded-lg" : ""}
                            px-4 py-3 text-md  

                            `}
                          >
                            {team.team.name}
                          </div>
                        );
                      })}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="w-full  bg-neutral-100 absolute bottom-0  right-0 space-y-2 py-4  ">
        <div className="flex justify-end px-20">
          <div
            onClick={() => {
              if (tournamentStatus && !tournamentStatus.data?.tournamentStarted) {
                setSwapModalIsOpen(true);
              }
            }}
            className={`${
              tournamentStatus && tournamentStatus.data?.tournamentStarted
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-purple-primary cursor-pointer"
            }  px-8   py-3 text-white rounded-md`}
          >
            AANPASSEN
          </div>
        </div>
      </div>
      <Modal
        close={() => {
          setSwapModalIsOpen(!swapModalIsOpen);
        }}
        open={swapModalIsOpen}
      >
        <SwapTeamsModal
          poules={data?.[teamClass] || []}
          onSave={async (teamA, teamB) => {
            await swapTeam({ data: { team_a_id: teamA, team_b_id: teamB } }).then(() => setSwapModalIsOpen(false));
            refetch();
          }}
          onClose={() => {
            setSwapModalIsOpen(false);
          }}
        />
      </Modal>
    </>
  );
};

export default PoolsPage;
