import React, { useMemo } from "react";
import { ToggleButtons } from "../../../components/ToggleButtons";
import { TeamClass } from "../../../data/api/types";
import { Select } from "components/Select";
import { Button } from "components/Button/Button";
import { MultiSelect } from "../../../components/MultiSelect";

export interface IFilters {
  date: number[];
  poule: string[];
  team: string[];
  referee: string[];
  fieldNumber: number[];
}

export type TOption<T extends string[] | number[]> = { id: "all" | T[number]; name: string; display?: string }[];
export type TFilterOptions = { [K in keyof IFilters]: TOption<IFilters[K]> };

interface IMatchesPageControlsProps {
  toggleTeamClass(teamClass: TeamClass): void;
  teamClass: TeamClass;
  selectedData: any[];
  onExport(): void;
  onChange(opts: Partial<TFilterOptions>): void;
  options: TFilterOptions;
  selected: TFilterOptions;
  isMixedPoule: boolean;
}

export const MatchesPageControls: React.VFC<IMatchesPageControlsProps> = ({
  toggleTeamClass,
  teamClass,
  selectedData,
  onExport,
  onChange,
  options,
  selected,
  isMixedPoule,
}) => {
  const canExport = useMemo(() => !!Object.keys(selectedData).length, [selectedData]);

  return (
    <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between sm:space-y-0 space-y-3 relative z-50">
      <div className="flex flex-1 items-center space-x-3">
        <div className="-mt-2">
          {isMixedPoule && (
            <ToggleButtons<TeamClass>
              secondary
              onChange={toggleTeamClass}
              value={teamClass}
              buttons={[
                { value: TeamClass.MEN, label: "Heren" },
                { value: TeamClass.WOMEN, label: "Dames" },
              ]}
            />
          )}
        </div>
        {options &&
          Object.entries(options).map(([key, selectOptions], index) =>
            index > 0 ? (
              <MultiSelect
                key={key}
                //@ts-ignore
                options={selectOptions}
                //@ts-ignore
                value={selected[key as keyof typeof selected]}
                onSelect={(val) => onChange({ [key]: val })}
              />
            ) : (
              <Select
                key={key}
                //@ts-ignore
                options={selectOptions}
                //@ts-ignore
                value={selected[key as keyof typeof selected][0]}
                onSelect={(val) => onChange({ [key]: [val] })}
              />
            )
          )}
      </div>
      <Button
        secondary
        className="self-end"
        label="Exporteer wedstrijdkaarten"
        onClick={onExport}
        disabled={!canExport}
      />
    </div>
  );
};
