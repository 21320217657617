import React from "react";
import { RouteChildrenProps } from "react-router";
import { Navbar } from "../../components/Navbar";
import { Redirect, Route, Switch } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import TeamsPage from "./teams";
import PoolsPage from "./pools";
import ToernooiOverzichtPage from "./toernooi-overview";
import KnockoutsPage from "./knockouts";
import MatchesPage from "./matches";

const DashboardPage: React.VFC<RouteChildrenProps> = (props) => {
  return (
    <div className="flex min-h-screen sm:px-20 sm:py-10">
      <div className="flex flex-col bg-white sm:rounded-lg w-full">
        <Navbar />
        <div className="py-6">
          <div className="w-full space-y-6">
            <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between sm:space-y-0 space-y-3">
              <Switch>
                <Redirect exact from={ROUTES.DASHBOARD} to={ROUTES.DASHBOARD_MATCH_SCHEDULE} />
                <Route exact path={ROUTES.DASHBOARD_TEAMS} component={TeamsPage} />
                <Route exact path={ROUTES.DASHBOARD_POOLS} component={PoolsPage} />
                <Route exact path={ROUTES.DASHBOARD_TOURNAMENT_OVERVIEW} component={ToernooiOverzichtPage} />
                <Route exact path={ROUTES.DASHBOARD_KNOCKOUT} component={KnockoutsPage} />
                <Route exact path={ROUTES.DASHBOARD_MATCH_SCHEDULE} component={MatchesPage} />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
