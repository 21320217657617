import React from "react";
import { RouteChildrenProps } from "react-router";
import { useForm } from "react-hook-form";
import useLoginLazyQuery, { ILoginReqData } from "../data/api/useLoginLazyQuery";
import { useAsyncCallback } from "react-async-hook";
import { ROUTES } from "../constants/routes";
import { usePrefetchAuthQuery } from "../data/api/useAuthQuery";
import { Button } from "../components/Button/Button";
import { TextInput } from "components/Input/TextInput";
import useStore from "../store";
import { usePrefetchProfileQuery } from "../data/api/useProfileQuery";
import useLogout from "../hooks/useLogout";

const LoginPage: React.VFC<RouteChildrenProps<{}, { from: Window["location"] }>> = ({ location, history }) => {
   const prefetchAuthQuery = usePrefetchAuthQuery();
   const prefetchProfileQuery = usePrefetchProfileQuery();
   const { register, handleSubmit, formState: { errors, isValid } } = useForm<ILoginReqData>({ mode: "all" });
   const addError = useStore((state) => state.addError);
   const { mutateAsync } = useLoginLazyQuery({ onError: (err) => addError(err.response?.data.message) });
   const logout = useLogout({ replaceHistory: false });

   const onSubmit = useAsyncCallback(async (data: ILoginReqData) => {
      await logout();
      await mutateAsync(data);
      await prefetchAuthQuery();
      await prefetchProfileQuery();
      const { from } = location.state || { from: { pathname: ROUTES.DASHBOARD } };
      history.replace(from);
   });

   return (
   <div className="relative">
      <div className="absolute bottom-0 right-0 w-1/2 h-full">
         <img className="absolute bottom-0 right-6 w-full hidden md:block opacity-10 lg:opacity-100" style={{ maxWidth: "51.75rem" }} src="/images/homebanner.png" alt="login form cover" />
      </div>
      <div className="flex min-h-screen py-10 relative">
         <div className="container">
            <div className="row h-full">
               <div className="col-xl-4 offset-xl-1 col-lg-5 offset-lg-1 col-md-8 offset-md-2">
                  <div className="flex flex-column justify-center h-full">
                     <img className="relative" src="/images/logo-tournamate.svg" alt="logo" />
                     <h2 className="font-extrabold mt-10 text-app-4xl text-app-dark-primary">Krijg toegang tot jouw toernooi.</h2>
                     <p className="font-normal max-w mt-4 text-app-dark-primary text-base">Om zeker te weten dat jij het bent willen we je vragen in te loggen met jouw gegevens. Dit doen we om te voorkomen dat anderen het toernooi kunnen wijzigen.</p>
                     <div className="mt-6 mb-16">
                        <form onSubmit={handleSubmit(onSubmit.execute)} className="space-y-6">
                           <TextInput id="email" type="email" label="E-mail" autoComplete="email" placeholder="E-mailadres" error={errors.username?.message} {...register("username", { required: "Dit veld is vereist" })} />
                           <TextInput id="password" type="password" label="Wachtwoord" placeholder="Wachtwoord" autoComplete="current-password" className="space-y-1" error={errors.password?.message} {...register("password", { required: "Dit veld is vereist" })} />
                           <div>
                              <Button type="submit" label={onSubmit.loading ? "Laden" : "Login"} loading={onSubmit.loading} disabled={!isValid} />
                           </div>
                        </form>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   );
};

export default LoginPage;
