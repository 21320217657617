import { useMutation, UseMutationOptions } from "react-query";
import { AxiosError } from "axios";
import client from "../client";
import { ENDPOINTS } from "../../constants";

export interface ILoginReqData {
  username: string;
  password: string;
}

function useLoginLazyQuery(options?: UseMutationOptions<any, AxiosError, ILoginReqData>) {
  return useMutation("auth/login", (data: ILoginReqData) => client.post(ENDPOINTS.AUTH_LOGIN, data), options);
}

export default useLoginLazyQuery;
