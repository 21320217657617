import React, { forwardRef } from "react";
import ReactDatePicker from "react-datepicker";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { IInlineTextInputProps, InlineTextInput } from "./Input/InlineTextInput";
import MaskedTextInput from "react-text-mask";

export const DateInput = forwardRef<
  ReactDatePicker,
  Omit<IInlineTextInputProps, "type" | "onChange"> & ReactDatePickerProps & { selected?: Date; invalid?: boolean }
>(({ id, placeholder, label, error, selected, mask, invalid, ...props }, ref) => {
  const CustomInput = forwardRef<MaskedTextInput, Omit<IInlineTextInputProps, "type" | "onChange">>(
    ({ value, ...rest }, ref) => {
      return (
        <InlineTextInput
          ref={ref}
          {...rest}
          mask={mask}
          value={selected ? value : undefined}
          type="text"
          id={id}
          placeholder={placeholder}
          label={label}
          error={error}
          style={{ borderColor: invalid ? "red" : "#3ECA7E" }}
        />
      );
    }
  );
  // @ts-ignore
  return <DatePicker ref={ref} {...props} customInput={<CustomInput />} selected={selected || new Date()} />;
});
