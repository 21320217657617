import client from "../../client";
import { UseMutationOptions, useMutation } from "react-query";
import { AxiosError } from "axios";
import { ENDPOINTS, MS_TIME } from "../../../constants";
import { TeamClass } from "../types";

const QUERY_KEY = "swap-teams-to-other-poule";

export interface IUpdateTeamFormValues {
  team_a_id: string;
  team_b_id: string;
}

export function useSwapTeamsMutation(options?: UseMutationOptions<any, AxiosError, { data: IUpdateTeamFormValues }>) {
  return useMutation(
    [QUERY_KEY],
    async ({ data }) =>
      client.post(
        ENDPOINTS.SWAP_TEAMS,
        { team_a: { team_id: data.team_a_id }, team_b: { team_id: data.team_b_id } },
        {
          timeout: MS_TIME.HALF_HOUR,
        }
      ),
    options
  );
}
