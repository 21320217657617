import React, { ComponentPropsWithoutRef } from "react";
import classNames from "classnames";

export interface IIndeterminateCheckboxProps extends ComponentPropsWithoutRef<"input"> {
  indeterminate?: boolean;
}

export const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, IIndeterminateCheckboxProps>(
  ({ indeterminate, className, ...rest }, ref) => {
    const defaultRef = React.useRef<HTMLInputElement>(null);
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      // @ts-expect-error
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <input
        type="checkbox"
        className={classNames(
          "focus:ring-2 focus:ring-app-yellow-primary focus:ring-offset-2 outline-none text-app-yellow-primary focus:border-app-yellow-primary w-6 h-6 rounded",
          className
        )}
        ref={resolvedRef}
        {...rest}
      />
    );
  }
);
