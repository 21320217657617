import { useMutation, UseMutationOptions } from "react-query";
import { AxiosError } from "axios";
import client from "../client";
import { ENDPOINTS } from "../../constants";

export interface ILoginReqData {
  username: string;
  password: string;
}

function useSignupLazyQuery(options?: UseMutationOptions<any, AxiosError, ILoginReqData>) {
  return useMutation("user/create", (data: ILoginReqData) => client.post(ENDPOINTS.USER_CREATE, data), options);
}

export default useSignupLazyQuery;
