import React, { ChangeEvent } from "react";
import { CellProps } from "react-table";
import classNames from "classnames";
export interface IEditableCellProps extends CellProps<any> {
  /* This is a custom function that we supplied to our table instance */
  updateData(index: number, id: string, value: number): void;
  rowToEdit?: string;
}

export const EditableCell = ({
  value: initialValue,
  row: { index, original },
  column: { id },
  updateData,
  rowToEdit,
}: IEditableCellProps) => {
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    if (e.target.value != initialValue) {
      updateData(index, id, parseInt(e.target.value));
    }
  };

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const disabled = rowToEdit !== original.id;
  return (
    <input
      type="number"
      maxLength={2}
      value={value}
      placeholder="-"
      readOnly={disabled}
      className={classNames(
        "text-app-dark-primary placeholder-app-dark-primary appearance-none block  w-9 text-center focus:outline-none focus:ring-0 bg-transparent px-2 py-2 rounded sm:text-sm text-base",
        {
          "border-0": disabled,
          border: !disabled,
        }
      )}
      onChange={onChange}
    />
  );
};
