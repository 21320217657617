import { calculateAantalMatchesPouleFase } from "./calculateMatchesPoulefase";

export function veldWisselPouleFase({
  aantalPoules,
  teamsPerPoule,
  veldWisselMinuten,
}: {
  aantalPoules: number;
  teamsPerPoule: number;
  veldWisselMinuten: number;
}) {
  console.log(aantalPoules, teamsPerPoule, veldWisselMinuten);
  console.log(calculateAantalMatchesPouleFase({ aantalPoules, teamsPerPoule }) * veldWisselMinuten);

  const aantalMinuten = calculateAantalMatchesPouleFase({ aantalPoules, teamsPerPoule }) * veldWisselMinuten;

  return aantalMinuten;
}
