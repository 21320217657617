import React, { useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import { Button } from "components/Button/Button";
import { ActionButton } from "components/Button/ActionButton";
import { TextInput } from "components/Input/TextInput";
import { Controller, useForm } from "react-hook-form";
import { ICreateTeamFormValues } from "../../../data/api/useTeams";
import { ToggleButtons } from "../../../components/ToggleButtons";
import { TeamClass } from "../../../data/api/types";
import useStore from "store";
import { useTeamsQuery } from "../../../data/api/useTeams";
import { IsMixedPoule } from "../utils/helpers/isMixedPouleChecker";

interface ICreateDialogProps {
  data?: ICreateTeamFormValues;
  close(): void;
  confirm(value: ICreateTeamFormValues): void;
  loading: boolean;
  teamClass: TeamClass;
}

export const CreateDialog: React.VFC<ICreateDialogProps> = ({ data, teamClass, close, confirm, loading }) => {
  type FormValue = ICreateTeamFormValues;
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
  } = useForm<FormValue>({ defaultValues: { teamClass } });

  useEffect(() => {
    reset(data);
  }, [data, reset]);

  // const { tournamentId, addError } = useStore((s) => s);
  // const { data: tournamentData, isLoading, refetch } = useTeamsQuery(tournamentId!, { enabled: !!tournamentId });
  const [isMixed] = IsMixedPoule();

  return (
    <>
      <div>
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title as="h3" className="text-2xl font-bold text-app-dark-primary">
            Tijd om een nieuw team toe te voegen
          </Dialog.Title>
        </div>
        <div className="mt-3 sm:mt-5">
          <form onSubmit={handleSubmit(confirm)} className={"space-y-4"}>
            {isMixed && (
              <Controller
                name="teamClass"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <ToggleButtons<TeamClass>
                    buttons={[
                      { value: TeamClass.MEN, label: "Heren" },
                      { value: TeamClass.WOMEN, label: "Dames" },
                    ]}
                    label="team type"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                  />
                )}
              />
            )}

            <TextInput
              id="name"
              type="text"
              label="Naam"
              autoComplete="organization"
              labelClassName="sm:sr-only font-normal sm:font-bold"
              placeholder="Naam"
              error={errors.name?.message}
              {...register("name", { required: "Dit veld is vereist" })}
            />
            <div className="gap-x-3 gap-y-4 grid grid-cols-1 sm:grid-cols-6">
              <TextInput
                type="text"
                id="street_address"
                autoComplete="street-address"
                label="Adres"
                placeholder="Adres"
                labelFontBold={false}
                labelClassName="sm:sr-only font-normal sm:font-bold"
                className="sm:col-span-6"
                error={errors.street?.message}
                {...register("street", { required: "Dit veld is vereist" })}
              />
              <TextInput
                type="text"
                id="street_number"
                autoComplete="street-number"
                label="Straatnaam"
                placeholder="Straatnaam"
                labelFontBold={false}
                labelClassName="sm:sr-only font-normal sm:font-bold"
                className="sm:col-span-6"
                error={errors.street?.message}
                {...register("streetNumber", { required: "Dit veld is vereist" })}
              />
              <TextInput
                type="text"
                id="zip"
                autoComplete="postal-code"
                label="Postcode"
                placeholder="Postcode"
                labelFontBold={false}
                labelClassName="sm:sr-only font-normal sm:font-bold"
                className="sm:col-span-3"
                error={errors.postalCode?.message}
                {...register("postalCode", { required: "Dit veld is vereist" })}
              />
              <TextInput
                type="text"
                id="addition"
                label="Toevoeging"
                placeholder="Toevoeging"
                labelFontBold={false}
                labelClassName="sm:sr-only font-normal sm:font-bold"
                className="sm:col-span-3"
                {...register("addition", { required: false })}
              />
              <TextInput
                type="text"
                id="city"
                label="Plaatsnaam"
                placeholder="Plaatsnaam"
                labelFontBold={false}
                labelClassName="sm:sr-only font-normal sm:font-bold"
                className="sm:col-span-6"
                error={errors.city?.message}
                {...register("city", { required: "Dit veld is vereist" })}
              />
            </div>
            <div className="gap-x-3 gap-y-4 grid grid-cols-1 sm:grid-cols-2">
              <TextInput
                type="text"
                id="email"
                autoComplete="email"
                label="E-mailadres"
                placeholder="E-mailadres"
                labelFontBold={false}
                labelClassName="sm:sr-only font-normal sm:font-bold"
                className="sm:col-span-1"
                error={errors.email?.message}
                {...register("email", { required: "Dit veld is vereist" })}
              />
              <TextInput
                type="text"
                id="phoneNumber"
                autoComplete="phone-number"
                label="Telefoonnummer"
                placeholder="Telefoonnummer"
                labelFontBold={false}
                labelClassName="sm:sr-only font-normal sm:font-bold"
                className="sm:col-span-1"
                error={errors.phoneNumber?.message}
                {...register("phoneNumber", { required: "Dit veld is vereist" })}
              />
            </div>
          </form>
        </div>
      </div>
      <div className="mt-5 sm:mt-6 flex flex-col items-center justify-center space-y-2">
        <Button secondary loading={loading} type="button" label="Team toevoegen" onClick={handleSubmit(confirm)} />
        <ActionButton noIcon type="button" label="Annuleren" onClick={close} />
      </div>
    </>
  );
};
