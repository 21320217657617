import React, { useEffect, useState } from "react";
import { RouteChildrenProps } from "react-router";
import useStore from "../../../store";
import { useMatchesQuery } from "../../../data/api/useMatches";
import "moment/locale/nl";
import { TeamClass } from "../../../data/api/types";
import { MatchesPageControls } from "./MatchesPageControls";
import { useFilters } from "./useMatchesFilters";
import { useMatchesTable } from "./useMatchesTable";
import { MatchesTable } from "./matchesTable";
import { ConfirmExportPDFDialog } from "./ConfirmExportPDFDialog";
import { useAsyncCallback } from "react-async-hook";
import { useMatchesPdfLazyQuery } from "../../../data/api/useMatches";
import { Modal } from "components/modal";
import { IsMixedPoule } from "../utils/helpers/isMixedPouleChecker";

const MatchesPage: React.VFC<RouteChildrenProps> = () => {
  const [openExportDialog, setOpenExportDialog] = useState(false);
  const { tournamentId } = useStore((s) => s);
  const { data, isLoading, refetch } = useMatchesQuery(tournamentId!, { enabled: !!tournamentId });
  const [teamClass, setTeamClass] = useState(TeamClass.MEN);
  const { options, selected, onChange: onSelectOptions, filteredData } = useFilters(teamClass, data);
  const tableInstance = useMatchesTable(filteredData);
  const { mutateAsync } = useMatchesPdfLazyQuery();
  const [isMixed] = IsMixedPoule();

  useEffect(() => {
    if (data?.[teamClass]?.length === 0) {
      if (teamClass === TeamClass.WOMEN) {
        setTeamClass(TeamClass.MEN);
      } else if (teamClass === TeamClass.MEN) {
        setTeamClass(TeamClass.WOMEN);
      }
    }
  }, [data, teamClass]);

  const exportPDF = useAsyncCallback(async () => {
    // @ts-ignore
    await mutateAsync(tableInstance.selectedFlatRows.map((r) => r.original.id));
    setOpenExportDialog(false);
  });

  return (
    <div className="w-full space-y-6">
      {options && selected && (
        <MatchesPageControls
          toggleTeamClass={(teamClass) => {
            setTeamClass(teamClass);
            refetch();
          }}
          teamClass={teamClass}
          // @ts-ignore
          selectedData={tableInstance.state.selectedRowIds}
          onExport={() => setOpenExportDialog(true)}
          options={options}
          selected={selected}
          onChange={onSelectOptions}
          isMixedPoule={isMixed}
        />
      )}
      <h4 className="text-2xl font-bold text-neutral-900">{selected?.date[0].display}</h4>
      <MatchesTable referees={options?.referee || []} tableInstance={tableInstance} loading={isLoading} />
      <Modal open={openExportDialog} close={() => setOpenExportDialog(false)}>
        <ConfirmExportPDFDialog
          confirm={exportPDF.execute}
          loading={exportPDF.loading}
          close={() => setOpenExportDialog(false)}
        />
      </Modal>
    </div>
  );
};

export default MatchesPage;
