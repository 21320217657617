import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { ROUTES } from "./constants/routes";
import AppRoute from "./components/helpers/AppRoute";

import LoginPage from "./pages/login.page";
import CreateTournamatePage from "./pages/create-tournamate.page";
import DashboardPage from "./pages/dashboard";
import useStore from "./store";
import useAuthQuery from "./data/api/useAuthQuery";
import { ErrorNotification } from "components/ErrorNotification";
import useProfileQuery from "./data/api/useProfileQuery";
import SettingsPage from "./pages/Settings";
import CreateUserPage from "./pages/create-user.page";

const App: React.VFC = () => {
  const {
    login,
    logout,
    setTournamentId,
    removeTournamentId,
    errors,
    removeError,
    closeError,
    clearErrors,
    addError,
    authenticated,
  } = useStore((s) => s);

  useEffect(() => {
    return () => clearErrors();
  }, [clearErrors]);

  const { isLoading: isProfileLoading, isFetching: isProfileFetching } = useProfileQuery({
    retry: false,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    notifyOnChangeProps: ["data", "error"],
    enabled: authenticated,
    onSuccess: (data) => setTournamentId(data.tournamentId),
    onError: (err) => {
      removeTournamentId();
      addError(err.response?.data.message);
    },
  });

  const { isLoading, isFetching } = useAuthQuery({
    retry: false,
    cacheTime: Infinity,
    notifyOnChangeProps: ["data", "error"],
    enabled: !!localStorage.getItem("accessToken"),
    onSuccess: () => login(),
    onError: () => logout(),
  });

  if (isLoading && !isFetching) return null;
  if (isProfileLoading && !isProfileFetching) return null;

  return (
    <>
      <Router>
        <Switch>
          <Redirect exact from="/" to={ROUTES.DASHBOARD} />
          <AppRoute routeType="private" exact path={ROUTES.CREATE_USER} component={CreateUserPage} />
          <AppRoute exact routeType="dashboard" path={ROUTES.DASHBOARD_SETTINGS} component={SettingsPage} />
          <AppRoute routeType="dashboard" path={ROUTES.DASHBOARD} component={DashboardPage} />
          <AppRoute routeType="private" path={ROUTES.CREATE_TOURNAMENT} component={CreateTournamatePage} />
          <Route exact path={ROUTES.LOGIN} component={LoginPage} />
        </Switch>
      </Router>
      <ErrorNotification errors={errors} remove={removeError} close={closeError} />
    </>
  );
};

export default App;
