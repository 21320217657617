import React from "react";

export interface FileUploadHandlerProps {
  name: string;
  id?: string;
  filename?: string;
  onDelete(name: string): void;
}

export const FileUploadHandler: React.VFC<FileUploadHandlerProps> = ({ id, name, filename, onDelete }) => {
  const handleDelete = () => onDelete(name);

  if (!id) return null;

  return (
    <div className="flex justify-between text-app-dark-primary text-base whitespace-nowrap sm:flex-row flex-col items-start">
      <div>{filename || id.slice(-10)}</div>
      <button className="font-bold hover:opacity-95 outline-none text-red-error underline" onClick={handleDelete}>
        Verwijder bestand
      </button>
    </div>
  );
};
