import client from "../client";
import { useQuery } from "react-query";
import { ENDPOINTS } from "../../constants";
const QUERY_KEY = "create-team";

export function useTournamentStatus(tournamentId: string, options?: {}) {
  return useQuery(
    [QUERY_KEY, tournamentId],
    async () => {
      const { data } = await client.get(ENDPOINTS.TOURNAMENT_STATUS.replace("{tournamentId}", tournamentId));
      return { ...data };
    },
    options
  );
}
