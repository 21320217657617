import { FieldValues } from "react-hook-form/dist/types/fields";
import { FieldErrors } from "react-hook-form";
import { useState } from "react";

export function useShippingFormTabs<TFieldValues extends FieldValues = FieldValues>(
  errors: FieldErrors<TFieldValues>,
  days: number | undefined
) {
  const tabs = new Array(days).fill(0).map((_, index) => ({
    value: index,
    label: `dag ${index + 1}`,
    // @ts-ignore
    error: errors.days?.length && errors.days[index] ? "Incorrecte invoer" : undefined,
  }));
  const [currentTab, setCurrentTab] = useState(tabs[0].value);

  const handleOnTabChange = (nextTabValue: number) => {
    setCurrentTab(nextTabValue);
  };

  return { tabs, currentTab, handleOnTabChange };
}
