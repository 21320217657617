import React, { FocusEventHandler, ReactElement } from "react";
import { RadioGroup } from "@headlessui/react";
import { RadioButton, IRadioButtonProps } from "./RadioButton";

export interface IRadioButtonsProps<T> {
  value?: T;
  onChange(value: T): void;
  onBlur?: FocusEventHandler<HTMLDivElement>;
  label?: string;
  disabled?: boolean;
  buttons: IRadioButtonProps<T>[];
  renderButton?(props: IRadioButtonProps<T>): ReactElement;
  buttonsWrapperClassNameReplacement?: string;
  error?: string;
}

export const RadioButtons = <T extends unknown>({
  value,
  onChange,
  onBlur,
  label,
  disabled,
  buttons,
  renderButton,
  buttonsWrapperClassNameReplacement,
  error,
}: IRadioButtonsProps<T>): ReactElement => {
  return (
    <RadioGroup onChange={onChange} onBlur={onBlur} value={value} disabled={disabled} className="pt-2">
      {label && (
        <RadioGroup.Label className="block font-medium text-neutral-900 text-base mb-3">
          {label}
        </RadioGroup.Label>
      )}
      <div className={buttonsWrapperClassNameReplacement || "mt-1 grid grid-cols-3 gap-5"}>
        {buttons.map(({ value, label }) => {
          const props = { key: `${value}-radio-button`, value, label };
          if (renderButton) return renderButton(props);
          return <RadioButton {...props} />;
        })}
      </div>
      {error && <p className="mt-2 text-sm text-red-error">{error}</p>}
    </RadioGroup>
  );
};
