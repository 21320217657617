import client from "../client";
import { useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { AxiosError } from "axios";
import { ENDPOINTS } from "../../constants";

const QUERY_KEY = "tournament-settings";
const QUERY_FUN = (tournamentId: string) => async () => {
  const res = await client.get(ENDPOINTS.TOURNAMENT_SETTINGS.replace("{tournamentId}", tournamentId));
  return res.data;
};

function useTournamentSettingsQuery(tournamentId: string, options?: UseQueryOptions<any, AxiosError>) {
  return useQuery(QUERY_KEY, QUERY_FUN(tournamentId), options);
}

export function usePrefetchTournamentSettingsQuery() {
  const client = useQueryClient();
  return (tournamentId: string) => client.prefetchQuery(QUERY_KEY, QUERY_FUN(tournamentId));
}

export default useTournamentSettingsQuery;
