import React, { ChangeEvent, forwardRef } from "react";
import { CounterButton } from "./counterButton";
import classNames from "classnames";

type HTMLCounterInputProps = React.ComponentPropsWithoutRef<"input">;

export interface ICounterInputProps
  extends Omit<HTMLCounterInputProps, "type" | "value" | "defaultValue" | "onChange"> {
  id: string;
  label: string;
  children?: never;
  value?: number;
  onChange(count: number): void;
  error?: string;
  lightLabel?: boolean;
}

export const CounterInput = forwardRef<HTMLInputElement, ICounterInputProps>(
  ({ id, lightLabel, label, value = 0, onChange, error, ...rest }, ref) => {
    const handleHandlerClick = (factor: number) => () => {
      const nextValue = value + factor;
      if (nextValue >= 0 && nextValue <= 99) {
        onChange(nextValue);
      }
    };

    const handleOnInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      const nextValue = parseInt(event.target.value);

      if (nextValue >= 0 && nextValue <= 99) {
        onChange(nextValue);
      }
    };

    return (
      <div>
        <label
          htmlFor={id}
          className={classNames("block text-neutral-900 mb-3 font-medium", {
            "font-semibold": !lightLabel,
          })}
        >
          {label}
        </label>
        <div className="flex items-center mt-1 space-x-3">
          <CounterButton type="minus" onClick={handleHandlerClick(-1)} />
          <input
            id={id}
            ref={ref}
            {...rest}
            type="number"
            value={value}
            onChange={handleOnInputChange}
            className={classNames(
               "p-3 appearance-none bg-white block border focus:outline-none focus:ring-0 placeholder-neutral-500 rounded-lg text-base w-14 text-center",
              {
                "border-neutral-500 focus:border-purple-primary focus:ring-purple-primary text-neutral-900": !error,
                "border-red-error focus:border-red-error focus:ring-red-error text-red-error": !!error,
              },
            )}
            aria-invalid={!!error}
            aria-describedby={error ? `${id}-error` : undefined}
          />
          <CounterButton type="plus" onClick={handleHandlerClick(1)} />
        </div>
        {error && (
          <p className="mt-2 text-sm text-red-error" id={`${id}-error`}>
            {error}
          </p>
        )}
      </div>
    );
  }
);
