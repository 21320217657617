import { useMutation, UseMutationOptions } from "react-query";
import { AxiosError } from "axios";
import client from "../client";
import { ENDPOINTS } from "../../constants";

function useUploadTeamMutation(options?: UseMutationOptions<any, AxiosError, FormData>) {
  return useMutation(
    "team/upload",
    (data: FormData) =>
      client.post(ENDPOINTS.UPLOAD_TEAM, data, {
        headers: { "Content-Type": "multipart/form-data" },
      }),
    options
  );
}

export default useUploadTeamMutation;
