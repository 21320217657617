import React from "react";
import classNames from "classnames";
import useStore, { IFormsMetaData } from "../../store";

export interface IFormWrapperProps {
  formTitle?: string;
  formStep?: string;
}

export const FormWrapper: React.FC<IFormWrapperProps> = ({ children, formTitle, formStep }) => {

   const currentStep = useStore((state) => state.currentTournamentConfigForm);
   let colClass = 'col-lg-4 offset-lg-1 col-md-8 offset-md-2';

   if(currentStep != 'base'){
      colClass = 'col-lg-4 col-md-8';
   }

   return (
      <div className="w-full">
         <div className="row">
            <div className={colClass}>
               {formStep && (
                  <div>
                     <p className="mb-2">Stap {formStep} van 3</p>
                  </div>
               )}
               {formTitle && (
                  <div>
                     <h2 className="font-bold mt-2 text-app-4xl text-app-dark-primary">{formTitle}</h2>
                  </div>
               )}
            </div>
         </div>
         <div>
            <div className={classNames({ "mt-8": formTitle })}>{children}</div>
         </div>
   </div>
   );
};
