import React, { useEffect, useMemo } from "react";
import { Route, Switch } from "react-router-dom";
import useStore, { TTournamentFormSteps } from "../../store";
import { BaseStep } from "./forms/BaseStep";
import { ShapingStep } from "./forms/ShapingStep";
import { FinalStep } from "./forms/FinalStep";
import { ROUTES } from "../../constants/routes";
import { RouteChildrenProps } from "react-router";
import { findKey, indexOf } from "lodash";

const steps: TTournamentFormSteps[] = ["base", "shaping", "final"];

const mapRoutesToSteps: Record<TTournamentFormSteps, ROUTES> = {
  base: ROUTES.CREATE_TOURNAMENT_BASE_STEP,
  shaping: ROUTES.CREATE_TOURNAMENT_SHAPING_STEP,
  final: ROUTES.CREATE_TOURNAMENT_FINAL_STEP,
};

const CreateTournamentPage: React.VFC<RouteChildrenProps> = ({ match, history, location }) => {
  const currentStep = useStore((state) => state.currentTournamentConfigForm);

  const currentStepRoute = useMemo(
    () => findKey(mapRoutesToSteps, location.pathname.replace(match?.url || "", "")),
    [location.pathname, match?.url]
  );

  useEffect(() => {
    if (currentStepRoute !== currentStep) {
      history.replace({
        pathname: `${match?.url}${mapRoutesToSteps[currentStep]}`,
      });
    }
  }, [currentStep, currentStepRoute, history, match?.url]);

   return (
      <div className="relative">
         <div className="absolute bottom-0 right-0 w-1/2 h-full">
            <img className="absolute bottom-0 right-0 w-auto pt-5 h-full hidden md:block opacity-30 lg:opacity-100" style={{ maxWidth: "unset" }} src="/images/bg-banner.png" alt="form background" />
         </div>
         <div className="flex min-h-screen py-10 pb-36 relative">
            <div className="container">
               <div className="row h-full">
                  <div className="col-12">
                     <div className="flex flex-column justify-center h-full">
                        <Switch>
                           <Route path={`${match?.url}${ROUTES.CREATE_TOURNAMENT_BASE_STEP}`} component={BaseStep} />
                           <Route path={`${match?.url}${ROUTES.CREATE_TOURNAMENT_SHAPING_STEP}`} component={ShapingStep} />
                           <Route path={`${match?.url}${ROUTES.CREATE_TOURNAMENT_FINAL_STEP}`} component={FinalStep} />
                        </Switch>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default CreateTournamentPage;
