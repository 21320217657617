import { IRadioButtonProps } from "../RadioButtons/RadioButton";
import { RadioGroup } from "@headlessui/react";
import classNames from "classnames";
import React from "react";

export interface IToggleButtonProps<T> extends IRadioButtonProps<T> {
  secondary?: boolean;
}

export const ToggleButton = <T extends unknown>({ value, secondary, label, ...rest }: IToggleButtonProps<T>) => {
  return (
    <RadioGroup.Option
      className="outline-none cursor-pointer last:rounded-r-lg first:rounded-l-lg overflow-hidden"
      value={value}
      {...rest}
    >
      {({ checked }) => (
        <span
          className={classNames(
            // "font-normal inline-flex justify-center px-4 py-2 text-base w-full hover:opacity-90",
            "border font-regular inline-flex justify-center p-3 text-base w-full bg-purple-primary text-white border-purple-primary",
            {
              [`bg-purple-primary text-white`]: checked,
              [`bg-neutral-100 text-neutral-900 border-neutral-100`]: !checked,
            }
          )}
        >
          {label}
        </span>
      )}
    </RadioGroup.Option>
  );
};
