import React, { forwardRef } from "react";
import { Controller, useForm } from "react-hook-form";
import classNames from "classnames";
import { TextInput } from "../../../components/Input/TextInput";
import { Button } from "../../../components/Button/Button";
import { ActionButton } from "../../../components/Button/ActionButton";
import { RadioButtons } from "../../../components/RadioButtons";
import useStore, { IFormsMetaData } from "../../../store";
import { FormWrapper } from "../FormWrapper";
import { ITournamentConfigBase } from "../../../data/api/useCreateTournamentMutation";

import useLogout from "../../../hooks/useLogout";
import { useAsyncCallback } from "react-async-hook";

type HTMLFormProps = React.ComponentPropsWithoutRef<"form">;

interface IFormData extends ITournamentConfigBase, Pick<IFormsMetaData, "daysCount" | "type"> {}

export interface IBaseStepProps extends Omit<HTMLFormProps, "onSubmit"> {}

export const BaseStep = forwardRef<HTMLFormElement, IBaseStepProps>(({ className, ...rest }, ref) => {
  let defaultValues = useStore((state) => state.tournamentConfig.base);
  const formsMetaData = useStore((state) => state.tournamentConfig.formsMetaData);
  const setTournamentForms = useStore((state) => state.setTournamentConfig);
  const setCurrentForm = useStore((state) => state.setCurrentTournamentConfigForm);

  // @ts-expect-error
  if (defaultValues) defaultValues["daysCount"] = formsMetaData?.daysCount;
  // @ts-expect-error
  if (defaultValues) defaultValues["type"] = formsMetaData?.type;

  const {
    control,
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<IFormData>({ defaultValues, mode: "all" });

  const onSubmit = ({ daysCount, type, ...data }: IFormData) => {
    setTournamentForms({ base: data, formsMetaData: { daysCount: daysCount, type } });
    setCurrentForm("shaping");
  };

  const logout = useLogout();
  const handleLogout = useAsyncCallback(logout);
  const activeLinkClass = "text-purple-primary font-bold";

  return (

   <FormWrapper formTitle="Laten we beginnen met de basis" formStep="1">
      <div className="row">
         <div className="col-lg-5 offset-lg-1 col-md-8 offset-md-2">
            <form
              autoComplete="off"
              ref={ref}
              {...rest}
              onSubmit={handleSubmit(onSubmit)}
              className={classNames("space-y-6", className)}
            >
              <TextInput
                id="name"
                type="text"
                label="Naam toernooi"
                labelFontBold={false}
                labelClassName="text-xl"
                autoComplete="organization"
                placeholder="Naam toernooi"
                error={errors.name?.message}
                {...register("name", { required: "Dit veld is vereist" })}
              />
              <Controller
                name="daysCount"
                control={control}
                rules={{ required: "Dit veld is vereist" }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <RadioButtons
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    error={errors.daysCount?.message}
                    label="Hoeveel dagen duurt het toernooi?"
                    buttons={[
                      { value: 1, label: "1 dag" },
                      { value: 2, label: "2 dagen" },
                      { value: 3, label: "3 dagen" },
                    ]}
                  />
                )}
              />
              <Controller
                name="type"
                control={control}
                rules={{ required: "Dit veld is vereist" }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <RadioButtons
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    error={errors.daysCount?.message}
                    label="Deelnemers toernooi"
                    buttons={[
                      { value: "Heren", label: "Heren" },
                      { value: "Dames", label: "Dames" },
                      { value: "Combinatie", label: "Combinatie" },
                    ]}
                  />
                )}
              />
              <fieldset>
                <legend className="block font-medium text-neutral-900 text-xl mb-3 pt-2">
                  Waar vindt het toernooi plaats?
                </legend>
                <div className="gap-x-5 gap-y-2 grid grid-cols-1 sm:grid-cols-6">
                  <TextInput
                    type="text"
                    id="zip"
                    autoComplete="postal-code"
                    label="Postcode"
                    placeholder="Postcode"
                    labelFontBold={true}
                    labelClassName=""
                    className="sm:col-span-6"
                    error={errors.postalCode?.message}
                    {...register("postalCode", { required: "Dit veld is vereist" })}
                  />
                  <TextInput
                    type="number"
                    id="street-number"
                    label="Huisnr."
                    placeholder="Huisnummer"
                    labelFontBold={true}
                    labelClassName=""
                    className="sm:col-span-3"
                    error={errors.streetNumber?.message}
                    {...register("streetNumber", {
                      required: "Dit veld is vereist",
                      valueAsNumber: true,
                    })}
                  />
                  <TextInput
                    type="text"
                    id="addition"
                    label="Toevoeging"
                    placeholder="Toevoeging"
                    labelFontBold={true}
                    labelClassName=""
                    className="sm:col-span-3"
                    {...register("addition", { required: false })}
                  />
                  <TextInput
                    type="text"
                    id="street_address"
                    autoComplete="street-address"
                    label="Straat"
                    placeholder="Straat"
                    labelFontBold={true}
                    labelClassName=""
                    className="sm:col-span-6"
                    error={errors.street?.message}
                    {...register("street", { required: "Dit veld is vereist" })}
                  />
                  <TextInput
                    type="text"
                    id="city"
                    label="Plaatsnaam"
                    placeholder="Plaatsnaam"
                    labelFontBold={true}
                    labelClassName=""
                    className="sm:col-span-6"
                    error={errors.city?.message}
                    {...register("city", { required: "Dit veld is vereist" })}
                  />
                </div>
              </fieldset>
              <div className="fixed bottom-0 left-0 w-full bg-neutral-100 py-5 mt-0">
                  <div className="container">
                     <div className="row">
                        <div className="col-4">
                           <div className="flex h-full items-center">
                              <button
                                className={classNames(activeLinkClass, "inline-flex items-center focus:outline-none font-bold text-base text-purple-primary uppercase")}
                                onClick={handleLogout.execute}
                              >
                                 Uitloggen
                                 <svg className="h-4 w-4 ml-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M1.71429 1.14286C1.56273 1.14286 1.41739 1.20306 1.31022 1.31022C1.20306 1.41739 1.14286 1.56273 1.14286 1.71429V14.2857C1.14286 14.4373 1.20306 14.5826 1.31022 14.6898C1.41739 14.7969 1.56273 14.8571 1.71429 14.8571H9.71428C9.86584 14.8571 10.0112 14.7969 10.1183 14.6898C10.2255 14.5826 10.2857 14.4373 10.2857 14.2857V12C10.2857 11.6844 10.5416 11.4286 10.8571 11.4286C11.1727 11.4286 11.4286 11.6844 11.4286 12V14.2857C11.4286 14.7404 11.248 15.1764 10.9265 15.4979C10.605 15.8194 10.1689 16 9.71428 16H1.71429C1.25963 16 0.823593 15.8194 0.502103 15.4979C0.180612 15.1764 0 14.7404 0 14.2857V1.71429C0 1.25963 0.180612 0.823593 0.502103 0.502103C0.823593 0.180612 1.25963 0 1.71429 0H9.71428C10.1689 0 10.605 0.180612 10.9265 0.502103C11.248 0.823593 11.4286 1.25963 11.4286 1.71429V4C11.4286 4.31559 11.1727 4.57143 10.8571 4.57143C10.5416 4.57143 10.2857 4.31559 10.2857 4V1.71429C10.2857 1.56273 10.2255 1.41739 10.1183 1.31022C10.0112 1.20306 9.86584 1.14286 9.71428 1.14286H1.71429Z" fill="#653EF2"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M6.85714 8C6.85714 7.68441 7.11298 7.42857 7.42857 7.42857H15.4286C15.7442 7.42857 16 7.68441 16 8C16 8.31559 15.7442 8.57143 15.4286 8.57143H7.42857C7.11298 8.57143 6.85714 8.31559 6.85714 8Z" fill="#653EF2"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12.7388 5.31022C12.962 5.08707 13.3238 5.08707 13.5469 5.31022L15.8326 7.59594C16.0558 7.8191 16.0558 8.1809 15.8326 8.40406L13.5469 10.6898C13.3238 10.9129 12.962 10.9129 12.7388 10.6898C12.5156 10.4666 12.5156 10.1048 12.7388 9.88165L14.6204 8L12.7388 6.11835C12.5156 5.89519 12.5156 5.53338 12.7388 5.31022Z" fill="#653EF2"/>
                                 </svg>

                              </button>
                           </div>
                        </div>
                        <div className="col-8">
                           <Button type="submit" style={{ maxWidth: "194px" }} className="w-full pl-5 pr-5 float-right" label="Volgende stap" withIcon={true} />
                        </div>
                     </div>
                  </div>
              </div>
            </form>
            </div>
         </div>
      </FormWrapper>
  );
});
