import React, { ChangeEvent, useState } from "react";
import classNames from "classnames";
import { isNumber } from "lodash";
import { SaveIcon } from "components/icons";

interface ScoreInputProps {
  value1: number | null;
  value2: number | null;
  disabled: boolean;
  onChange(data: { value1: number | null; value2: number | null }): void;
}

function isEmpty(value: any) {
  return typeof value === "undefined" || value === null || value === "";
}

export const ScoreInput: React.VFC<ScoreInputProps> = ({ value1, value2, disabled, onChange }) => {
  const [bothFieldsFilled, setBothFieldsFilled] = useState(false);
  const [value, setValue] = useState({ value1: value1, value2 });
  const [status, setStatus] = useState({ hasErrors: false, isSaved: false, didSumbitTry: false });

  const onChangeScoreField1 = (evt: ChangeEvent<HTMLInputElement>) => {
    setStatus({ ...status, isSaved: false });
    if (isEmpty(evt.target.value)) {
      setValue({ value1: null, value2: value.value2 });
    } else {
      setValue({ value1: parseInt(evt.target.value, 10), value2: value.value2 });
    }
  };

  const onChangeScoreField2 = (evt: ChangeEvent<HTMLInputElement>) => {
    setStatus({ ...status, isSaved: false });
    if (isEmpty(evt.target.value)) {
      setValue({ value1: value.value1, value2: null });
    } else {
      setValue({ value1: value.value1, value2: parseInt(evt.target.value, 10) });
    }
  };

  const onClickSave = () => {
    if (!isEmpty(value.value1) && !isEmpty(value.value2)) {
      // both fields filled
      setStatus({ hasErrors: false, isSaved: true, didSumbitTry: true });
      onChange(value);
    } else if (isEmpty(value.value1) && isEmpty(value.value2)) {
      // both fields empty
      setStatus({ hasErrors: false, isSaved: true, didSumbitTry: true });
      onChange(value);
    } else {
      // one field filled
      setStatus({ hasErrors: true, isSaved: false, didSumbitTry: true });
    }
  };

  const hasValue = isNumber(value1) && isNumber(value2);

  return (
    <div className="flex justify-start">
      <div
        className={classNames("flex items-center justify-between w-20 rounded-sm  ", {
          "border border-app-dark-light bg-white": true,
          "bg-app-green-primary": disabled && hasValue,
        })}
      >
        <input
          type="number"
          name="team1Score"
          id="team1Score"
          maxLength={2}
          // @ts-ignore
          value={!isEmpty(value.value1) ? value.value1 : ""}
          placeholder="-"
          min={0}
          className={classNames(
            "appearance-none block border-0  w-9 text-center focus:outline-none focus:ring-0 bg-transparent px-2 py-2 rounded sm:text-sm text-base",
            {
              "bg-green-500": status.isSaved,
              "bg-red-500": status.hasErrors,
              "text-app-dark-primary placeholder-app-dark-primary": !disabled && !hasValue,
              "text-white placeholder-app-dark-light": disabled && hasValue,
            }
          )}
          style={{ backgroundColor: `${bothFieldsFilled && "#22c55e"}` }}
          readOnly={disabled}
          onChange={onChangeScoreField1}
        />
        <span
          className={classNames("text-center", {
            "text-app-dark-primary": !disabled && !hasValue,
            "text-app-dark-light": disabled && hasValue,
          })}
        >
          |
        </span>
        <input
          type="number"
          name="team2Score"
          id="team2Score"
          maxLength={2}
          // @ts-ignore
          value={!isEmpty(value.value2) ? value.value2 : ""}
          placeholder="-"
          min={0}
          style={{ backgroundColor: `${bothFieldsFilled && "#22c55e"}` }}
          readOnly={disabled}
          className={classNames(
            "appearance-none block border-0  w-9 text-center focus:outline-none focus:ring-0 bg-transparent px-2 py-2 rounded sm:text-sm text-base",
            {
              "bg-green-500": status.isSaved,
              "bg-red-500": status.hasErrors,
              "text-app-dark-primary placeholder-app-dark-primary": !disabled && !hasValue,
              "text-white placeholder-app-dark-light": disabled && hasValue,
            }
          )}
          onChange={onChangeScoreField2}
        />
      </div>
      <div
        onClick={onClickSave}
        className="w-9 h-9 bg-purple-ultra-light hover:bg-purple-primary rounded-full border border-purple-primary mx-4"
      >
        <SaveIcon className="w-9 h-9  hover:text-white text-purple-primary cursor-pointer" />
      </div>
    </div>
  );
};
