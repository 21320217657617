import React, { forwardRef } from "react";

export const SaveIcon = forwardRef<SVGSVGElement, React.ComponentPropsWithoutRef<"svg">>((props, ref) => {
  return (
    <svg
      {...props}
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      aria-hidden="true"
      viewBox="0 0 36 36"
    >
      <path
        fillRule="evenodd"
        d="M23 9H10.286A1.286 1.286 0 009 10.286v15.428A1.286 1.286 0 0010.286 27h15.428A1.29 1.29 0 0027 25.714V13l-4-4zm-10.786 5.786v-2.572h7.715v2.572h-7.715zm8.997 7.222a3.215 3.215 0 11-3.362-3.361 3.222 3.222 0 013.362 3.361z"
      />
    </svg>
  );
});
