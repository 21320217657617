import React, { useMemo, useState } from "react";
import { RouteChildrenProps } from "react-router";
import useStore from "../../../store";
import { KnockoutsPageControls, TKnockOutOption } from "./knockoutsPageControls";
import { IKnockoutGame, IMatchScore, TeamClass } from "../../../data/api/types";
import { sortBy, findIndex, map, find, uniqBy, toPairs, groupBy, filter, isNumber } from "lodash";
import { useKnockOutsQuery } from "../../../data/api/useKnockOuts";
import { formatDate } from "../../../utils/formatDate";
import { KnockoutGamesTable } from "./knockoutGamesTable";
import { useUpdateFieldNumberMutation, useUpdateMatchScoreMutation } from "../../../data/api/usePools";
import { useAsyncCallback } from "react-async-hook";
import { KNOCKOUT } from "../../../constants";

const KnockoutsPage: React.VFC<RouteChildrenProps> = () => {
  const { tournamentId, addError } = useStore((s) => s);
  const { data, isLoading, refetch } = useKnockOutsQuery(tournamentId!, { enabled: !!tournamentId });
  const [teamClass, setTeamClass] = useState(TeamClass.MEN);
  const [knockOutOption, setKnockOutOption] = useState<TKnockOutOption>();
  const [matchToEdit, setMatchToEdit] = useState<string>();
  const [updatedFieldNumberValue, setUpdatedFieldNumberValue] = useState<number>();
  const { mutateAsync: updateMatchScore } = useUpdateMatchScoreMutation({
    onError: (err) => addError(err.response?.data.message),
  });
  const { mutateAsync: updateFieldNumber } = useUpdateFieldNumberMutation({
    onError: (err) => addError(err.response?.data.message),
  });

  const knockOutOptions = useMemo(() => {
    let selectedKnockouts = data?.[teamClass];
    if (selectedKnockouts?.length === 0) {
      if (teamClass === TeamClass.WOMEN) {
        selectedKnockouts = data?.men;
        setTeamClass(TeamClass.MEN);
      } else if (teamClass === TeamClass.MEN) {
        selectedKnockouts = data?.women;
        setTeamClass(TeamClass.WOMEN);
      }
    }
    const sortedTemplate = [
      KNOCKOUT.LAST_32,
      KNOCKOUT.LAST_16,
      KNOCKOUT.FINAL,
      KNOCKOUT.HALF_FINAL,
      KNOCKOUT.QUARTER_FINAL,
    ];
    if (selectedKnockouts) {
      const options = sortBy(
        uniqBy(
          map(selectedKnockouts, (p) => ({ id: p.type, name: p.name })),
          "id"
        ),
        (opt) => findIndex(sortedTemplate, (opt1) => opt.id === opt1)
      );

      if (!knockOutOption || !find(options, knockOutOption)) setKnockOutOption(options[0]);

      return options;
    }
  }, [data, teamClass, knockOutOption]);

  const knockout = useMemo(() => {
    if (knockOutOption) {
      const selectedKnockouts = data?.[teamClass];
      const filteredData = filter(selectedKnockouts, (p) => p.type === knockOutOption.id);
      return filteredData[0];
    }
  }, [data, teamClass, knockOutOption]);

  const games = useMemo(() => {
    return toPairs<IKnockoutGame[]>(groupBy(knockout?.games, (g) => formatDate(g.startTime)));
  }, [knockout?.games]);

  const handleChangeTeamScore = useAsyncCallback(async (data: IMatchScore) => {
    try {
      if (updatedFieldNumberValue) {
        await updateFieldNumber({ id: data.id, fieldNumber: updatedFieldNumberValue - 1 });
      }
      if (data.team1Score !== null || data.team2Score !== null) {
        await updateMatchScore(data);
      }
    } catch (err) {
      addError(err.response?.data.message);
    }
    await refetch();
    setUpdatedFieldNumberValue(undefined);
    setMatchToEdit(undefined);
  });

  const updateData = (index: number, id: string, value: number) => {
    setUpdatedFieldNumberValue(value);
  };

  return (
    <div className="w-full space-y-6">
      {knockOutOption && knockOutOptions && (
        <KnockoutsPageControls
          teamClass={teamClass}
          toggleTeamClass={setTeamClass}
          knockOutsOption={knockOutOption}
          knockOutOptions={knockOutOptions}
          setKnockOutsOption={setKnockOutOption}
        />
      )}
      {games.map(([date, data]) => (
        <>
          <h4 className="text-2xl font-bold text-app-dark-primary">{date}</h4>
          <KnockoutGamesTable
            data={data}
            loading={isLoading}
            matchToEdit={matchToEdit}
            onEdit={setMatchToEdit}
            onConfirmEdit={handleChangeTeamScore.execute}
            isConfirmingEdit={handleChangeTeamScore.loading}
            updateData={updateData}
            isFieldNumberChanged={isNumber(updatedFieldNumberValue)}
          />
        </>
      ))}
    </div>
  );
};

export default KnockoutsPage;
