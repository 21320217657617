import React, { useMemo, useState } from "react";
import { RouteChildrenProps } from "react-router";
import { usePoolsQuery, useUpdateFieldNumberMutation, useUpdateMatchScoreMutation } from "../../../data/api/usePools";
import useStore from "../../../store";
import { PoolsPageControls, TPoolOption } from "./poolsPageControls";
import { IMatchScore, IPoolGame, TeamClass } from "../../../data/api/types";
import { filter, find, groupBy, isNumber, map, sortBy, toPairs, uniqBy } from "lodash";
import { PoolTeamsTable } from "./poolTeamsTable";
import { usePoolTeamsTable } from "./usePoolTeamsTable";
import { PoolGamesTable } from "./poolGamesTable";
import { useAsyncCallback } from "react-async-hook";
import { formatDate } from "../../../utils/formatDate";
import { IsMixedPoule } from "../utils/helpers/isMixedPouleChecker";

const PoolsPage: React.VFC<RouteChildrenProps> = () => {
  const { tournamentId, addError } = useStore((s) => s);
  const { data, isLoading, refetch } = usePoolsQuery(tournamentId!, { enabled: !!tournamentId });
  const [teamClass, setTeamClass] = useState(TeamClass.MEN);
  const [poolOption, setPoolsOption] = useState<TPoolOption>();
  const [isMixed] = IsMixedPoule();

  const poolOptions = useMemo(() => {
    let selectedPools = data?.[teamClass];
    if (selectedPools?.length === 0) {
      if (teamClass === TeamClass.WOMEN) {
        selectedPools = data?.men;
        setTeamClass(TeamClass.MEN);
      } else if (teamClass === TeamClass.MEN) {
        selectedPools = data?.women;
        setTeamClass(TeamClass.WOMEN);
      }
    }
    if (selectedPools) {
      const options = sortBy<TPoolOption>(
        uniqBy(
          map(selectedPools, (p) => ({ id: p.name, name: `Poule ${p.name}` })),
          "id"
        ),
        (p) => p.id
      );
      if (!poolOption || !find(options, poolOption)) setPoolsOption(options[0]);

      return options;
    }
  }, [data, teamClass, poolOption]);

  const pool = useMemo(() => {
    if (poolOption) {
      const selectedPools = data?.[teamClass];

      const filteredData = filter(selectedPools, (p) => p.name === poolOption.id);
      return filteredData[0];
    }
  }, [data, teamClass, poolOption]);

  const teamsTableInstance = usePoolTeamsTable(pool?.teams);

  return (
    <div className="w-full space-y-6">
      {poolOption && poolOptions && (
        <PoolsPageControls
          teamClass={teamClass}
          toggleTeamClass={setTeamClass}
          poolsName={poolOption}
          poolOptions={poolOptions}
          setPoolsName={setPoolsOption}
          isMixedPoule={isMixed}
        />
      )}
      <PoolTeamsTable tableInstance={teamsTableInstance} loading={isLoading} />
    </div>
  );
};

export default PoolsPage;
