import React, { forwardRef } from "react";
import classNames from "classnames";
import { Spinner } from "../Spiner";

type HTMLButtonProps = React.ComponentPropsWithoutRef<"button">;

export interface IButtonProps extends HTMLButtonProps {
  label: string;
  withIcon?: boolean;
  loading?: boolean;
  secondary?: boolean;
  dangerous?: boolean;
  sm?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, IButtonProps>(
  ({ className, withIcon, secondary, dangerous, sm, label, disabled, loading, ...rest }, ref) => {
    return (
      <button
        ref={ref}
        {...rest}
        disabled={disabled || loading}
        className={classNames(
          !secondary
            ? dangerous
              ? "font-bold whitespace-nowrap bg-red-error items-center border border-red-error flex focus:outline-none focus:ring-2 focus:ring-red-error focus:ring-offset-2 hover:opacity-95 justify-center md:w-auto px-4 py-3 rounded-lg uppercase text-white w-full disabled:opacity-50 disabled:cursor-not-allowed"
              : "font-bold whitespace-nowrap bg-purple-primary items-center border border-purple-primary flex focus:outline-none focus:ring-2 focus:ring-purple-primary focus:ring-offset-2 hover:opacity-95 justify-center md:w-auto px-4 py-3 rounded-lg uppercase text-white w-full disabled:opacity-50 disabled:cursor-not-allowed"
              : "font-bold whitespace-nowrap bg-purple-primary items-center border border-purple-primary flex focus:outline-none focus:ring-2 focus:ring-purple-primary focus:ring-offset-2 hover:opacity-95 justify-center md:w-auto px-4 py-3 rounded-lg uppercase text-white w-full disabled:opacity-50 disabled:cursor-not-allowed",
          className,
          { "opacity-50 pointer-events-none": disabled, "text-base px-20": !sm }
        )}
      >
        <Spinner visible={loading} />
        {label}
        {withIcon && (
          <svg className="h-4 ml-3" width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.995981 0.167368C1.21914 -0.0557892 1.58095 -0.0557892 1.8041 0.167368L8.8274 7.19067C8.93539 7.29396 9.02163 7.41785 9.08103 7.55504C9.14183 7.6955 9.17321 7.84694 9.17321 8C9.17321 8.15306 9.14183 8.30449 9.08103 8.44495C9.02163 8.58215 8.93538 8.70604 8.8274 8.80933L1.8041 15.8326C1.58095 16.0558 1.21914 16.0558 0.995981 15.8326C0.772824 15.6095 0.772824 15.2477 0.995981 15.0245L8.02049 8L0.995981 0.97549C0.772824 0.752333 0.772824 0.390524 0.995981 0.167368Z" fill="white"/>
          </svg>
        )}
      </button>
    );
  }
);
