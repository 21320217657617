export function veldWisselKnockOutFase({
  fase,
  veldWisselMinuten,
}: {
  fase: string | null;
  veldWisselMinuten: number;
}) {
  let aantalMinuten = 0;

  switch (fase) {
    case "LAST_32":
      aantalMinuten = 31 * veldWisselMinuten;
      break;
    case "LAST_16":
      aantalMinuten = 15 * veldWisselMinuten;
      break;
    case "QUARTER_FINAL":
      aantalMinuten = 7 * veldWisselMinuten;
      break;
    case "HALF_FINAL":
      aantalMinuten = 3 * veldWisselMinuten;
      break;
    case "FINAL":
      aantalMinuten = 0;
      break;
    default:
      break;
  }

  return aantalMinuten;
}
