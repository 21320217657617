import client from "../../client";
import { useQuery, UseQueryOptions } from "react-query";
import { AxiosError } from "axios";
import { ENDPOINTS } from "../../../constants";
import { IMatch, TeamClass } from "../types";

const QUERY_KEY = "matches";

export function useMatchesQuery(
  tournamentId: string,
  options?: UseQueryOptions<Record<TeamClass, IMatch[]>, AxiosError>
) {
  return useQuery(
    [QUERY_KEY, tournamentId],
    async () => {
      const { data: men } = await client.get(ENDPOINTS.MATCH_MEN.replace("{tournamentId}", tournamentId));
      const { data: women } = await client.get(ENDPOINTS.MATCH_WOMEN.replace("{tournamentId}", tournamentId));
      return { [TeamClass.MEN]: men, [TeamClass.WOMEN]: women };
    },
    options
  );
}
