import React from "react";
import { RadioGroup } from "@headlessui/react";
import classNames from "classnames";

export interface IRadioButtonProps<T> {
   value: T;
   label: string;
}

export const RadioButton = <T extends unknown>({ value, label }: IRadioButtonProps<T>) => {
   return (
      <RadioGroup.Option className="outline-none cursor-pointer" value={value}>
         {({ checked }) => (
            <div>
               <span
                  className = {
                     classNames("border font-bold inline-flex justify-center p-3 rounded-lg text-base w-full uppercase",
                        {
                           "bg-purple-primary text-white border-purple-primary": checked,
                           "bg-white text-neutral-400 hover:text-neutral-600 hover:border-neutral-600":!checked,
                        }
                     )
                  }
                  >
                  {label}
               </span>
            </div>
         )}
      </RadioGroup.Option>
   );
};
