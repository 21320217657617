import React, { useState } from "react";
import { Select } from "components/Select";

interface IRefereeSelectorProps {
  selectedReferee: string;
  onChange(refereeId: any): void;
  referees: any;
  disabled: boolean;
}

export const RefereeSelector: React.VFC<IRefereeSelectorProps> = ({
  selectedReferee,
  onChange,
  referees,
  disabled,
}) => {
  const [referee, setSelectedReferee] = useState({ id: selectedReferee, name: selectedReferee });

  return (
    <div className="flex flex-col z-10 justify-start  sm:flex-row sm:items-center sm:justify-between sm:space-y-0 space-y-3">
      <Select
        onSelect={(opt: any) => {
          setSelectedReferee(opt);
          onChange(opt.name);
        }}
        key={selectedReferee}
        options={referees || []}
        value={referee ? referee : referees[0]}
      />
    </div>
  );
};
