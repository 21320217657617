import React, { useEffect, useState } from "react";
import { RouteChildrenProps } from "react-router";
import useStore from "store";
import { useTeamsQuery } from "data/api/useTeams";
import { useTeamsTable } from "./useTeamsTable";
import { TeamsTable } from "./teamsTable";
import { TeamsPageControls } from "./teamsPageControls";
import { useAsyncCallback } from "react-async-hook";
import { Modal } from "../../../components/modal";
import { DeleteDialog } from "./deleteDialog";
import { EditDialog } from "./editDialog";
import { CreateDialog } from "./createDialog";
import {
  useDeleteTeamMutation,
  IUpdateTeamFormValues,
  useUpdateTeamMutation,
  ICreateTeamFormValues,
  useCreateTeamMutation,
} from "../../../data/api/useTeams";
import { TeamClass, ITeam } from "../../../data/api/types";
import { IsMixedPoule } from "../utils/helpers/isMixedPouleChecker";

const TeamsPage: React.VFC<RouteChildrenProps> = () => {
  const { tournamentId, addError } = useStore((s) => s);
  const [teamClass, setTeamClass] = useState(TeamClass.MEN);
  const { data, isLoading, refetch } = useTeamsQuery(tournamentId!, { enabled: !!tournamentId });
  const tableInstance = useTeamsTable(data?.[teamClass]);
  const [isMixed] = IsMixedPoule();

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [teamToDelete, setItemToDelete] = useState<string>();
  const { mutateAsync: deleteTeamMutation } = useDeleteTeamMutation({
    onError: (err) => {
      addError(err.response?.data.message);
      setOpenDeleteDialog(false);
    },
  });

  const [teamToUpdate, setTeamToUpdate] = useState<ITeam>();
  const { mutateAsync: updateTeamMutation } = useUpdateTeamMutation({
    onError: (err) => {
      addError(err.response?.data.message);
      setOpenDeleteDialog(false);
    },
  });

  const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
  const { mutateAsync: createTeamMutation } = useCreateTeamMutation({
    onError: (err) => {
      addError(err.response?.data.message);
      setOpenCreateDialog(false);
    },
  });

  const deleteTeam = (teamId: string) => {
    setItemToDelete(teamId);
    setOpenDeleteDialog(true);
  };

  const handleConfirmDelete = useAsyncCallback(async () => {
    if (teamToDelete) {
      await deleteTeamMutation({ teamId: teamToDelete });
      await refetch();
      setOpenDeleteDialog(false);
    }
  });

  const editTeam = (id: string) => {
    setTeamToUpdate(data?.[teamClass].find((d) => d.id === id));
  };

  const handleConfirmUpdate = useAsyncCallback(async (data: IUpdateTeamFormValues) => {
    await updateTeamMutation({ teamClass, teamId: teamToUpdate?.id!, data });
    await refetch();
    setTeamToUpdate(undefined);
  });

  const handleConfirmCreate = useAsyncCallback(async ({ teamClass, ...data }: ICreateTeamFormValues) => {
    if (tournamentId) {
      // @ts-ignore
      await createTeamMutation({ teamClass, data: { ...data, tournamentId } });
      await refetch();
      setOpenCreateDialog(false);
    }
  });

  useEffect(() => {
    let selectedTeams = data?.[teamClass];
    if (selectedTeams?.length === 0) {
      if (teamClass === TeamClass.WOMEN) {
        setTeamClass(TeamClass.MEN);
      } else if (teamClass === TeamClass.MEN) {
        setTeamClass(TeamClass.WOMEN);
      }
    }
  }, [data, teamClass]);

  return (
    <div className="w-full space-y-6">
      <TeamsPageControls
        onCreateBtnClick={() => setOpenCreateDialog(true)}
        tableInstance={tableInstance}
        teamClass={teamClass}
        toggleTeamClass={setTeamClass}
        isMixedPoule={isMixed}
      />
      <TeamsTable onDelete={deleteTeam} onEdit={editTeam} loading={isLoading} tableInstance={tableInstance} />
      <Modal open={openDeleteDialog} close={() => setOpenDeleteDialog(false)}>
        <DeleteDialog
          confirm={handleConfirmDelete.execute}
          loading={handleConfirmDelete.loading}
          close={() => setOpenDeleteDialog(false)}
        />
      </Modal>
      <Modal open={!!teamToUpdate} close={() => setTeamToUpdate(undefined)}>
        <EditDialog
          data={teamToUpdate}
          confirm={handleConfirmUpdate.execute}
          loading={handleConfirmUpdate.loading}
          close={() => setTeamToUpdate(undefined)}
        />
      </Modal>
      <Modal open={openCreateDialog} close={() => setOpenCreateDialog(false)}>
        <CreateDialog
          teamClass={teamClass}
          confirm={handleConfirmCreate.execute}
          loading={handleConfirmCreate.loading}
          close={() => setOpenCreateDialog(false)}
        />
      </Modal>
    </div>
  );
};

export default TeamsPage;
