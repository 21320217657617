import { useSortBy, useTable } from "react-table";
import { IPoolTeam } from "../../../data/api/types";

const columns = [
  {
    Header: "Teamnaam",
    accessor: "team.name",
  },
  {
    Header: "Gespeeld",
    accessor: "matchesPlayed",
  },
  {
    Header: "Winst",
    accessor: "matchesWon",
  },
  {
    Header: "Gelijk",
    accessor: "matchesTied",
    sortType: "basic",
  },
  {
    Header: "Verlies",
    accessor: "matchesLost",
    sortType: "basic",
  },
  {
    Header: "Doelpunten voor",
    accessor: "goalsScored",
    isSorted: true,
    sortType: "basic",
  },
  {
    Header: "Doelpunten tegen",
    accessor: "goalsReceived",
    isSorted: true,
    sortType: "basic",
  },
  {
    Header: "Doelsaldo",
    accessor: "goalCount",
    isSorted: true,
    sortType: "basic",
  },
  {
    Header: "Punten",
    accessor: "points",
    isSorted: true,
    sortType: "basic",
  },
];

export function usePoolTeamsTable(data: IPoolTeam[] = []) {
  return useTable(
    {
      // @ts-expect-error
      columns,
      data,

      initialState: {
        // @ts-expect-error
        sortBy: [
          {
            id: "points",
            desc: true,
          },
          {
            id: "goalCount",
            desc: true,
          },
          {
            id: "goalsScored",
            desc: true,
          },
          {
            id: "goalsReceived",
            desc: true,
          },
        ],
      },
      autoResetSortBy: false,
      maxMultiSortColCount: 20,
    },
    useSortBy
  );
}
