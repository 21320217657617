export function calculateAantalMatchesKnockoutFase({ fase }: { fase: string | null }) {
  let aantalMatches = 0;

  switch (fase) {
    case "LAST_32":
      aantalMatches = 31;
      break;
    case "LAST_16":
      aantalMatches = 15;
      break;
    case "QUARTER_FINAL":
      aantalMatches = 7;
      break;
    case "HALF_FINAL":
      aantalMatches = 3;
      break;
    case "FINAL":
      aantalMatches = 1;
      break;
    default:
      break;
  }

  return aantalMatches;
}
