import { TFilterOptions, TOption } from "./MatchesPageControls";
import { IMatch, TeamClass } from "../../../data/api/types";
import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { reject, isNil, filter, sortBy, uniqBy } from "lodash";
import { formatDate } from "../../../utils/formatDate";

const defaultOptions: TFilterOptions = {
  date: [],
  poule: [{ id: "all", name: "Alle fasen" }],
  team: [{ id: "all", name: "Alle teams" }],
  referee: [{ id: "all", name: "Alle scheidsrechters" }],
  fieldNumber: [{ id: "all", name: "Alle velden" }],
};

export function useFilters(currentTeamType: TeamClass, data?: Record<TeamClass, IMatch[]>) {
  const [selected, setSelected] = useState<TFilterOptions>();
  const [options, setOptions] = useState<TFilterOptions>();

  const dateToDay = (date: string) => {
    const momentDate = moment.parseZone(date);
    momentDate.locale("nl");
    return { name: momentDate.format("dddd"), num: momentDate.isoWeekday() };
  };

  useEffect(() => {
    if (data?.[currentTeamType].length) {
      let allOptions = data[currentTeamType].reduce((prev, curr) => {
        const { name, num } = dateToDay(curr.startTime);
        return {
          date: [...prev.date, { id: num, name: name, display: formatDate(curr.startTime) }],
          poule: [...prev.poule, { id: curr.poule, name: curr.poule }],
          team: [...prev.team, { id: curr.team1, name: curr.team1 }, { id: curr.team2, name: curr.team2 }],
          referee: [...prev.referee, { id: curr.referee, name: curr.referee }],
          fieldNumber: [...prev.fieldNumber, { id: curr.fieldNumber, name: `Veld ${curr.fieldNumber + 1}` }],
        };
      }, defaultOptions);
      for (const [key, value] of Object.entries(allOptions)) {
        // @ts-expect-error
        const defaultOption = defaultOptions[key][0];
        // prettier-ignore
        // @ts-expect-error
        allOptions[key] = reject([defaultOption, ...filter(sortBy(uniqBy(value, "id"), "id"), (d) => !isNil(d.id) && d.id !== defaultOption?.id)],isNil);
      }
      // @ts-ignore
      setSelected({ ...defaultOptions, date: [allOptions.date[0]] });
      setOptions(allOptions);
    }
  }, [currentTeamType, data]);

  const onChange = (opts: Partial<TFilterOptions>) => {
    // @ts-expect-error
    const nextSelected: TFilterOptions = { ...(selected || {}), ...opts };

    setSelected(nextSelected);
  };

  const filteredData = useMemo(() => {
    if (data && selected) {
      const optToArr = (option: TOption<string[] | number[]>) => option.map(({ id }) => id);
      const filtered = data[currentTeamType].filter((entry) => {
        if (!optToArr(selected.date).includes(moment.parseZone(entry.startTime).isoWeekday())) {
          return false;
        }
        if (!optToArr(selected.poule).includes(entry.poule)) {
          if (!optToArr(selected.poule).includes("all")) return false;
        }
        if (!optToArr(selected.team).includes(entry.team1) && !optToArr(selected.team).includes(entry.team2)) {
          if (!optToArr(selected.team).includes("all")) return false;
        }
        if (!optToArr(selected.referee).includes(entry.referee)) {
          if (!optToArr(selected.referee).includes("all")) return false;
        }
        if (!optToArr(selected.fieldNumber).includes(entry.fieldNumber)) {
          if (!optToArr(selected.fieldNumber).includes("all")) return false;
        }
        return true;
      });
      // return sortBy( filtered, (d) => ( new Date(d.startTime).getSeconds() || d.poule ) );
      return filtered.sort( (a, b) => ( new Date(a.startTime).getSeconds() || b.poule.localeCompare(a.poule) ) );
    }
  }, [currentTeamType, data, selected]);

  return { onChange, options, selected, filteredData };
}
