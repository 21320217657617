import { useMutation, UseMutationOptions } from "react-query";
import { AxiosError } from "axios";
import { ENDPOINTS } from "../../../constants";
import client from "../../client";

export function useMatchesPdfLazyQuery(options?: UseMutationOptions<any, AxiosError, string[]>) {
  return useMutation(
    "match-pdf",
    async (matchIds: string[]) => {
      const response = await client.post(ENDPOINTS.MATCH_PDF, { matchIds }, { responseType: "arraybuffer" });
      const type = response.headers["content-type"];
      // @ts-ignore
      const blob = new Blob([response.data], { type: type, encoding: "UTF-8" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "matches.pdf";
      link.click();
    },
    options
  );
}
