import React from "react";
import { ToggleButtons } from "../../../components/ToggleButtons";
import { TeamClass } from "../../../data/api/types";
import { Select } from "components/Select";

export type TPoolOption = { id: string; name: string };

interface IPoolsPageControlsProps {
  toggleTeamClass(teamClass: TeamClass): void;
  teamClass: TeamClass;
  poolsName: TPoolOption;
  poolOptions: TPoolOption[];
  setPoolsName(option: TPoolOption): void;
  isMixedPoule: boolean;
}

export const PoolsPageControls: React.VFC<IPoolsPageControlsProps> = ({
  poolOptions,
  poolsName,
  setPoolsName,
  toggleTeamClass,
  teamClass,
  isMixedPoule,
}) => {
  return (
    <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between sm:space-y-0 space-y-3">
      <div className="flex flex-1 items-center space-x-3">
        <div className="-mt-2">
          {isMixedPoule && (
            <ToggleButtons<TeamClass>
              secondary
              onChange={toggleTeamClass}
              value={teamClass}
              buttons={[
                { value: TeamClass.MEN, label: "Heren" },
                { value: TeamClass.WOMEN, label: "Dames" },
              ]}
            />
          )}
        </div>
        <Select<TPoolOption> options={poolOptions} value={poolsName} onSelect={setPoolsName} />
      </div>
    </div>
  );
};
