import React, { forwardRef } from "react";
import classNames from "classnames";
import { MinusIcon as MinusIconSolid, PlusIcon as PlusIconSolid } from "@heroicons/react/solid";

type HTMLCounterButtonProps = React.ComponentPropsWithoutRef<"button">;

export interface CounterButtonProps extends Omit<HTMLCounterButtonProps, "type"> {
  type: "minus" | "plus";
  children?: never;
}

export const CounterButton = forwardRef<HTMLButtonElement, CounterButtonProps>(({ type, className, ...rest }, ref) => {
  return (
    <button
      ref={ref}
      {...rest}
      type="button"
      className={classNames(
        "bg-purple-primary hover:opacity-90 focus:bg-purple-primary focus:outline-none focus:ring-2 focus:ring-purple-primary focus:ring-offset-2 inline-flex items-center p-1 rounded-full text-white",
        className
      )}
    >
      {type === "minus" && <MinusIconSolid className="h-9 w-9" aria-hidden="true" />}
      {type === "plus" && <PlusIconSolid className="h-9 w-9" aria-hidden="true" />}
    </button>
  );
});
