import React, { forwardRef } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import classNames from "classnames";

type HTMLInputProps = React.ComponentPropsWithoutRef<"input">;

export interface ITextInput extends HTMLInputProps {
  id: string;
  label: string;
  type: HTMLInputProps["type"];
  labelClassName?: string;
  labelFontBold?: boolean;
  inputClassName?: string;
  error?: string;
}

export const TextInput = forwardRef<HTMLInputElement, ITextInput>(
  ({ id, label, className, labelClassName, labelFontBold = true, inputClassName, error, ...rest }, ref) => {
    return (
      <div className={className}>
        <label
          htmlFor={id}
          className={classNames(
            "block text-neutral-900 mb-3 font-medium",
            { "font-semibold": labelFontBold },
            labelClassName
          )}
        >
          {label}
        </label>
        <div className="mt-1 relative">
          <input
            id={id}
            ref={ref}
            {...rest}
            className={classNames(
              "p-3 appearance-none bg-white block border focus:outline-none focus:ring-0 placeholder-neutral-500 rounded-lg text-base w-full",
              {
                "border-neutral-500 focus:border-purple-primary focus:ring-purple-primary text-neutral-900": !error,
                "border-red-error focus:border-red-error focus:ring-red-error text-red-error": !!error,
              },
              inputClassName
            )}
            aria-invalid={!!error}
            aria-describedby={error ? `${id}-error` : undefined}
          />
          {error && (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <ExclamationCircleIcon className="h-5 w-5 text-red-error" aria-hidden="true" />
            </div>
          )}
        </div>
        {error && (
          <p className="mt-2 text-sm text-red-error" id={`${id}-error`}>
            {error}
          </p>
        )}
      </div>
    );
  }
);
