import React, { forwardRef, memo, useEffect, useState } from "react";
import classNames from "classnames";
import { Controller, useForm } from "react-hook-form";
import { Tabs } from "../../../components/Tabs";
import { FileInput } from "../../../components/Input/FileInput";
import { ActionButton } from "../../../components/Button/ActionButton";
import { Button } from "../../../components/Button/Button";
import { FileUploadHandler } from "../../../components/FileUploadHandler";
import useStore from "../../../store";
import { FormWrapper } from "../FormWrapper";
import { useUploadTournamentFiles } from "../../../hooks/UseUploadTournamentFiles";
import { useShippingFormTabs } from "../../../hooks/UseShippingFormTabs";
import { ITournamentConfigShaping } from "../../../data/api/useCreateTournamentMutation";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import moment from "moment";
import { DateInput } from "../../../components/DateInput";
import { useAsyncCallback } from "react-async-hook";

import useLogout from "../../../hooks/useLogout";

type HTMLFormProps = React.ComponentPropsWithoutRef<"form">;

interface IFormData extends ITournamentConfigShaping {}

export interface IShapingStepProps extends Omit<HTMLFormProps, "onSubmit"> {}

const messages: Joi.LanguageMessages = {
  "string.empty": "Dit veld is vereist",
  "time.min": "{#name} moet zijn na de {#limit}",
  "time.max": "{#name} moet eerder zijn dan de {#limit}",
  "time.invalid": "Dit {#name} veld is niet geldig!",
};

const schema = Joi.object<IFormData>({
  days: Joi.array().items({
    startDate: Joi.date().iso().required().messages({
      "any.required": "Dit Starttijd veld is vereist!",
    }),
    startTime: Joi.string()
      .regex(/\d{2}:\d{2}/)
      .custom((value, helpers) => {
        if (!moment(value, "HH:mm").isValid()) {
          return helpers.error("time.invalid", { name: "Starttijd" });
        }
        if (moment.parseZone(value, "HH:mm").isAfter(moment.parseZone(helpers.state.ancestors[0].endTime, "HH:mm"))) {
          return helpers.error("time.max", { limit: "eindtijd", name: "Starttijd" });
        }
        return value;
      })
      .required()
      .messages({
        "any.required": "Dit Starttijd veld is vereist!",
      }),
    endTime: Joi.string()
      .required()
      .custom((value, helpers) => {
        if (!moment(value, "HH:mm").isValid()) {
          return helpers.error("time.invalid", { name: "Eindtijd" });
        }
        if (
          moment.parseZone(value, "HH:mm").isBefore(moment.parseZone(helpers.state.ancestors[0].startTime, "HH:mm"))
        ) {
          return helpers.error("time.min", { limit: "Starttijd", name: "eindtijd" });
        }
        return value;
      })
      .messages({
        "any.required": "Dit Eindtijd veld is vereist!",
      }),
  }),
  teamId: Joi.string().required(),
  refreeId: Joi.string().required(),
}).messages(messages);

export const ShapingStep = forwardRef<HTMLFormElement, IShapingStepProps>(({ ...rest }, ref) => {
  const defaultValues = useStore((state) => state.tournamentConfig.shaping);
  const days = useStore((state) => state.tournamentConfig.formsMetaData?.daysCount);
  const setTournamentForms = useStore((state) => state.setTournamentConfig);
  const setCurrentForm = useStore((state) => state.setCurrentTournamentConfigForm);
  const storeMenCount = useStore((state) => state.setMensCount);
  const storeWomenCount = useStore((state) => state.setWomensCount);

  const [menCount, setMenCount] = useState(0);
  const [womenCount, setWomenCount] = useState(0);

  const formInstance = useForm<IFormData>({
    defaultValues,
    mode: "all",
    resolver: joiResolver(schema),
  });

  const logout = useLogout();
  const handleLogout = useAsyncCallback(logout);
  const activeLinkClass = "text-purple-primary font-bold";

  const { localFileNames, handleOnFileUpload, handleDeleteFile } = useUploadTournamentFiles(formInstance);
  const {
    handleSubmit,
    register,
    getValues,
    control,
    watch,
    trigger,
    formState: { errors },
    setError,
  } = formInstance;
  const { tabs, currentTab, handleOnTabChange } = useShippingFormTabs(errors, days);
  const daysValue = watch("days");
  const onSubmit = (data: IFormData) => {
    storeMenCount(menCount);
    storeWomenCount(womenCount);

    // check if days are valid in a row, so every day is greater than the previous day
    if (daysValue) {
      const daysArray = daysValue.map((day) => {
        return {
          startDate: day.startDate,
          startTime: day.startTime,
          endTime: day.endTime,
        };
      });
      const isValid = daysArray.every((day, index) => {
        if (index === 0) {
          return true;
        }
        const previousDay = daysArray[index - 1];
        const currentDay = daysArray[index];

        return moment(currentDay.startDate) > moment(previousDay.startDate);
      });
      if (!isValid) {
        setError(`days.0.startDate`, {
          type: "server side",
          message: "Alle dagen moeten na elkaar volgen. Bekijk alle data opnieuw ",
        });
      } else {
        setTournamentForms({ shaping: data });
        setCurrentForm("final");
      }
    }
  };

  const onBack = () => {
    setTournamentForms({ shaping: getValues() });
    setCurrentForm("base");
  };

  const onTabChange = useAsyncCallback(async (nextTabValue: number, prev: number = 0) => {
    // @ts-ignore
    await trigger(`days[${prev}]`);
    handleOnTabChange(nextTabValue);
  });

  return (
    <div className="col-12">
      <div className="flex flex-column justify-center h-full">
        <FormWrapper formTitle="Tijd om het toernooi vorm te gaan geven" formStep="2">
          <form autoComplete="off" ref={ref} {...rest} onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            {tabs.length > 1 && (
              <div className="hidden sm:block">
                <Tabs tabs={tabs} onChange={onTabChange.execute} value={currentTab} />
              </div>
            )}
            <div className="overflow-hidden space-y-6 sm:space-y-0">
              {tabs.map(({ value: tabValue, label }) => (
                <fieldset
                  key={`day-${tabValue}`}
                  className={classNames({
                    "sm:hidden": tabValue !== currentTab,
                  })}
                >
                  <legend
                    className={classNames("block font-bold text-gray-700 text-lg mb-3", {
                      "sm:hidden": tabs.length > 1,
                    })}
                  >
                    {label}
                  </legend>
                  <div className="space-y-6 sm:space-y-5">
                    <Controller
                      name={`days.${tabValue}.startDate`}
                      control={control}
                      rules={{ required: "Dit veld is vereist" }}
                      render={({ field: { onChange, onBlur, value }, fieldState: { invalid } }) => {
                        const selected = value ? new Date(value) : undefined;
                        return (
                          <DateInput
                            invalid={invalid}
                            id="start_date"
                            placeholder="dd/mm/yyyy"
                            mask={[/[0-9]/, /[0-9]/, "/", /[0-9]/, /[0-9]/, "/", /[1-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                            dateFormat="dd/MM/yyyy"
                            label="Kies datum"
                            onChange={(date: Date) => {
                              if (moment(new Date(date)) < moment(new Date())) {
                                setError(`days.${tabValue}.startDate`, {
                                  type: "server side",
                                  message: `Datum moet in de toekomst liggen`,
                                });
                              } else if (tabValue > 0) {
                                for (let index = 1; index < daysValue.length; index++) {
                                  if (tabValue === index) {
                                    const dateFromLastDay = moment(new Date(daysValue[index - 1].startDate));
                                    const selectedDate = moment(new Date(date));

                                    if (dateFromLastDay.isSame(selectedDate, "day")) {
                                      setError(`days.${tabValue}.startDate`, {
                                        type: "server side",
                                        message: `Dag ${tabValue + 1} kan niet het zelfde zijn als dag ${tabValue}`,
                                      });
                                    } else if (selectedDate.isBefore(dateFromLastDay)) {
                                      setError(`days.${tabValue}.startDate`, {
                                        type: "server side",
                                        message: `Dag ${tabValue + 1} kan niet voor dag ${tabValue} vallen.`,
                                      });
                                    } else {
                                      onChange(date);
                                    }
                                  }
                                }
                              } else {
                                onChange(date);
                              }
                            }}
                            onBlur={onBlur}
                            selected={selected}
                            //@ts-ignore
                            error={
                              //@ts-ignore
                              errors.days?.length > tabValue ? errors.days[tabValue]?.startDate?.message : undefined
                            }
                          />
                        );
                      }}
                    />
                    <Controller
                      name={`days.${tabValue}.startTime`}
                      control={control}
                      rules={{ required: "Dit veld is vereist" }}
                      render={({ field: { onChange, onBlur, value }, fieldState: { invalid } }) => {
                        const selected = !value
                          ? undefined
                          : moment
                              .parseZone(daysValue[tabValue].startDate)
                              .set({
                                hour: Number(value.split(":")[0]),
                                minute: Number(value.split(":")[1]),
                              })
                              .toDate();
                        const handleChange = (d: Date) => {
                          onChange(moment.parseZone(d).format("HH:mm").toString());
                        };
                        return (
                          <DateInput
                            invalid={invalid}
                            id="start_time"
                            placeholder="hh:mm"
                            label="Starttijd"
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="HH:mm"
                            timeFormat="HH:mm"
                            mask={[/[0-9]/, /[0-9]/, ":", /[0-9]/, /[0-9]/]}
                            popperPlacement="bottom-start"
                            //@ts-ignore
                            popperModifiers={[
                              {
                                name: "offset",
                                options: {
                                  offset: [105, 0],
                                },
                              },
                            ]}
                            onChange={handleChange}
                            onBlur={onBlur}
                            selected={selected}
                            error={
                              //@ts-ignore
                              errors?.days?.length > tabValue ? errors?.days?.[tabValue]?.startTime?.message : undefined
                            }
                          />
                        );
                      }}
                    />
                    <Controller
                      name={`days.${tabValue}.endTime`}
                      control={control}
                      render={({ field: { onChange, onBlur, value }, fieldState: { invalid } }) => {
                        const selected = !value
                          ? undefined
                          : moment
                              .parseZone(daysValue[tabValue].startDate)
                              .set({
                                hour: Number(value.split(":")[0]),
                                minute: Number(value.split(":")[1]),
                              })
                              .toDate();
                        const handleChange = (d: Date) => {
                          onChange(moment.parseZone(d).format("HH:mm").toString());
                        };
                        return (
                          <DateInput
                            invalid={invalid}
                            id="end_time"
                            placeholder="hh:mm"
                            label="Eindtijd"
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="HH:mm"
                            timeFormat="HH:mm"
                            mask={[/[0-9]/, /[0-9]/, ":", /[0-9]/, /[0-9]/]}
                            popperPlacement="bottom-start"
                            //@ts-ignore
                            popperModifiers={[
                              {
                                name: "offset",
                                options: {
                                  offset: [105, 0],
                                },
                              },
                            ]}
                            onChange={handleChange}
                            onBlur={onBlur}
                            selected={selected}
                            // @ts-expect-error
                            error={errors.days?.length > tabValue ? errors.days[tabValue]?.endTime?.message : undefined}
                          />
                        );
                      }}
                    />
                  </div>
                </fieldset>
              ))}
            </div>
            <div className="gap-3 grid grid-cols-2">
              <FileInput
                label="Teams"
                id="team_data"
                placeholder="Upload je CSV bestand of"
                uploadedPlaceholder="Uploaden van het bestand is geslaagd"
                accept=".csv"
                className="col-span-full sm:col-auto"
                onUpload={async (files) => {
                  const { womenTeamCount, menTeamCount } = await handleOnFileUpload.execute(files, "teamId");
                  setMenCount(menTeamCount);
                  setWomenCount(womenTeamCount);
                }}
                value={getValues("teamId")}
                error={errors.teamId?.message}
                {...register("teamId")}
              />
              <FileInput
                label="Scheidsrechters"
                id="referee_file_list"
                placeholder="Upload je CSV bestand of"
                uploadedPlaceholder="Uploaden van het bestand is geslaagd"
                accept=".csv"
                className="col-span-full sm:col-auto"
                onUpload={handleOnFileUpload.execute}
                value={getValues("refreeId")}
                error={errors.refreeId?.message}
                {...register("refreeId")}
              />
            </div>
            <div className="flex flex-col space-y-2">
              <FileUploadHandler
                id={getValues("teamId")}
                name="teamId"
                filename={localFileNames?.teamId || "teams.csv"}
                onDelete={handleDeleteFile}
              />
              <FileUploadHandler
                id={getValues("refreeId")}
                name="refreeId"
                filename={localFileNames?.refreeId || "scheidsrechters.csv"}
                onDelete={handleDeleteFile}
              />
            </div>
            <div className="fixed bottom-0 left-0 w-full bg-neutral-100 py-5 mt-0">
              <div className="container">
                <div className="row">
                  <div className="col-4">
                    <div className="flex h-full items-center">
                      <button
                        className={classNames(
                          activeLinkClass,
                          "inline-flex items-center focus:outline-none font-bold text-base text-purple-primary uppercase"
                        )}
                        onClick={handleLogout.execute}
                      >
                        Uitloggen
                        <svg
                          className="h-4 w-4 ml-2"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1.71429 1.14286C1.56273 1.14286 1.41739 1.20306 1.31022 1.31022C1.20306 1.41739 1.14286 1.56273 1.14286 1.71429V14.2857C1.14286 14.4373 1.20306 14.5826 1.31022 14.6898C1.41739 14.7969 1.56273 14.8571 1.71429 14.8571H9.71428C9.86584 14.8571 10.0112 14.7969 10.1183 14.6898C10.2255 14.5826 10.2857 14.4373 10.2857 14.2857V12C10.2857 11.6844 10.5416 11.4286 10.8571 11.4286C11.1727 11.4286 11.4286 11.6844 11.4286 12V14.2857C11.4286 14.7404 11.248 15.1764 10.9265 15.4979C10.605 15.8194 10.1689 16 9.71428 16H1.71429C1.25963 16 0.823593 15.8194 0.502103 15.4979C0.180612 15.1764 0 14.7404 0 14.2857V1.71429C0 1.25963 0.180612 0.823593 0.502103 0.502103C0.823593 0.180612 1.25963 0 1.71429 0H9.71428C10.1689 0 10.605 0.180612 10.9265 0.502103C11.248 0.823593 11.4286 1.25963 11.4286 1.71429V4C11.4286 4.31559 11.1727 4.57143 10.8571 4.57143C10.5416 4.57143 10.2857 4.31559 10.2857 4V1.71429C10.2857 1.56273 10.2255 1.41739 10.1183 1.31022C10.0112 1.20306 9.86584 1.14286 9.71428 1.14286H1.71429Z"
                            fill="#653EF2"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6.85714 8C6.85714 7.68441 7.11298 7.42857 7.42857 7.42857H15.4286C15.7442 7.42857 16 7.68441 16 8C16 8.31559 15.7442 8.57143 15.4286 8.57143H7.42857C7.11298 8.57143 6.85714 8.31559 6.85714 8Z"
                            fill="#653EF2"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12.7388 5.31022C12.962 5.08707 13.3238 5.08707 13.5469 5.31022L15.8326 7.59594C16.0558 7.8191 16.0558 8.1809 15.8326 8.40406L13.5469 10.6898C13.3238 10.9129 12.962 10.9129 12.7388 10.6898C12.5156 10.4666 12.5156 10.1048 12.7388 9.88165L14.6204 8L12.7388 6.11835C12.5156 5.89519 12.5156 5.53338 12.7388 5.31022Z"
                            fill="#653EF2"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>

                  <div className="col-8 flex justify-end">
                    <ActionButton type="button" label="Vorige" onClick={onBack} className="uppercase" />
                    <Button
                      type="submit"
                      style={{ maxWidth: "194px" }}
                      className="w-full pl-5 pr-5 float-right"
                      label="Volgende stap"
                      withIcon={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </FormWrapper>
      </div>
    </div>
  );
});
