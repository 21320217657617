export enum ROUTES {
  DASHBOARD = "/dashboard",
  CREATE_TOURNAMENT = "/create-tournament",
  LOGIN = "/login",
  CREATE_USER = "/create-user0392098",

  // dashboard sub routes
  DASHBOARD_TEAMS = "/dashboard/teams",
  DASHBOARD_POOLS = "/dashboard/pools",
  DASHBOARD_KNOCKOUT = "/dashboard/knock-out",
  DASHBOARD_MATCH_SCHEDULE = "/dashboard/match-schedule",
  DASHBOARD_SETTINGS = "/dashboard/settings",
  DASHBOARD_TOURNAMENT_OVERVIEW = "/dashboard/tournament-overview",

  // create tournamate sub routes
  CREATE_TOURNAMENT_BASE_STEP = "/base",
  CREATE_TOURNAMENT_SHAPING_STEP = "/shaping",
  CREATE_TOURNAMENT_FINAL_STEP = "/final",
}
