export enum ENDPOINTS {
  AUTH = "/auth",
  AUTH_REFRESH = "/auth/refresh",
  AUTH_LOGIN = "/auth/login",
  TOURNAMENT_CREATE = "/tournament/create",
  TOURNAMENT_EDIT = "/tournament/{tournamentId}",
  TOURNAMENT_DELETE = "/tournament/{tournamentId}",
  TOURNAMENT_SETTINGS = "/tournament/settings/{tournamentId}",
  TOURNAMENT_STATUS = "/tournament/status/{tournamentId}",
  UPLOAD_TEAM = "/team/upload",
  UPLOAD_REFEREE = "/referee/upload",

  /* JOOST */
  TOURNAMENT_REFEREE = "/referee/{tournamentId}",

  
  USER_PROFILE = "/user/profile",
  USER_CREATE = "/user/create",

  TEAM_MEN = "/team/men/{tournamentId}",
  TEAM_WOMEN = "/team/women/{tournamentId}",
  DELETE_TEAM = "/team/{teamId}",
  UPDATE_TEAM = "/team/{teamClass}/{teamId}",
  CREATE_TEAM = "/team/{teamClass}/create",

  POOL_MEN = "/poule/men/{tournamentId}",
  POOL_WOMEN = "/poule/women/{tournamentId}",

  KNOCKOUT_MEN = "/poule/knockout/men/{tournamentId}",
  KNOCKOUT_WOMEN = "/poule/knockout/women/{tournamentId}",

  MATCH_MEN = "/match/men/{tournamentId}",
  MATCH_WOMEN = "/match/women/{tournamentId}",
  UPDATE_MATCH = "/match/score/{matchId}",
  UPDATE_FIELD_NUMBER = "/match/{matchId}",
  MATCH_PDF = "/match/pdf",
  SWAP_TEAMS = "/poule/switchTeams",
}

export enum KNOCKOUT {
  LAST_16 = "LAST_16",
  QUARTER_FINAL = "QUARTER_FINAL",
  HALF_FINAL = "HALF_FINAL",
  FINAL = "FINAL",
  LAST_32 = "LAST_32",
  POULE = "POULE",
  EXTRA_POULE_ROUND_WINNERS = "EXTRA_POULE_ROUND_WINNERS",
  EXTRA_POULE_ROUND_LOSERS = "EXTRA_POULE_ROUND_LOSERS",
}
