import client from "../../client";
import { useQuery, UseQueryOptions } from "react-query";
import { AxiosError } from "axios";
import { ENDPOINTS } from "../../../constants";
import { IPool, TeamClass } from "../types";

const QUERY_KEY = "pools";

export function usePoolsQuery(tournamentId: string, options?: UseQueryOptions<Record<TeamClass, IPool[]>, AxiosError>) {
  return useQuery(
    [QUERY_KEY, tournamentId],
    async () => {
      const { data: men } = await client.get(ENDPOINTS.POOL_MEN.replace("{tournamentId}", tournamentId));
      const { data: women } = await client.get(ENDPOINTS.POOL_WOMEN.replace("{tournamentId}", tournamentId));
      return { [TeamClass.MEN]: men, [TeamClass.WOMEN]: women };
    },
    options
  );
}
