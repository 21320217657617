import React, { Fragment, ReactElement, useRef, useState } from "react";
import { Transition } from "@headlessui/react";
// import { ChevronDownIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import { find, uniqBy, filter, countBy } from "lodash";
import { useOnClickOutside } from "../hooks/useOnClickOutside";

type TOption = Record<"id" | "name", string | number>;

interface MultiSelectProps<T extends TOption> {
  options: T[];
  value: T[];
  onSelect(opt: T[]): void;
  label?: string;
}

export const MultiSelect = <T extends TOption>({ value, onSelect, options }: MultiSelectProps<T>): ReactElement => {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  useOnClickOutside(ref, () => setOpen(false));

  const handleSelect = (option: T) => {
    let next: T[];
    const isDuplicated = countBy([option, ...value], (o) => o.id)[option.id] > 1;
    if (isDuplicated) {
      next = filter(value, (v) => v.id !== option.id);
    } else {
      next = uniqBy([option, ...value], (v) => v.id);
    }
    if (next.length > 1 && find(value, (o) => o.id === "all")) {
      next = filter(next, (o) => o.id !== "all");
    }
    if (next.length > 1 && find(next, (o) => o.id === "all")) {
      next = filter(next, (o) => o.id === "all");
    }
    if (next.length) onSelect(next);
  };

  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          type="button"
          className="whitespace-nowrap cursor-pointer inline-flex justify-center items-center bg-white relative w-full border border-gray-300 rounded-md px-5 py-3 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-app-purple-primary focus:border-app-purple-primary"
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
          onClick={() => setOpen((prev) => !prev)}
          // onBlur={() => setOpen(false)}
        >
          {value[0].name}
          <svg className="ml-2 h-4 w-4" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.571442 4.3999L7.60001 11.4285C7.65132 11.4831 7.71328 11.5267 7.78208 11.5565C7.85087 11.5863 7.92505 11.6016 8.00001 11.6016C8.07498 11.6016 8.14915 11.5863 8.21795 11.5565C8.28675 11.5267 8.34871 11.4831 8.40001 11.4285L15.4286 4.3999" stroke="#272B30" stroke-width="1.14286" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
      </div>
      <Transition
        show={open}
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div
          className="overflow-auto z-10 min-w-full max-h-60 origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex={-1}
        >
          {open && (
            <div ref={ref} className="py-1" role="none">
              {options.map((option) => {
                const active = find(value, (o) => o.id === option.id);
                return (
                  <span
                    className={classNames("block px-4 py-2 text-sm cursor-pointer", {
                      "text-app-dark-soft hover:bg-app-purple-ultra-light": !active,
                      "text-white bg-app-purple-primary": active,
                    })}
                    role="menuitem"
                    tabIndex={-1}
                    id="menu-item-0"
                    onClick={() => handleSelect(option)}
                  >
                    {option.name}
                  </span>
                );
              })}
            </div>
          )}
        </div>
      </Transition>
    </div>
  );
};
