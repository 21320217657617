import React from "react";
import { TableInstance } from "react-table";
import { IPoolTeam } from "../../../data/api/types";
import classNames from "classnames";

interface IPoolTeamsTableProps {
  tableInstance: TableInstance<IPoolTeam>;
  loading: boolean;
}

export const PoolTeamsTable: React.VFC<IPoolTeamsTableProps> = ({ tableInstance, loading }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto">
        <div className="py-2 align-middle inline-block min-w-full">
          <div className="overflow-y-auto sm:rounded-md" style={{ maxHeight: "40vh" }}>
            <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
              <thead className="bg-app-dark-light sticky top-0">
                <tr>
                  <th className="px-6 py-3 text-left font-semibold text-app-dark-primary tracking-wider">
                    <span>#</span>
                  </th>
                  {headerGroups.map((headerGroup) =>
                    headerGroup.headers.map((column) => (
                      <th
                        scope="col"
                        className="px-6 py-3 text-left font-semibold text-app-dark-primary tracking-wider whitespace-nowrap"
                        {...column.getHeaderProps()}
                      >
                        {column.render("Header")}
                      </th>
                    ))
                  )}
                </tr>
              </thead>
              <tbody {...getTableBodyProps()} className="bg-white divide-y divide-app-dark-light">
                {rows.map((row, index) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      <td
                        className={classNames("px-6 py-4 whitespace-nowrap text-base text-app-dark-primary", {
                          "font-bold": index < 2,
                        })}
                      >
                        {index + 1}
                      </td>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            className={classNames("px-6 py-4 whitespace-nowrap text-base text-app-dark-primary", {
                              "font-bold": index < 2,
                            })}
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {!rows.length && (
              <tr className="flex items-center justify-center py-3 capitalize">
                {loading ? "loading" : "Geen teams om weer te geven!"}
              </tr>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
