import React, { ReactElement } from "react";
import { ITab, Tab } from "./Tab";

export interface ITabsProps<T> {
  value?: T;
  onChange(value: T, prev?: T): void;
  tabs: ITab<T>[];
}

export const Tabs = <T extends string | number>({ value, onChange, tabs }: ITabsProps<T>): ReactElement => {
  return (
    <div className="border-app-dark-light border-b">
      <nav className="-mb-px flex" aria-label="Tabs">
        {tabs.map((tabProps) => (
          <Tab
            {...tabProps}
            key={tabProps.value}
            onChange={(next) => onChange(next, value)}
            current={value === tabProps.value}
            tabsLength={tabs.length}
          />
        ))}
      </nav>
    </div>
  );
};
