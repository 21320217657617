import React, { useMemo, useState } from "react";
import { RouteChildrenProps } from "react-router";
import { Controller, useForm } from "react-hook-form";
import { useAsyncCallback } from "react-async-hook";
import { Button } from "../../components/Button/Button";
import { TextInput } from "components/Input/TextInput";
import useStore, { IFormsMetaData } from "../../store";
import { Navbar } from "../../components/Navbar";
import { RadioButtons } from "../../components/RadioButtons";
import useEditTournamentMutation, { IEditTournamentOptions } from "../../data/api/useEditTournamentMutation";
import { Tabs } from "../../components/Tabs";
import classNames from "classnames";
import { useShippingFormTabs } from "../../hooks/UseShippingFormTabs";
import { CounterInput } from "../../components/Input/CounterInput";
import { ToggleButtons } from "../../components/ToggleButtons";
import { KNOCKOUT } from "../../constants";
import { ActionButton } from "../../components/Button/ActionButton";
import { DeleteIcon } from "../../components/icons";
import { useQueryClient } from "react-query";
import useTournamentSettingsQuery from "../../data/api/useTournamentSettingsQuery";
import { Modal } from "../../components/modal";
import { DeleteDialog } from "./deleteDialog";
import useDeleteTournamentMutation from "../../data/api/useDeleteTournamentMutation";
import useLogout from "../../hooks/useLogout";
import { ROUTES } from "../../constants/routes";
import moment from "moment";
import { DateInput } from "../../components/DateInput";
import { ITab } from "../../components/Tabs/Tab";
import { ITournamentConfigFinal } from "../../data/api/useCreateTournamentMutation";

interface FormData
  extends IEditTournamentOptions,
    IFormsMetaData,
    Pick<
      ITournamentConfigFinal,
      "teamsPerPouleWomen" | "teamsPerPouleMen" | "knockoutStartMen" | "knockoutStartWomen"
    > {
  daysCount: number;
  hasBreak: "Ja" | "Nee";
}
type TTab = Required<IFormsMetaData>["type"];
const SettingsPage: React.VFC<RouteChildrenProps<{}, { from: Window["location"] }>> = ({ location, history }) => {
  const client = useQueryClient();
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const logout = useLogout({ replaceHistory: false });
  const { addError, tournamentId } = useStore((state) => state);
  const { mutateAsync } = useEditTournamentMutation(tournamentId!, {
    onError: (err) => addError(err.response?.data.message),
  });
  const { mutateAsync: deleteTournament } = useDeleteTournamentMutation({
    onError: (err) => addError(err.response?.data.message),
  });
  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isValid },
  } = useForm<FormData>({
    mode: "all",
  });
  const [currentType, setCurrentType] = useState<TTab>();
  useTournamentSettingsQuery(tournamentId!, {
    onSuccess: (d) => {
      const _defaultValue = {
        ...d,
        daysCount: d?.days.length,
        hasBreak: d?.breakTimeMinutes ? "Ja" : "Nee",
      };
      if (d.knockoutStartMen !== "NONE" && d.knockoutStartWomen !== "NONE") {
        setCurrentType("Combinatie");
        _defaultValue.type = "Combinatie";
      } else if (d.knockoutStartMen !== "NONE") {
        setCurrentType("Heren");
        _defaultValue.type = "Heren";
      } else if (d.knockoutStartWomen !== "NONE") {
        setCurrentType("Dames");
        _defaultValue.type = "Dames";
      }
      reset(_defaultValue);
    },
  });
  const [days, hasBreak, tType] = watch(["daysCount", "hasBreak", "type"]);
  const { tabs, currentTab, handleOnTabChange } = useShippingFormTabs(errors, days);
  const typeTabs = useMemo(() => {
    const res: ITab<TTab>[] = [];
    if (tType === "Heren" || tType === "Combinatie") {
      res.push({ label: "Heren", value: "Heren" });
    }
    if (tType === "Dames" || tType === "Combinatie") {
      res.push({ label: "Dames", value: "Dames" });
    }
    return res;
  }, [tType]);

  const onWindowClose = (e: BeforeUnloadEvent) => {
    const msg = "Het toernooi wordt nog gegenereerd. Wil je echt sluiten? Dit kan fouten opleveren in de generatie.";
    e = e || window.event;
    if (e) e.returnValue = msg;
    return msg;
  };
  const onSubmit = useAsyncCallback(
    async ({ daysCount, hasBreak, addition = "", breakTimeMinutes = 0, ...data }: FormData) => {
      window.addEventListener("beforeunload", onWindowClose);
      try {
        let options: IEditTournamentOptions = { ...data };
        if (addition) options.addition = addition;
        if (breakTimeMinutes > 0) options.breakTimeMinutes = breakTimeMinutes;
        await mutateAsync(options);
        client.clear();
      } catch (e) {}
      window.removeEventListener("beforeunload", onWindowClose);
    }
  );

  const handleConfirmDelete = useAsyncCallback(async () => {
    window.addEventListener("beforeunload", onWindowClose);
    try {
      await deleteTournament(tournamentId!);
      await logout();
      const { from } = location.state || { from: { pathname: ROUTES.CREATE_TOURNAMENT } };
      history.replace(from);
    } catch (e) {}
    window.removeEventListener("beforeunload", onWindowClose);
  });

  return (
    <div>
      <div className="flex max-h-screen min-h-screen sm:px-20 sm:py-10">
        <div className="bg-white flex flex-col sm:overflow-hidden sm:rounded-lg sm:shadow-soft w-full">
          <Navbar />
          <div className="flex h-full">
            <div className="relative bg-white overflow-auto sm:mb-10 flex flex-1 flex-col lg:flex-none lg:px-20 px-4 py-12 sm:px-6 xl:px-24 xl:w-1/2">
              <div className="lg:w-104 w-full">
                <div className="mt-6">
                  <form onSubmit={handleSubmit(onSubmit.execute)} className="space-y-6">
                    <TextInput
                      id="name"
                      type="text"
                      label="Naam toernooi"
                      autoComplete="organization"
                      placeholder="Naam toernooi"
                      error={errors.name?.message}
                      {...register("name", { required: "Dit veld is vereist" })}
                    />
                    <Controller
                      name="daysCount"
                      control={control}
                      rules={{ required: "Dit veld is vereist" }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <RadioButtons
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          error={errors.daysCount?.message}
                          label="Hoeveel dagen duurt het toernooi?"
                          buttons={[
                            { value: 1, label: "1 dag" },
                            { value: 2, label: "2 dagen" },
                            { value: 3, label: "3 dagen" },
                          ]}
                        />
                      )}
                    />
                    <fieldset>
                      <legend className="block font-bold text-app-dark-primary text-gray-700 text-lg mb-3">
                        Waar vindt het toernooi plaats?
                      </legend>
                      <div className="gap-3 grid grid-cols-1 sm:grid-cols-6">
                        <TextInput
                          type="text"
                          id="street_address"
                          autoComplete="street-address"
                          label="Adres"
                          placeholder="Adres"
                          labelFontBold={false}
                          labelClassName="sm:sr-only font-normal sm:font-bold"
                          className="sm:col-span-6"
                          error={errors.street?.message}
                          {...register("street", { required: "Dit veld is vereist" })}
                        />
                        <TextInput
                          type="text"
                          id="zip"
                          autoComplete="postal-code"
                          label="Postcode"
                          placeholder="Postcode"
                          labelFontBold={false}
                          labelClassName="sm:sr-only font-normal sm:font-bold"
                          className="sm:col-span-2"
                          error={errors.postalCode?.message}
                          {...register("postalCode", { required: "Dit veld is vereist" })}
                        />
                        <TextInput
                          type="number"
                          id="street-number"
                          label="Huisnr."
                          placeholder="Huisnr."
                          labelFontBold={false}
                          labelClassName="sm:sr-only font-normal sm:font-bold"
                          className="sm:col-span-2"
                          error={errors.streetNumber?.message}
                          {...register("streetNumber", {
                            required: "Dit veld is vereist",
                            valueAsNumber: true,
                          })}
                        />
                        <TextInput
                          type="text"
                          id="addition"
                          label="Toevoeging"
                          placeholder="Toevoeging"
                          labelFontBold={false}
                          labelClassName="sm:sr-only font-normal sm:font-bold"
                          className="sm:col-span-2"
                          {...register("addition", { required: false })}
                        />
                        <TextInput
                          type="text"
                          id="city"
                          label="Plaatsnaam"
                          placeholder="Plaatsnaam"
                          labelFontBold={false}
                          labelClassName="sm:sr-only font-normal sm:font-bold"
                          className="sm:col-span-6"
                          error={errors.city?.message}
                          {...register("city", { required: "Dit veld is vereist" })}
                        />
                      </div>
                    </fieldset>
                    {tabs.length > 1 && (
                      <div className="hidden sm:block">
                        <Tabs tabs={tabs} onChange={handleOnTabChange} value={currentTab} />
                      </div>
                    )}
                    <div className="overflow-hidden space-y-6 sm:space-y-0">
                      {tabs.map(({ value: tabValue, label }) => (
                        <fieldset
                          key={`day-${tabValue}`}
                          className={classNames({
                            "sm:hidden": tabValue !== currentTab,
                          })}
                        >
                          <legend
                            className={classNames("block font-bold text-app-dark-primary text-gray-700 text-lg mb-3", {
                              "sm:hidden": tabs.length > 1,
                            })}
                          >
                            {label}
                          </legend>
                          <div className="space-y-6 sm:space-y-5">
                            <Controller
                              // @ts-ignore
                              name={`days.${tabValue}.startDate`}
                              control={control}
                              rules={{ required: "Dit veld is vereist" }}
                              render={({ field: { onChange, onBlur, value } }) => {
                                const selected = value ? moment.parseZone(value as any).toDate() : undefined;
                                return (
                                  <DateInput
                                    id="start_date"
                                    placeholder="dd/mm/yyyy"
                                    mask={[
                                      /[0-9]/,
                                      /[0-9]/,
                                      "/",
                                      /[0-9]/,
                                      /[0-9]/,
                                      "/",
                                      /[1-9]/,
                                      /[0-9]/,
                                      /[0-9]/,
                                      /[0-9]/,
                                    ]}
                                    dateFormat="dd/MM/yyyy"
                                    label="Kies datum"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    selected={selected}
                                    // prettier-ignore
                                    // @ts-expect-error
                                    error={errors.days?.length > tabValue ? errors.days[tabValue]?.startDate?.message : undefined
                                    }
                                  />
                                );
                              }}
                            />
                            <Controller
                              name={`days.${tabValue}.startTime`}
                              control={control}
                              rules={{ required: "Dit veld is vereist" }}
                              render={({ field: { onChange, onBlur, value } }) => {
                                const selected = !value
                                  ? undefined
                                  : moment()
                                      .set({ hour: Number(value.split(":")[0]), minute: Number(value.split(":")[1]) })
                                      .toDate();
                                const handleChange = (d: Date) => {
                                  onChange(moment.parseZone(d).format("HH:mm").toString());
                                };
                                return (
                                  <DateInput
                                    id="start_time"
                                    placeholder="hh:mm"
                                    label="Starttijd"
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    timeFormat="HH:mm"
                                    mask={[/[0-9]/, /[0-9]/, ":", /[0-9]/, /[0-9]/]}
                                    popperPlacement="bottom-start"
                                    //@ts-ignore
                                    popperModifiers={[
                                      {
                                        name: "offset",
                                        options: {
                                          offset: [105, 0],
                                        },
                                      },
                                    ]}
                                    onChange={handleChange}
                                    onBlur={onBlur}
                                    selected={selected}
                                    // prettier-ignore
                                    // @ts-expect-error
                                    error={errors.days?.length > tabValue ? errors.days[tabValue]?.startTime?.message : undefined
                                    }
                                  />
                                );
                              }}
                            />
                            <Controller
                              name={`days.${tabValue}.endTime`}
                              control={control}
                              rules={{ required: "Dit veld is vereist" }}
                              render={({ field: { onChange, onBlur, value } }) => {
                                const selected = value
                                  ? moment()
                                      .set({ hour: Number(value.split(":")[0]), minute: Number(value.split(":")[1]) })
                                      .toDate()
                                  : undefined;
                                const handleChange = (d: Date) => {
                                  onChange(moment.parseZone(d).format("HH:mm").toString());
                                };
                                return (
                                  <DateInput
                                    id="end_time"
                                    placeholder="hh:mm"
                                    label="Eindtijd"
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    timeFormat="HH:mm"
                                    mask={[/[0-9]/, /[0-9]/, ":", /[0-9]/, /[0-9]/]}
                                    popperPlacement="bottom-start"
                                    //@ts-ignore
                                    popperModifiers={[
                                      {
                                        name: "offset",
                                        options: {
                                          offset: [105, 0],
                                        },
                                      },
                                    ]}
                                    onChange={handleChange}
                                    onBlur={onBlur}
                                    selected={selected}
                                    // prettier-ignore
                                    // @ts-expect-error
                                    error={errors.days?.length > tabValue ? errors.days[tabValue]?.endTime?.message : undefined}
                                  />
                                );
                              }}
                            />
                          </div>
                        </fieldset>
                      ))}
                    </div>
                    <div className="grid grid-cols-1 space-y-6 sm:grid-cols-2 sm:space-y-0">
                      <Controller
                        name="playingFields"
                        control={control}
                        rules={{
                          required: "Dit veld is vereist",
                          min: { value: 1, message: "min value is 0" },
                          max: { value: 99, message: "max value is 99" },
                        }}
                        render={({ field }) => (
                          <CounterInput
                            {...field}
                            label="Velden"
                            id="playing-fields"
                            error={errors.playingFields?.message}
                          />
                        )}
                      />
                    </div>
                    <div className="grid grid-cols-1 space-y-6 sm:grid-cols-2 sm:space-y-0">
                      <Controller
                        name="hasBreak"
                        control={control}
                        rules={{ required: true }}
                        defaultValue="Nee"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <ToggleButtons
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            buttons={[
                              { value: "Ja", label: "Ja" },
                              { value: "Nee", label: "Nee" },
                            ]}
                            label="Pauze inbegrepen?"
                          />
                        )}
                      />
                    </div>
                    <div
                      className={classNames(
                        "grid grid-cols-1 space-y-6 sm:space-y-0",
                        `sm:grid-cols-${hasBreak === "Ja" ? 3 : 2}`
                      )}
                    >
                      {hasBreak === "Ja" && (
                        <TextInput
                          type="number"
                          id="break-duration-minutes"
                          placeholder="In minuten"
                          label="Pauze"
                          className="sm:w-32"
                          error={errors.breakTimeMinutes?.message}
                          {...register("breakTimeMinutes", { required: "Dit veld is vereist", valueAsNumber: true })}
                        />
                      )}
                      <TextInput
                        type="number"
                        id="match-duration-minutes"
                        placeholder="In minuten"
                        label="Wedstrijd"
                        className="sm:w-32"
                        error={errors.matchDurationMinutes?.message}
                        {...register("matchDurationMinutes", { required: "Dit veld is vereist", valueAsNumber: true })}
                      />
                      <TextInput
                        type="number"
                        id="swap-time-minutes"
                        placeholder="In minuten"
                        label="Veldwissel knockout"
                        className="sm:w-32"
                        error={errors.swapTimeMinutesKnockout?.message}
                        {...register("swapTimeMinutesKnockout", { required: "Dit veld is vereist", valueAsNumber: true })}
                      />
                    </div>
                    {currentType === "Combinatie" && (
                      <div className="hidden sm:block">
                        <Tabs<TTab> tabs={typeTabs} onChange={(v) => setCurrentType(v)} value={currentType} />
                      </div>
                    )}
                    {currentType === "Heren" && (
                      <>
                        <div className="grid grid-cols-1 space-y-6 sm:grid-cols-2 sm:space-y-0">
                          <Controller
                            name="teamsPerPouleMen"
                            control={control}
                            rules={{
                              required: "Dit veld is vereist",
                              min: { value: 1, message: "min value is 0" },
                              max: { value: 99, message: "max value is 99" },
                            }}
                            render={({ field }) => (
                              <CounterInput
                                {...field}
                                label="Teams per poule"
                                id="teams-per-pole"
                                error={errors.teamsPerPouleMen?.message}
                              />
                            )}
                          />
                        </div>
                        <div className="space-y-3">
                          <Controller
                            name="knockoutStartMen"
                            control={control}
                            rules={{ required: "Dit veld is vereist" }}
                            render={({ field: { onChange, onBlur, value } }) => (
                              <RadioButtons
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                error={errors.knockoutStartMen?.message}
                                label="Kies startpunt knock-out fase*"
                                buttonsWrapperClassNameReplacement="mt-1 inline-grid grid-cols-2 gap-3"
                                buttons={[
                                  { value: KNOCKOUT.LAST_32, label: "Laatste 32" },
                                  { value: KNOCKOUT.LAST_16, label: "Laatste 16" },
                                  { value: KNOCKOUT.FINAL, label: "Kwartfinale" },
                                  { value: KNOCKOUT.HALF_FINAL, label: "Halve finale" },
                                  { value: KNOCKOUT.QUARTER_FINAL, label: "Alleen finale" },
                                ]}
                              />
                            )}
                          />
                        </div>
                      </>
                    )}
                    {currentType === "Dames" && (
                      <>
                        <div className="grid grid-cols-1 space-y-6 sm:grid-cols-2 sm:space-y-0">
                          <Controller
                            name="teamsPerPouleWomen"
                            control={control}
                            rules={{
                              required: "Dit veld is vereist",
                              min: { value: 1, message: "min value is 0" },
                              max: { value: 99, message: "max value is 99" },
                            }}
                            render={({ field }) => (
                              <CounterInput
                                {...field}
                                label="Teams per poule"
                                id="teams-per-pole"
                                error={errors.teamsPerPouleWomen?.message}
                              />
                            )}
                          />
                        </div>
                        <div className="space-y-3">
                          <Controller
                            name="knockoutStartWomen"
                            control={control}
                            rules={{ required: "Dit veld is vereist" }}
                            render={({ field: { onChange, onBlur, value } }) => (
                              <RadioButtons
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                error={errors.knockoutStartWomen?.message}
                                label="Kies startpunt knock-out fase*"
                                buttonsWrapperClassNameReplacement="mt-1 inline-grid grid-cols-2 gap-3"
                                buttons={[
                                  { value: KNOCKOUT.LAST_32, label: "Laatste 32" },
                                  { value: KNOCKOUT.LAST_16, label: "Laatste 16" },
                                  { value: KNOCKOUT.FINAL, label: "Kwartfinale" },
                                  { value: KNOCKOUT.HALF_FINAL, label: "Halve finale" },
                                  { value: KNOCKOUT.QUARTER_FINAL, label: "Alleen finale" },
                                ]}
                              />
                            )}
                          />
                        </div>
                      </>
                    )}
                    <div className="flex flex-wrap sm:space-x-3 space-y-3 sm:space-y-0">
                      <Button
                        type="submit"
                        label={!onSubmit.loading ? "Wijzigingen opslaan" : "loading"}
                        loading={onSubmit.loading}
                        disabled={!isValid}
                        sm
                      />
                      <ActionButton
                        type="button"
                        dangerous
                        label="Toernooi verwijderen"
                        renderIcon={() => <DeleteIcon className={"h-8 -mr-2 sm:mr-1 w-8"} />}
                        sm
                        onClick={() => setOpenDeleteDialog(true)}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="hidden lg:block relative w-0 flex-1 relative">
              <div className="app-background-gradient absolute inset-0 z-10 opacity-60" />
              <img
                className="absolute inset-0 h-full w-full object-cover"
                src="/images/visual-bg.png"
                alt="form cover"
              />
            </div>
          </div>
        </div>
      </div>
      <Modal open={openDeleteDialog} close={() => setOpenDeleteDialog(false)}>
        <DeleteDialog
          confirm={handleConfirmDelete.execute}
          loading={handleConfirmDelete.loading}
          close={() => setOpenDeleteDialog(false)}
        />
      </Modal>
    </div>
  );
};

export default SettingsPage;
