import client from "../../client";
import { useQuery, UseQueryOptions } from "react-query";
import { AxiosError } from "axios";
import { ENDPOINTS } from "../../../constants";
import { ITeam, TeamClass } from "../types";

const QUERY_KEY = "teams";

export function useTeamsQuery(tournamentId: string, options?: UseQueryOptions<Record<TeamClass, ITeam[]>, AxiosError>) {
  return useQuery(
    [QUERY_KEY, tournamentId],
    async () => {
      const { data: men } = await client.get(ENDPOINTS.TEAM_MEN.replace("{tournamentId}", tournamentId));
      const { data: women } = await client.get(ENDPOINTS.TEAM_WOMEN.replace("{tournamentId}", tournamentId));
      return { [TeamClass.MEN]: men, [TeamClass.WOMEN]: women };
    },
    options
  );
}
