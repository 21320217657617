import React, { forwardRef, ReactElement } from "react";
import classNames from "classnames";

type HTMLActionButtonProps = React.ComponentPropsWithoutRef<"button">;

export interface IActionButtonProps extends HTMLActionButtonProps {
  label: string;
  loading?: boolean;
  noIcon?: boolean;
  dangerous?: boolean;
  renderIcon?(className: string): ReactElement;
  sm?: boolean;
}

export const ActionButton = forwardRef<HTMLButtonElement, IActionButtonProps>(
  ({ className, dangerous, renderIcon, sm, label, noIcon, loading, ...rest }, ref) => {
    return (
      <button
        ref={ref}
        {...rest}
        className={classNames(
          dangerous
            ? "bg-app-red-ultra-light duration-150 ease-in-out focus:bg-app-red-ultra-light focus:outline-none focus:ring-2 focus:ring-red-error focus:ring-inset focus:text-red-error sm:focus:bg-transparent sm:focus:ring-0 sm:focus:text-app-red-soft font-bold inline-flex items-center justify-center px-3 py-3 rounded sm:bg-transparent sm:hover:text-red-error sm:px-0 sm:text-app-dark-soft sm:w-auto transition-colors w-full"
            : "bg-app-dark-ultra-light duration-150 ease-in-out focus:bg-app-dark-ultra-light focus:outline-none focus:ring-2 focus:ring-app-dark-primary focus:ring-inset focus:text-app-dark-primary sm:focus:bg-transparent sm:focus:ring-0 sm:focus:text-app-dark-soft font-bold inline-flex items-center justify-center px-3 py-3 rounded sm:bg-transparent sm:hover:text-app-dark-primary sm:px-0 sm:text-app-dark-soft sm:w-auto transition-colors w-full",
          className,
          { "sm:mr-6": !noIcon, "text-lg": !sm }
        )}
      >
        {!(!noIcon || renderIcon) && (
          <svg className="h-5 -mr-5 sm:mr-3 w-5" fill="currentColor" viewBox="0 0 12 20" aria-hidden="true">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.01.418a1.429 1.429 0 010 2.02L3.45 10l7.561 7.561a1.429 1.429 0 01-2.02 2.02l-8.572-8.57a1.429 1.429 0 010-2.021L8.99.418a1.429 1.429 0 012.02 0z"
            />
          </svg>
        )}
        {renderIcon?.("h-5 -mr-5 sm:mr-3 w-5")}
        <span className="flex-1 text-center">{label}</span>
      </button>
    );
  }
);
