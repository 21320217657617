import { useMutation, UseMutationOptions } from "react-query";
import { AxiosError } from "axios";
import client from "../../client";
import { ENDPOINTS } from "../../../constants";
import { IUpdatedFieldNumber } from "../types";

export function useUpdateFieldNumberMutation(options?: UseMutationOptions<any, AxiosError, IUpdatedFieldNumber>) {
  return useMutation(
    ["update-match-fieldnumber"],
    ({ id, fieldNumber, refereeId }) =>
      client.patch(ENDPOINTS.UPDATE_FIELD_NUMBER.replace("{matchId}", id), [{ fieldNumber, refereeId }]),
    options
  );
}
