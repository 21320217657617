import { useMutation, UseMutationOptions } from "react-query";
import { AxiosError } from "axios";
import client from "../../client";
import { ENDPOINTS } from "../../../constants";
import { IMatchScore } from "../types";

export function useUpdateMatchScoreMutation(options?: UseMutationOptions<any, AxiosError, IMatchScore>) {
  return useMutation(
    ["update-match-score"],
    (data) => client.patch(ENDPOINTS.UPDATE_MATCH.replace("{matchId}", data.id), [data]),
    options
  );
}
