import React, { forwardRef } from "react";
import classNames from "classnames";
import MaskedTextInput, { MaskedInputProps } from "react-text-mask";

type HTMLInputProps = React.ComponentPropsWithoutRef<"input">;

export interface IInlineTextInputProps extends MaskedInputProps {
  id: string;
  label: string;
  type: HTMLInputProps["type"];
  labelClassName?: string;
  labelFontBold?: boolean;
  inputClassName?: string;
  error?: string;
}

export const InlineTextInput = forwardRef<MaskedTextInput, IInlineTextInputProps>(
  ({ id, label, className, labelClassName, labelFontBold, inputClassName, error, ...rest }, ref) => {
    return (
      <div className="items-start sm:flex sm:space-x-3">
        <div className={classNames("flex-1 grid-cols-5 sm:grid sm:items-center", className)}>
          <label
            htmlFor={id}
            className={classNames(
              "block col-span-2 text-app-dark-primary text-gray-700 text-lg mb-3 tracking-tighter sm:mb-0",
              { "font-bold": labelFontBold },
              labelClassName
            )}
          >
            {label}
          </label>
          <div className="col-span-3 mt-1 sm:mt-0">
            <MaskedTextInput
              id={id}
              ref={ref}
              {...rest}
              className={classNames(
                "appearance-none bg-white block border border-app-dark-light focus:border-app-dark-primary focus:outline-none focus:ring-0 focus:ring-app-dark-primary placeholder-app-dark-light placeholder-gray-400 px-3 py-2 rounded sm:text-sm text-app-dark-primary text-base w-full",
                inputClassName
              )}
            />
          </div>
        </div>
        <div className="text-red-error text-sm tracking-tight sm:w-1/3 whitespace-nowrap mt-1 sm:mt-0">
          {error && <span className="hidden font-bold sm:block">Let op!</span>}
          <div className="whitespace-normal">{error}</div>
        </div>
      </div>
    );
  }
);
