import { useUpdateFieldNumberMutation, useUpdateMatchScoreMutation } from "data/api/usePools";
import React, { useEffect } from "react";
import { useQueryClient } from "react-query";
import { TableInstance } from "react-table";
import useStore from "store";
import { IMatch } from "../../../data/api/types";
import { EditableTableGroup } from "../components/editableTableGroup/index";

/* JOOST */
import { useReferees } from "../../../data/api/getReferees";

interface Referee {
  id: string;
  firstName: string;
  insertion: string;
  lastName: string;
}

interface ITeamsTableProps {
  tableInstance: TableInstance<IMatch>;
  loading: boolean;
  referees: any;
}

export const MatchesTable: React.VFC<ITeamsTableProps> = ({ tableInstance, loading, referees }) => {
  // console.log(referees);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;
  const queryClient = useQueryClient();

  const { tournamentId, addError } = useStore((s) => s);

  /* JOOST */
  const { data: refereesData, isLoading: refereesLoading } = useReferees(tournamentId || "", {
    enabled: !!tournamentId,
  });

  const { mutateAsync: updateMatchScore } = useUpdateMatchScoreMutation({
    onError: (err) => addError(err.response?.data.message),
    onSuccess: () => {
      queryClient.invalidateQueries(["matches"]);
    },
  });
  const { mutateAsync: updateFieldNumber } = useUpdateFieldNumberMutation({
    onError: (err) => addError(err.response?.data.message),
    onSuccess: () => {
      queryClient.invalidateQueries(["matches"]);
    },
  });

  useEffect(() => {}, [tableInstance]);

  // Natural sort comparator
  const naturalSort = (a: string, b: string) => {
    return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });
  };

  // Ensure refereesData is an array and transform it
  const allReferees =
    (Array.isArray(refereesData)
      ? refereesData.map((item: Referee) => ({
          id: `${item.firstName} ${
            item.insertion !== "-" ? item.insertion + " " : ""
          }${item.lastName}`,
          name: `${item.firstName} ${
            item.insertion !== "-" ? item.insertion + " " : ""
          }${item.lastName}`,
        }))
      : []
    ).sort((a, b) => naturalSort(a.name, b.name));

  const updateMatchScoreField = (matchId: string, value1: number | null, value2: number | null) => {
    if (tournamentId && matchId) {
      updateMatchScore({ tournamentId: tournamentId, id: matchId, team1Score: value1, team2Score: value2 });
    }
  };

  const updateFieldAndReferee = (matchId: string, refereeId: string, field: number | null) => {
    if (matchId && refereeId) {
      updateFieldNumber({ id: matchId, fieldNumber: field ? field - 1 : null, refereeId: refereeId });
    }
  };

  return (
    <div className="flex flex-col justify-start ">
      <div className="overflow-x-auto">
        <div className="py-2 align-middle inline-block min-w-full">
          <div className="overflow-y-auto sm:rounded-md" style={{ maxHeight: "70vh" }}>
            <table {...getTableProps()} className="min-w-full">
              <thead className="bg-neutral-200 sticky top-0 z-10">
                <tr>
                  {headerGroups.map((headerGroup) =>
                    headerGroup.headers.map((column) => (
                      <th
                        scope="col"
                        className="px-1 py-3 text-left font-semibold text-neutral-900 tracking-wider"
                        {...column.getHeaderProps()}
                      >
                        {column.render("Header")}
                        {/* @ts-ignore */}
                        <div>{column.canFilter ? column.render("Filter") : null}</div>
                      </th>
                    ))
                  )}
                  <th scope="col" className="px-1 py-3 text-left font-semibold text-neutral-900 tracking-wider ">
                    <span className="">Scheidsrechter</span>
                  </th>
                  <th scope="col" className="px-1 py-3 text-left font-semibold text-neutral-900 tracking-wider ">
                    <span className="">Veld</span>
                  </th>
                  <th style={{ display: "table-cell" }} scope="col" className="relative py-3 flex justify-end">
                    <span className="w-20 flex justify-start">
                      <span>Uitslag</span>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody {...getTableBodyProps()} className="bg-white">
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            className="px-1 py-6 whitespace-nowrap text-base text-neutral-900"
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                      <EditableTableGroup
                        key={row.original.id}
                        id={row.original.id}
                        refereeId={row.original.referee}
                        updateMatchScore={updateMatchScoreField}
                        updateFieldAndReferee={updateFieldAndReferee}
                        value1={row.original.team1Score}
                        value2={row.original.team2Score}
                        field={row.original.fieldNumber}
                      
                        /* JOOST */
                        // referees={referees}
                        referees ={allReferees}
                        
                        // disabled={row.original.poule.includes("Poule")}
                        disabled={false}
                      />
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {!rows.length && (
              <tr className="flex items-center justify-center py-3 capitalize">
                {loading ? "loading" : "Geen teams om weer te geven!"}
              </tr>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
