import React, { Fragment, ReactElement } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/solid";
import classNames from "classnames";

type TOption = Record<"id" | "name", string | number>;

interface SelectProps<T extends TOption> {
  options: T[];
  value: T;
  onSelect(opt: T): void;
  label?: string;
  disabled?: boolean;
  variant?: "selected_value" | null;
}

export const Select = <T extends TOption>({
  value,
  onSelect,
  options,
  label,
  disabled,
  variant,
}: SelectProps<T>): ReactElement => {
  return (
    <Listbox disabled={disabled} value={value} onChange={onSelect}>
      {({ open }) => (
        <>
          {label && <Listbox.Label className="block text-sm font-medium text-app-dark-soft">{label}</Listbox.Label>}
          <div
            className={classNames("relative", { "mt-1": !!label, "text-neutral-600": variant === "selected_value" })}
          >
            <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md pl-5 pr-10 py-3 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-app-purple-primary focus:border-app-purple-primary ">
              <span className={`block truncate capitalize ${disabled && "text-gray-300"} `}>{value.name}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <svg
                  className="h-4 w-4"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.57144 5.71431L7.70287 0.697172C7.74128 0.65707 7.7874 0.625156 7.83847 0.603354C7.88953 0.581552 7.94449 0.570312 8.00001 0.570312C8.05554 0.570312 8.11049 0.581552 8.16156 0.603354C8.21262 0.625156 8.25875 0.65707 8.29716 0.697172L13.4286 5.71431"
                    stroke="#272B30"
                    stroke-width="1.14286"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M2.57144 10.2856L7.70287 15.3028C7.74128 15.3429 7.7874 15.3748 7.83847 15.3966C7.88953 15.4184 7.94449 15.4296 8.00001 15.4296C8.05554 15.4296 8.11049 15.4184 8.16156 15.3966C8.21262 15.3748 8.25875 15.3429 8.29716 15.3028L13.4286 10.2856"
                    stroke="#272B30"
                    stroke-width="1.14286"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                static
                className="absolute z-10 mt-1 w-30 min-w-full bg-white shadow-lg max-h-40 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm  "
              >
                {options.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-purple-primary" : "text-neutral-900",
                        "cursor-default select-none relative py-2 pl-3 pr-9"
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate capitalize"
                          )}
                        >
                          {option.name}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-app-purple-primary",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-4 w-4" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};
