import { useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import useStore from "../store";
import { ROUTES } from "../constants/routes";

interface ILogoutOptions {
  replaceHistory?: boolean;
}
function useLogout({ replaceHistory = true }: ILogoutOptions = {}) {
  const history = useHistory();
  const location = useLocation<{ from: Window["location"] }>();
  const client = useQueryClient();
  const clearStore = useStore((state) => state.clearStore);

  return async () => {
    clearStore();
    client.clear();
    window.localStorage.clear();
    if (replaceHistory) {
      const { from } = location.state || { from: { pathname: ROUTES.LOGIN } };
      history.replace(from);
    }
    await new Promise((res) => setTimeout(res, 1000));
  };
}

export default useLogout;
