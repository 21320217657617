import React, { ReactElement } from "react";
import { IRadioButtonsProps, RadioButtons } from "../RadioButtons";
import { IRadioButtonProps } from "../RadioButtons/RadioButton";
import { ToggleButton } from "./ToggleButton";

export interface IToggleButtonsProps<T>
  extends Omit<IRadioButtonsProps<T>, "buttonsWrapperClassNameReplacement" | "renderButton" | "buttons"> {
  buttons: [IRadioButtonProps<T>, IRadioButtonProps<T>];
  secondary?: boolean;
}

export const ToggleButtons = <T extends unknown>({ secondary, ...props }: IToggleButtonsProps<T>): ReactElement => {
  return (
    <RadioButtons
      {...props}
      buttonsWrapperClassNameReplacement={`focus-within:ring-2 grid-cols-2 inline-grid relative ring-purple-primary ring-offset-2 rounded-lg w-32 z-0`}
      renderButton={(btnProps) => <ToggleButton secondary={secondary} {...btnProps} />}
    />
  );
};
