import { useMutation, UseMutationOptions } from "react-query";
import { AxiosError } from "axios";
import client from "../client";
import { ENDPOINTS, KNOCKOUT } from "../../constants";

export interface ITournamentDay {
  startDate: string;
  startTime: string;
  endTime: string;
}

export interface ITournamentConfigBase {
  name: string;
  street: string;
  postalCode: string;
  streetNumber: number;
  city: string;
  addition: string;
}

export interface ITournamentConfigShaping {
  days: ITournamentDay[];
  teamId: string;
  refreeId: string;
}

export interface ITournamentConfigFinal {
  teamsPerPouleWomen: number;
  teamsPerPouleMen: number;
  playingFields: number;
  matchDurationMinutes: number;
  swapTimeMinutesPoules: number;
  swapTimeMinutesKnockout: number;
  knockoutStartMen?: KNOCKOUT;
  knockoutStartWomen?: KNOCKOUT;
  extraPouleRound: boolean;
  breakTimeMinutes: number;
}

export type ICreateTournamentReqData = ITournamentConfigBase & ITournamentConfigShaping & ITournamentConfigFinal;

function useCreateTournamentMutation(options?: UseMutationOptions<any, AxiosError, ICreateTournamentReqData>) {
  return useMutation(
    "tournament/create",
    (data: ICreateTournamentReqData) => client.post(ENDPOINTS.TOURNAMENT_CREATE, data),
    options
  );
}

export default useCreateTournamentMutation;
