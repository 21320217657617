import React from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";
import useStore from "../../store";
import { ROUTES } from "../../constants/routes";

interface PrivateRouteProps extends Omit<RouteProps, "children" | "render"> {
  routeType: "private" | "dashboard";
}

const AppRoute: React.VFC<PrivateRouteProps> = ({ component: Component, routeType, ...rest }) => {
  const { authenticated, tournamentId } = useStore((state) => state);

  return (
    <Route {...rest}>
      {(routeProps) => {
        if (!authenticated) {
          return <Redirect to={{ pathname: ROUTES.LOGIN, state: { from: routeProps.location } }} />;
        }
        // if (routeType === "private" && tournamentId) {
        //   return <Redirect to={{ pathname: ROUTES.DASHBOARD, state: { from: routeProps.location } }} />;
        // }
        else if (routeType === "dashboard" && !tournamentId) {
          return <Redirect to={{ pathname: ROUTES.CREATE_TOURNAMENT, state: { from: routeProps.location } }} />;
        }
        // @ts-ignore
        return <Component {...routeProps} />;
      }}
    </Route>
  );
};

export default AppRoute;
