import React from "react";
import { ToggleButtons } from "../../../components/ToggleButtons";
import { TeamClass } from "../../../data/api/types";
import { Select } from "../../../components/Select";

export type TKnockOutOption = { id: string; name: string };

interface IKnockOutsPageControlsProps {
  toggleTeamClass(teamClass: TeamClass): void;
  teamClass: TeamClass;
  knockOutsOption: TKnockOutOption;
  knockOutOptions: TKnockOutOption[];
  setKnockOutsOption(option: TKnockOutOption): void;
}

export const KnockoutsPageControls: React.VFC<IKnockOutsPageControlsProps> = ({
  knockOutsOption,
  knockOutOptions,
  setKnockOutsOption,
  toggleTeamClass,
  teamClass,
}) => {
  return (
    <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between sm:space-y-0 space-y-3">
      <div className="flex flex-1 items-center space-x-3">
        <ToggleButtons<TeamClass>
          secondary
          onChange={toggleTeamClass}
          value={teamClass}
          buttons={[
            { value: TeamClass.MEN, label: "Heren" },
            { value: TeamClass.WOMEN, label: "Dames" },
          ]}
        />
        <Select<TKnockOutOption> options={knockOutOptions} value={knockOutsOption} onSelect={setKnockOutsOption} />
      </div>
    </div>
  );
};
