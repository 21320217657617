export function calculateAantalMatchesPouleFase({
  aantalPoules,
  teamsPerPoule,
}: {
  aantalPoules: number;
  teamsPerPoule: number;
}) {
  const aantalMatchesPerPool = 0.5 * teamsPerPoule * (teamsPerPoule - 1);
  return aantalMatchesPerPool * aantalPoules;
}
