import React, { forwardRef } from "react";

export const DeleteIcon = forwardRef<SVGSVGElement, React.ComponentPropsWithoutRef<"svg">>((props, ref) => {
  return (
    <svg
      {...props}
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      aria-hidden="true"
      viewBox="0 0 36 36"
    >
      <path d="M25.429 10.857h-4.286V9.786A1.786 1.786 0 0019.357 8h-3.571A1.786 1.786 0 0014 9.786v1.071H9.714a.714.714 0 000 1.429h.76l.847 13.612c.064 1.199.983 2.102 2.143 2.102h8.215c1.166 0 2.067-.883 2.142-2.098l.849-13.616h.759a.714.714 0 100-1.429zm-10.69 14.286h-.025a.714.714 0 01-.714-.689l-.357-10a.714.714 0 111.428-.05l.358 10a.716.716 0 01-.69.739zm3.547-.714a.714.714 0 11-1.429 0v-10a.714.714 0 111.429 0v10zm1.428-13.572H15.43V9.786a.351.351 0 01.357-.357h3.571a.353.353 0 01.357.357v1.071zm1.429 13.597a.714.714 0 01-.714.689h-.026a.716.716 0 01-.689-.74l.357-10a.717.717 0 01.74-.689.714.714 0 01.689.74l-.357 10z" />
    </svg>
  );
});
