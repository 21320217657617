import React, { forwardRef, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import classNames from "classnames";
import { TextInput } from "../../../components/Input/TextInput";
import { Button } from "../../../components/Button/Button";
import { RadioButtons } from "../../../components/RadioButtons";
import { CounterInput } from "../../../components/Input/CounterInput";
import { ToggleButtons } from "../../../components/ToggleButtons";
import { ActionButton } from "../../../components/Button/ActionButton";
import useStore, { IFormsMetaData } from "../../../store";
import { FormWrapper } from "../FormWrapper";
import { useAsyncCallback } from "react-async-hook";
import { omitBy, isNil } from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import useCreateTournamentMutation, {
  ICreateTournamentReqData,
  ITournamentConfigFinal,
} from "../../../data/api/useCreateTournamentMutation";
import { KNOCKOUT } from "../../../constants";
import { ROUTES } from "../../../constants/routes";
import { usePrefetchProfileQuery } from "../../../data/api/useProfileQuery";
import { usePrefetchTournamentSettingsQuery } from "../../../data/api/useTournamentSettingsQuery";
import { ITab } from "../../../components/Tabs/Tab";
import useLogout from "../../../hooks/useLogout";
import { calculateTotalTime } from "../utils/calculateTotalTime";
import { berekenAantalTijdsloten } from "../utils/berekenAantalTijdsslotenBeschikbaar";

type HTMLFormProps = React.ComponentPropsWithoutRef<"form">;
type TTab = Required<IFormsMetaData>["type"];

export interface IFormData extends ITournamentConfigFinal {}

export interface IFinalStepProps extends Omit<HTMLFormProps, "onSubmit"> {}

function calculateTotaleTijdPauze(variables: ICreateTournamentReqData) {
  if (!variables.breakTimeMinutes) {
    return 0;
  }

  if (!variables.days.length) {
    return 0;
  }

  if (!variables.playingFields) {
    return 0;
  }

  return variables.breakTimeMinutes * variables.playingFields * variables.days.length;
}

export const FinalStep = forwardRef<HTMLFormElement, IFinalStepProps>(({ ...rest }, ref) => {
  const history = useHistory();
  const location = useLocation<{ from: Window["location"] }>();
  const prefetchProfileQuery = usePrefetchProfileQuery();
  const prefetchTournamentSettingsQuery = usePrefetchTournamentSettingsQuery();
  const tournamentConfig = useStore((state) => state.tournamentConfig);
  const setUserTournamentId = useStore((state) => state.setTournamentId);
  const setTournamentForms = useStore((state) => state.setTournamentConfig);
  const setCurrentForm = useStore((state) => state.setCurrentTournamentConfigForm);
  const addError = useStore((state) => state.addError);
  const menCount = useStore((state) => state.mensCount);
  const womenCount = useStore((state) => state.womensCount);

  const formsMetaData = useStore((state) => state.tournamentConfig.formsMetaData);

  const logout = useLogout();
  const handleLogout = useAsyncCallback(logout);
  const activeLinkClass = "text-purple-primary font-bold";

  const { mutateAsync: createTournament } = useCreateTournamentMutation({
    onError: (err) => addError(err.response?.data.message),
  });
  const [hasBreakTimeMinutes, setHasBreakTimeMinutes] = useState(
    Boolean(tournamentConfig.final?.breakTimeMinutes || 0)
  );
  const {
    control,
    handleSubmit,
    register,
    getValues,
    setError,
    formState: { errors, isValid },
  } = useForm<IFormData>({
    defaultValues: {
      playingFields: 0,
      ...tournamentConfig?.final,
      teamsPerPouleWomen: 0,
      teamsPerPouleMen: 0,
      extraPouleRound: false,
    },
    mode: "all",
  });

  const handleOnToggleHasBreakTimeMinutes = (val: boolean) => {
    setHasBreakTimeMinutes(val);
  };

  const onSubmit = useAsyncCallback(async (data: IFormData) => {
    const onWindowClose = (e: BeforeUnloadEvent) => {
      const msg = "Het toernooi wordt nog gegenereerd. Wil je echt sluiten? Dit kan fouten opleveren in de generatie.";
      e = e || window.event;
      if (e) e.returnValue = msg;
      return msg;
    };
    window.addEventListener("beforeunload", onWindowClose);
    setTournamentForms({ final: data });
    // @ts-ignore
    let variables: ICreateTournamentReqData = {
      ...tournamentConfig.base!,
      ...tournamentConfig.shaping!,
      ...data,
      teamsPerPouleWomen: parseInt(String(data.teamsPerPouleWomen)),
      teamsPerPouleMen: parseInt(String(data.teamsPerPouleMen)),
    };
    switch (formsMetaData?.type) {
      case "Combinatie":
        break;
      case "Heren": {
        // @ts-expect-error
        variables.teamsPerPouleWomen = undefined;
        variables.knockoutStartWomen = undefined;
        break;
      }
      case "Dames": {
        // @ts-expect-error
        variables.teamsPerPouleMen = undefined;
        variables.knockoutStartMen = undefined;
        break;
      }
    }
    // @ts-expect-error
    variables = omitBy(variables, isNil);

    // console.log(variables);

    // function calculate_min_playing_fields_knockouts() {
    //   let fields_needed_men = 0;
    //   let fields_needed_women = 0;

    //   // Check on knockout fields men
    //   switch (variables.knockoutStartMen) {
    //     case KNOCKOUT.LAST_32:
    //       fields_needed_men = 16;
    //       break;
    //     case KNOCKOUT.LAST_16:
    //       fields_needed_men = 8;
    //       break;
    //     case KNOCKOUT.QUARTER_FINAL:
    //       fields_needed_men = 4;
    //       break;
    //     case KNOCKOUT.HALF_FINAL:
    //       fields_needed_men = 2;
    //       break;
    //     case KNOCKOUT.FINAL:
    //       fields_needed_men = 1;
    //       break;
    //     default:
    //       fields_needed_men = 0;
    //   }

    //   switch (variables.knockoutStartWomen) {
    //     case KNOCKOUT.LAST_32:
    //       fields_needed_women = 16;
    //       break;
    //     case KNOCKOUT.LAST_16:
    //       fields_needed_women = 8;
    //       break;
    //     case KNOCKOUT.QUARTER_FINAL:
    //       fields_needed_women = 4;
    //       break;
    //     case KNOCKOUT.HALF_FINAL:
    //       fields_needed_women = 2;
    //       break;
    //     case KNOCKOUT.FINAL:
    //       fields_needed_women = 1;
    //       break;
    //     default:
    //       fields_needed_women = 0;
    //       break;
    //   }

    //   return fields_needed_men + fields_needed_women;
    // }

    const totaleTijdVanDagen = calculateTotalTime(variables.days) * variables.playingFields;
    const totaleTijdVanPauze = calculateTotaleTijdPauze(variables);

    const teamsCountMen = menCount || 0;
    const teamsCountWomen = womenCount || 0;

    const aantalTijdslotenBeschikbaar = berekenAantalTijdsloten({
      variables,
      teamsCountMen,
      teamsCountWomen,
      totaleTijdVanDagen,
      totaleTijdVanPauze,
    });

    console.log(aantalTijdslotenBeschikbaar);

    const menPoules = teamsCountMen / variables.teamsPerPouleMen || 0;
    const womenPoules = teamsCountWomen / variables.teamsPerPouleWomen || 0;
    const aantal_poules = menPoules + womenPoules;
    const minimum_playing_fields_needed_for_knockouts = aantal_poules;

   if (aantal_poules > variables.playingFields) {
      setError("teamsPerPouleMen", {
        type: "server side",
        message: `Aantal poules mag niet groter zijn dan het aantal velden.`,
      });
      setError("teamsPerPouleWomen", {
        type: "server side",
        message: `Aantal poules mag niet groter zijn dan het aantal velden.`,
      });
    } else if (aantalTijdslotenBeschikbaar > 0) {
      if (teamsCountMen && teamsCountMen % variables.teamsPerPouleMen !== 0) {
        setError("teamsPerPouleMen", {
          type: "server side",
          message: `Je hebt ${teamsCountMen} teams, dus verhoog of verlaag het aantal teams per poule zodat elke poule even groot is!`,
        });
      } else if (teamsCountWomen && teamsCountWomen % variables.teamsPerPouleWomen !== 0) {
        setError("teamsPerPouleWomen", {
          type: "server side",
          message: `Je hebt ${teamsCountWomen} teams, dus verhoog of verlaag het aantal teams per poule zodat elke poule even groot is!`,
        });
      } else {
        try {
          const { id: tournamentId } = await createTournament(variables);
          setUserTournamentId(tournamentId);
          await prefetchProfileQuery();
          await prefetchTournamentSettingsQuery(tournamentId);
          const { from } = location.state || { from: { pathname: ROUTES.DASHBOARD } };
          history.replace(from);
        } catch (e) {
          setNextAttemptDisabled(true);
          setTimeout(() => {
            setNextAttemptDisabled(false);
          }, 10000);
        }
      }

      window.removeEventListener("beforeunload", onWindowClose);
    } else {
      setError("playingFields", {
        type: "server side",
        message:
          "Er zijn niet voldoende timeslots beschikbaar. Verhoog het aantal velden, of verlaag het aantal teams per poule.",
      });
    }
  });

  const onBack = () => {
    const data = getValues();
    setTournamentForms({
      final: {
        ...data,
        teamsPerPouleWomen: parseInt(String(data.teamsPerPouleWomen)),
        teamsPerPouleMen: parseInt(String(data.teamsPerPouleMen)),
      },
    });
    setCurrentForm("shaping");
  };
  const type = formsMetaData?.type;
  const tabs = useMemo(() => {
    const res: ITab<TTab>[] = [];
    const type = formsMetaData?.type;
    if (type === "Heren" || type === "Combinatie") {
      res.push({ label: "Heren", value: "Heren" });
    }
    if (type === "Dames" || type === "Combinatie") {
      res.push({ label: "Dames", value: "Dames" });
    }
    return res;
  }, [formsMetaData]);
  const [currentTap, setCurrentTap] = useState<TTab>(tabs[0]?.value);
  const [nextAttemptDisabled, setNextAttemptDisabled] = useState(false);

  return (
    <div className="col-lg-12">
      <div className="flex flex-column justify-center h-full">
        <FormWrapper formTitle="We zijn bijna klaar met aanmaken" formStep="3">
          <form autoComplete="off" ref={ref} {...rest} onSubmit={handleSubmit(onSubmit.execute)} className="space-y-6">
            <div className="row">
              <div className="col-xl-3 col-lg-7 mb-8">
                <Controller
                  name="playingFields"
                  control={control}
                  rules={{
                    required: "Dit veld is vereist",
                    min: { value: 1, message: "min value is 0" },
                    max: { value: 99, message: "max value is 99" },
                  }}
                  render={({ field }) => (
                    <CounterInput
                      {...field}
                      label="Aantal velden"
                      id="playing-fields"
                      error={errors.playingFields?.message}
                    />
                  )}
                />
                <TextInput
                  type="number"
                  id="match-duration-minutes"
                  placeholder="In minuten"
                  label="Wedstrijd duratie"
                  className="w-full"
                  error={errors.matchDurationMinutes?.message}
                  {...register("matchDurationMinutes", { required: "Dit veld is vereist", valueAsNumber: true })}
                />
                <TextInput
                  type="number"
                  id="swap-time-minutes"
                  placeholder="In minuten"
                  label="Veldwissel duratie poule"
                  className="w-full"
                  error={errors.swapTimeMinutesPoules?.message}
                  {...register("swapTimeMinutesPoules", { required: "Dit veld is vereist", valueAsNumber: true })}
                />
                <TextInput
                  type="number"
                  id="swap-time-minutes"
                  placeholder="In minuten"
                  label="Veldwissel duratie knockout"
                  className="w-full"
                  error={errors.swapTimeMinutesKnockout?.message}
                  {...register("swapTimeMinutesKnockout", { required: "Dit veld is vereist", valueAsNumber: true })}
                />
                <ToggleButtons
                  onChange={handleOnToggleHasBreakTimeMinutes}
                  value={hasBreakTimeMinutes}
                  buttons={[
                    { value: true, label: "Ja" },
                    { value: false, label: "Nee" },
                  ]}
                  label="Pauze"
                />
                {hasBreakTimeMinutes && (
                  <TextInput
                    type="number"
                    id="break-duration-minutes"
                    placeholder="In minuten"
                    label="Pauze duratie"
                    className="w-full"
                    error={errors.breakTimeMinutes?.message}
                    {...register("breakTimeMinutes", { required: "Dit veld is vereist", valueAsNumber: true })}
                  />
                )}
              </div>

              {(type === "Heren" || type === "Combinatie") && (
                <div className="col-xl-4 offset-xl-1 col-md-6  mb-8">
                  <div className="kaart">
                    <h3 className="font-semibold size-h3 pb-3 mb-8 border-b border-neutral-300">Heren</h3>
                    <Controller
                      name="teamsPerPouleMen"
                      control={control}
                      rules={{
                        required: "Dit veld is vereist",
                        min: { value: 1, message: "min value is 0" },
                        max: { value: 99, message: "max value is 99" },
                      }}
                      render={({ field: { onChange }, field }) => (
                        <CounterInput
                          {...field}
                          label="Teams per poule"
                          id="teams-per-pole"
                          error={errors.teamsPerPouleMen?.message}
                          onChange={(e) => {
                            onChange(e);
                          }}
                        />
                      )}
                    />
                    <Controller
                      name="knockoutStartMen"
                      control={control}
                      rules={{ required: "Dit veld is vereist" }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <RadioButtons
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          error={errors.knockoutStartMen?.message}
                          label="Kies startpunt knock-out fase*"
                          buttonsWrapperClassNameReplacement="mt-1 inline-grid grid-cols-2 gap-3 w-full"
                          buttons={[
                            { value: KNOCKOUT.LAST_32, label: "Laatste 32" },
                            { value: KNOCKOUT.LAST_16, label: "Laatste 16" },
                            { value: KNOCKOUT.QUARTER_FINAL, label: "Kwartfinale" },
                            { value: KNOCKOUT.HALF_FINAL, label: "Halve finale" },
                            { value: KNOCKOUT.FINAL, label: "Alleen finale" },
                          ]}
                        />
                      )}
                    />
                    <div className="text-xs text-neutral-700">
                      * Bij een oneven aantal wordt er ook nog gestreden om <strong>de beste 3de</strong>
                    </div>
                  </div>
                </div>
              )}

              {(type === "Dames" || type === "Combinatie") && (
                <div className="col-xl-4 col-md-6 mb-8">
                  <div className="kaart">
                    <h3 className="font-semibold size-h3 pb-3 mb-8 border-b border-neutral-300">Dames</h3>
                    <Controller
                      name="teamsPerPouleWomen"
                      control={control}
                      rules={{
                        required: "Dit veld is vereist",
                        min: { value: 1, message: "min value is 0" },
                        max: { value: 99, message: "max value is 99" },
                      }}
                      render={({ field }) => (
                        <CounterInput
                          {...field}
                          label="Teams per poule"
                          id="teams-per-pole"
                          error={errors.teamsPerPouleWomen?.message}
                        />
                      )}
                    />
                    <Controller
                      name="knockoutStartWomen"
                      control={control}
                      rules={{ required: "Dit veld is vereist" }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <RadioButtons
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          error={errors.knockoutStartWomen?.message}
                          label="Kies startpunt knock-out fase*"
                          buttonsWrapperClassNameReplacement="mt-1 inline-grid grid-cols-2 gap-3 w-full"
                          buttons={[
                            { value: KNOCKOUT.LAST_32, label: "Laatste 32" },
                            { value: KNOCKOUT.LAST_16, label: "Laatste 16" },
                            { value: KNOCKOUT.QUARTER_FINAL, label: "Kwartfinale" },
                            { value: KNOCKOUT.HALF_FINAL, label: "Halve finale" },
                            { value: KNOCKOUT.FINAL, label: "Alleen finale" },
                          ]}
                        />
                      )}
                    />
                    <div className="text-xs text-neutral-700">
                      * Bij een oneven aantal wordt er ook nog gestreden om <strong>de beste 3de</strong>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="fixed bottom-0 left-0 w-full bg-neutral-100 py-5 mt-0">
              <div className="container">
                <div className="row">
                  <div className="col-4">
                    <div className="flex h-full items-center">
                      <button
                        className={classNames(
                          activeLinkClass,
                          "inline-flex items-center focus:outline-none font-bold text-base text-purple-primary uppercase"
                        )}
                        onClick={handleLogout.execute}
                      >
                        Uitloggen
                        <svg
                          className="h-4 w-4 ml-2"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1.71429 1.14286C1.56273 1.14286 1.41739 1.20306 1.31022 1.31022C1.20306 1.41739 1.14286 1.56273 1.14286 1.71429V14.2857C1.14286 14.4373 1.20306 14.5826 1.31022 14.6898C1.41739 14.7969 1.56273 14.8571 1.71429 14.8571H9.71428C9.86584 14.8571 10.0112 14.7969 10.1183 14.6898C10.2255 14.5826 10.2857 14.4373 10.2857 14.2857V12C10.2857 11.6844 10.5416 11.4286 10.8571 11.4286C11.1727 11.4286 11.4286 11.6844 11.4286 12V14.2857C11.4286 14.7404 11.248 15.1764 10.9265 15.4979C10.605 15.8194 10.1689 16 9.71428 16H1.71429C1.25963 16 0.823593 15.8194 0.502103 15.4979C0.180612 15.1764 0 14.7404 0 14.2857V1.71429C0 1.25963 0.180612 0.823593 0.502103 0.502103C0.823593 0.180612 1.25963 0 1.71429 0H9.71428C10.1689 0 10.605 0.180612 10.9265 0.502103C11.248 0.823593 11.4286 1.25963 11.4286 1.71429V4C11.4286 4.31559 11.1727 4.57143 10.8571 4.57143C10.5416 4.57143 10.2857 4.31559 10.2857 4V1.71429C10.2857 1.56273 10.2255 1.41739 10.1183 1.31022C10.0112 1.20306 9.86584 1.14286 9.71428 1.14286H1.71429Z"
                            fill="#653EF2"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6.85714 8C6.85714 7.68441 7.11298 7.42857 7.42857 7.42857H15.4286C15.7442 7.42857 16 7.68441 16 8C16 8.31559 15.7442 8.57143 15.4286 8.57143H7.42857C7.11298 8.57143 6.85714 8.31559 6.85714 8Z"
                            fill="#653EF2"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12.7388 5.31022C12.962 5.08707 13.3238 5.08707 13.5469 5.31022L15.8326 7.59594C16.0558 7.8191 16.0558 8.1809 15.8326 8.40406L13.5469 10.6898C13.3238 10.9129 12.962 10.9129 12.7388 10.6898C12.5156 10.4666 12.5156 10.1048 12.7388 9.88165L14.6204 8L12.7388 6.11835C12.5156 5.89519 12.5156 5.53338 12.7388 5.31022Z"
                            fill="#653EF2"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="col-8 flex justify-end">
                    <ActionButton type="button" label="Vorige" onClick={onBack} className="uppercase" />
                    {nextAttemptDisabled ? (
                      <Button
                        type="submit"
                        label="Probeer het opnieuw in tien seconden"
                        loading={onSubmit.loading}
                        disabled={true}
                      />
                    ) : (
                      <Button type="submit" label="Toernooi aanmaken" loading={onSubmit.loading} disabled={!isValid} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </FormWrapper>
      </div>
    </div>
  );
});
