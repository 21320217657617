import axios from "axios";
import { MS_TIME, ENDPOINTS } from "../../constants";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: MS_TIME.HALF_HOUR,
  headers: { "Content-Type": "application/json" },
});

instance.interceptors.request.use(
  function (config) {
    config.headers["Authorization"] = `Bearer ${localStorage.getItem("accessToken")}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    if (response.config.url?.match(/\/auth\/(refresh|login)/)) {
      const { accessToken, refreshToken } = response.data;
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
    }
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    const isAuthError = !!error.response?.status.toString().match(/^40[1-9]$/);
    const shouldRetried = !originalRequest._retry;
    const isRefreshTokenReq = originalRequest.url.match(ENDPOINTS.AUTH_REFRESH);

    if (isAuthError && isRefreshTokenReq) {
      return Promise.reject(error);
    }

    if (isAuthError && shouldRetried && !isRefreshTokenReq) {
      originalRequest._retry = true;
      const tokens = {
        accessToken: localStorage.getItem("accessToken"),
        refreshToken: localStorage.getItem("refreshToken"),
      };
      await instance.post(ENDPOINTS.AUTH_REFRESH, tokens).catch();
      return instance(originalRequest);
    }

    return Promise.reject(error);
  }
);

export default instance;
