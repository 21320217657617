import { useState } from "react";
import { useAsyncCallback } from "react-async-hook";
import { UseFormReturn } from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types/fields";
import useStore from "store";
import useUploadRefereeMutation from "../data/api/useUploadRefereeMutation";
import useUploadTeamMutation from "../data/api/useUploadTeamMutation";

interface ILocalFileNames {
  refreeId?: string;
  teamId?: string;
}

export function useUploadTournamentFiles<TFieldValues extends FieldValues = FieldValues>(
  formInstance: UseFormReturn<TFieldValues>
) {
  const [localFileNames, setLocalFileNames] = useState<ILocalFileNames>({});
  const { mutateAsync: uploadReferee } = useUploadRefereeMutation();
  const { mutateAsync: uploadTeam } = useUploadTeamMutation();

  const handleOnFileUpload = useAsyncCallback(async (files: FileList, name: string) => {
    const file = files[0];
    const formData = new FormData();
    formData.append("file", file);

    let womenTeamCount = 0;
    let menTeamCount = 0;

    try {
      if (name === "refreeId") {
        const {
          data: { parseId },
        } = await uploadReferee(formData);
        // @ts-expect-error
        formInstance.setValue(name, parseId);
        setLocalFileNames((prev) => ({ ...prev, [name]: file.name }));
        // @ts-expect-error
        formInstance.clearErrors(name);
      }
      if (name === "teamId") {
        const {
          data: { parseId, women, men },
        } = await uploadTeam(formData);
        womenTeamCount = women ? women : 0;
        menTeamCount = men ? men : 0;

        // @ts-expect-error
        formInstance.setValue(name, parseId);
        setLocalFileNames((prev) => ({ ...prev, [name]: file.name }));
        // @ts-expect-error
        formInstance.clearErrors(name);
      }
    } catch (e) {
      if (name === "refreeId" || name === "teamId") {
        // @ts-expect-error
        formInstance.setError(name, {
          message: "Upload het bestand opnieuw",
          type: "serverError",
        });
      }
    }

    return { womenTeamCount, menTeamCount };
  });

  const handleDeleteFile = (name: string) => {
    if (name === "teamId" || name === "refreeId") {
      // @ts-expect-error
      formInstance.reset({ ...formInstance.getValues(), [name]: "" }, { keepErrors: true });
    }
  };

  return { localFileNames, handleOnFileUpload, handleDeleteFile };
}
