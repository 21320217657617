import { useRowSelect, useSortBy, useTable } from "react-table";
import moment from "moment";
import { IMatch } from "../../../data/api/types";
import { useCustomRowSelect } from "../plugins/useCustomRowSelect";
import { memo, useEffect } from "react";
import startTimeSorter from "../utils/sorters/startTime";

const columns = [
  {
    id: "startTime",
    Header: "Tijd",
    accessor: (d: IMatch) =>
      `${moment.parseZone(d.startTime).format("HH:mm")} - ${moment.parseZone(d.endTime).format("HH:mm")}`,
    sortType: startTimeSorter,
  },
  {
    id: "poule",
    Header: "Poule",
    accessor: (d: IMatch) => (d.poule.includes("Poule") ? d.poule : "-"),
  },
  {
    Header: "Knock-out",
    accessor: (d: IMatch) => (!d.poule.includes("Poule") ? d.poule : "-"),
  },
  {
    Header: "Team 1",
    accessor: (d: IMatch) => d.team1 || "-",
  },
  {
    Header: "Team 2",
    accessor: (d: IMatch) => d.team2 || "-",
  },
];

export function useMatchesTable(data: IMatch[] = []) {
  const instance = useTable(
    {
      columns,
      data,

      initialState: {
        // @ts-expect-error
        sortBy: [
          {
            id: "startTime",
            desc: false,
          },
          {
            id: "poule",
            desc: false,
          },
        ],
      },
      autoResetSortBy: false,
      autoResetSelectedRows: false,
      autoResetHiddenColumns: true,
    },
    useSortBy,
    useRowSelect,
    useCustomRowSelect
  );

  useEffect(() => {
    // @ts-ignore
    instance.toggleAllRowsSelected(false);
  }, [instance]);

  return instance;
}
