import create from "zustand";
import { persist } from "zustand/middleware";
import { DeepPartial } from "utility-types";
import { v4 as uuidv4 } from "uuid";
import {
  ITournamentConfigBase,
  ITournamentConfigFinal,
  ITournamentConfigShaping,
} from "../data/api/useCreateTournamentMutation";

export interface IError {
  id: string;
  message: string;
  show: boolean;
}

export interface IFormsMetaData {
  daysCount?: number;
  type?: "Heren" | "Dames" | "Combinatie";
}

interface ITournamentFormData {
  base: ITournamentConfigBase;
  shaping: ITournamentConfigShaping;
  final: ITournamentConfigFinal;
  formsMetaData: IFormsMetaData;
}

export type TTournamentFormSteps = keyof Omit<ITournamentFormData, "formsMetaData">;

interface IActions {
  login(): void;
  logout(): void;
  setTournamentId(id: string): void;
  removeTournamentId(): void;
  setTournamentConfig(data: DeepPartial<ITournamentFormData>): void;
  clearTournamentConfig(): void;
  setCurrentTournamentConfigForm(name: TTournamentFormSteps): void;
  addError(err: string): void;
  closeError(id: string): void;
  removeError(id: string): void;
  clearErrors(): void;
  clearStore(): void;
}
interface IState {
  authenticated: boolean;
  tournamentId: string | null;
  tournamentConfig: DeepPartial<ITournamentFormData>;
  currentTournamentConfigForm: keyof Omit<ITournamentFormData, "formsMetaData">;
  prevTournamentConfigForm: keyof Omit<ITournamentFormData, "formsMetaData"> | null;
  errors: IError[];
}

interface ITeamsState {
  mensCount?: number;
  womensCount?: number;
  setMensCount(count: number): void;
  setWomensCount(count: number): void;
}

type TStore = IState & IActions & ITeamsState;

const useStore = create(
  persist<TStore>(
    (set) => ({
      authenticated: false,
      tournamentId: null,
      tournamentConfig: {},
      prevTournamentConfigForm: null,
      currentTournamentConfigForm: "base",
      errors: [],
      mensCount: 0,
      womensCount: 0,
      login: () => set({ authenticated: true }),
      logout: () => set({ authenticated: false, tournamentId: null }),
      setTournamentId: (tournamentId) => set({ tournamentId }),
      removeTournamentId: () => set({ tournamentId: null }),
      setTournamentConfig: (data) => set((prev) => ({ tournamentConfig: { ...prev.tournamentConfig, ...data } })),
      clearTournamentConfig: () => set({ tournamentConfig: {} }),
      setMensCount: (count) => set({ mensCount: count }),
      setWomensCount: (count) => set({ womensCount: count }),
      setCurrentTournamentConfigForm: (step) =>
        set((prev) => ({
          currentTournamentConfigForm: step,
          prevTournamentConfigForm: prev.currentTournamentConfigForm,
        })),
      addError: (err) => set((prev) => ({ errors: [...prev.errors, { id: uuidv4(), message: err, show: true }] })),
      removeError: (id) =>
        set((prev) => ({
          errors: prev.errors.filter((e) => {
            if (e.id !== id) return e;
          }),
        })),
      closeError(id: string) {
        set((prev) => ({
          errors: prev.errors.map((e) => {
            if (e.id === id) {
              return { ...e, show: false };
            }
            return e;
          }),
        }));
      },
      clearErrors: () => {
        set({
          errors: [],
        });
      },
      clearStore: () =>
        set({
          authenticated: false,
          tournamentId: null,
          tournamentConfig: {},
          prevTournamentConfigForm: null,
          currentTournamentConfigForm: "base",
          errors: [],
        }),
    }),
    {
      name: "app-state",
      getStorage: () => localStorage,
    }
  )
);

export default useStore;
