import client from "../../client";
import { UseMutationOptions, useMutation } from "react-query";
import { AxiosError } from "axios";
import { ENDPOINTS, MS_TIME } from "../../../constants";
import { TeamClass } from "../types";

const QUERY_KEY = "create-team";

export interface ICreateTeamFormValues {
  tournamentId: string;
  name: string;
  email: string;
  phoneNumber: string;
  street: string;
  streetNumber: string;
  city: string;
  addition: string;
  postalCode: string;
  teamClass: TeamClass;
}

export function useCreateTeamMutation(
  options?: UseMutationOptions<any, AxiosError, { teamClass: TeamClass; data: ICreateTeamFormValues }>
) {
  return useMutation(
    [QUERY_KEY],
    async ({ teamClass, data }) =>
      client.post(ENDPOINTS.CREATE_TEAM.replace("{teamClass}", teamClass), data, {
        timeout: MS_TIME.HALF_HOUR,
      }),
    options
  );
}
