import React, { ReactElement } from "react";
import classNames from "classnames";

export interface ITab<T> {
  value: T;
  label: string;
  error?: string;
}

export interface ITabProps<T> extends ITab<T> {
  onChange(value: T): void;
  current?: boolean;
  tabsLength: number;
}

export const Tab = <T extends string | number>({
  value,
  label,
  onChange,
  current,
  tabsLength,
  error,
}: ITabProps<T>): ReactElement => {
  const handleOnClick = () => onChange(value);
  return (
    <div
      className={classNames(
        "px-1 text-center border-b-2 text-base cursor-pointer",
        `w-1/${tabsLength}`,
        error ? "pt-4 pb-2" : "py-4",
        {
          "border-app-dark-primary text-app-dark-primary font-bold": current && !error,
          "border-transparent hover:text-app-dark-soft font-normal": !current && !error,
          "border-red-error text-red-error font-bold": current && error,
          "border-transparent text-red-error hover:text-app-red-soft font-normal": !current && error,
        }
      )}
      aria-current={current ? "page" : undefined}
      onClick={handleOnClick}
    >
      {label}
      {!current && error && <em className="block text-xs">{error}</em>}
    </div>
  );
};
