import React from "react";
import { Dialog } from "@headlessui/react";
import { Button } from "../../../components/Button/Button";
import { ActionButton } from "../../../components/Button/ActionButton";

interface IDeleteDialogProps {
  close(): void;
  confirm(): void;
  loading: boolean;
}

export const DeleteDialog: React.VFC<IDeleteDialogProps> = ({ close, confirm, loading }) => {
  return (
    <>
      <div>
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title as="h3" className="text-2xl font-bold text-app-dark-primary">
            Weet je zeker dat je dit team wil verwijderen?
          </Dialog.Title>
        </div>
      </div>
      <div className="mt-5 sm:mt-6 flex flex-col items-center justify-center space-y-3">
        <Button dangerous loading={loading} type="button" label="Verwijder team" onClick={confirm} />
        <ActionButton noIcon type="button" label="Annuleren" onClick={close} />
      </div>
    </>
  );
};
