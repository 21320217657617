import React from "react";

type HTMLInputProps = React.ComponentPropsWithoutRef<"input">;

export interface ISearchInput extends HTMLInputProps {
  id: string;
  label: string;
}

export const SearchInput: React.VFC<ISearchInput> = ({ id, label, className, ...rest }) => {
  return (
    <div className={className}>
      <label htmlFor={id} className="sr-only">
        search teams
      </label>
      <div className="relative rounded-md shadow-sm">
        <input
          id={id}
          {...rest}
          type="text"
          className="fappearance-none bg-white block border focus:outline-none focus:ring-0 placeholder-app-dark-light placeholder-gray-400 px-3 py-2 rounded sm:text-sm text-base w-full border-app-dark-light focus:border-app-dark-primary focus:ring-app-dark-primary text-app-dark-primary"
        />
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <svg
            className="h-5 w-5 text-gray-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.322 0a8.322 8.322 0 105.315 14.725l5.05 5.05a.77.77 0 001.088-1.088l-5.05-5.05a8.322 8.322 0 001.919-5.315A8.323 8.323 0 008.321 0v.77V0zm4.796 3.525a6.783 6.783 0 00-4.796-1.987l6.783 6.784a6.783 6.783 0 00-1.987-4.797zm-8.565-.843a6.783 6.783 0 013.769-1.144l6.783 6.784a6.783 6.783 0 11-10.552-5.64z"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};
