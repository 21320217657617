import React, { useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { Button } from "components/Button/Button";
import { ActionButton } from "components/Button/ActionButton";
import { TextInput } from "components/Input/TextInput";
import { useForm } from "react-hook-form";
import { IUpdateTeamFormValues } from "../../../data/api/useTeams";

interface IEditDialogProps {
  data?: IUpdateTeamFormValues;
  close(): void;
  confirm(value: IUpdateTeamFormValues): void;
  loading: boolean;
}

export const EditDialog: React.VFC<IEditDialogProps> = ({ data, close, confirm, loading }) => {
  type FormValue = IUpdateTeamFormValues;
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<FormValue>();

  useEffect(() => {
    reset(data);
  }, [data, reset]);

  return (
    <>
      <div>
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title as="h3" className="text-2xl font-bold text-app-dark-primary">
            Weet je zeker dat je dit team wil updaten?
          </Dialog.Title>
        </div>
        <div className="mt-3 sm:mt-5">
          <form onSubmit={handleSubmit(confirm)} className={"space-y-4"}>
            <TextInput
              id="name"
              type="text"
              label="Naam"
              autoComplete="organization"
              labelClassName="sm:sr-only font-normal sm:font-bold"
              placeholder="Naam"
              error={errors.name?.message}
              {...register("name", { required: "Dit veld is vereist" })}
            />
            <div className="gap-x-3 gap-y-4 grid grid-cols-1 sm:grid-cols-6">
              <TextInput
                type="text"
                id="street_address"
                autoComplete="street-address"
                label="Adres"
                placeholder="Adres"
                labelFontBold={false}
                labelClassName="sm:sr-only font-normal sm:font-bold"
                className="sm:col-span-6"
                error={errors.street?.message}
                {...register("street", { required: "Dit veld is vereist" })}
              />
              <TextInput
                type="text"
                id="street_number"
                autoComplete="street-number"
                label="Straatnaam"
                placeholder="Straatnaam"
                labelFontBold={false}
                labelClassName="sm:sr-only font-normal sm:font-bold"
                className="sm:col-span-6"
                error={errors.street?.message}
                {...register("streetNumber", { required: "Dit veld is vereist" })}
              />
              <TextInput
                type="text"
                id="zip"
                autoComplete="postal-code"
                label="Postcode"
                placeholder="Postcode"
                labelFontBold={false}
                labelClassName="sm:sr-only font-normal sm:font-bold"
                className="sm:col-span-3"
                error={errors.postalCode?.message}
                {...register("postalCode", { required: "Dit veld is vereist" })}
              />
              <TextInput
                type="text"
                id="addition"
                label="Toevoeging"
                placeholder="Toevoeging"
                labelFontBold={false}
                labelClassName="sm:sr-only font-normal sm:font-bold"
                className="sm:col-span-3"
                {...register("addition", { required: false })}
              />
              <TextInput
                type="text"
                id="city"
                label="Plaatsnaam"
                placeholder="Plaatsnaam"
                labelFontBold={false}
                labelClassName="sm:sr-only font-normal sm:font-bold"
                className="sm:col-span-6"
                error={errors.city?.message}
                {...register("city", { required: "Dit veld is vereist" })}
              />
            </div>
            <div className="gap-x-3 gap-y-4 grid grid-cols-1 sm:grid-cols-2">
              <TextInput
                type="text"
                id="email"
                autoComplete="email"
                label="E-mailadres"
                placeholder="E-mailadres"
                labelFontBold={false}
                labelClassName="sm:sr-only font-normal sm:font-bold"
                className="sm:col-span-1"
                error={errors.email?.message}
                {...register("email", { required: "Dit veld is vereist" })}
              />
              <TextInput
                type="text"
                id="phoneNumber"
                autoComplete="phone-number"
                label="Telefoonnummer"
                placeholder="Telefoonnummer"
                labelFontBold={false}
                labelClassName="sm:sr-only font-normal sm:font-bold"
                className="sm:col-span-1"
                error={errors.phoneNumber?.message}
                {...register("phoneNumber", { required: "Dit veld is vereist" })}
              />
            </div>
          </form>
        </div>
      </div>
      <div className="mt-5 sm:mt-6 flex flex-col items-center justify-center space-y-2">
        <Button loading={loading} type="button" label="Team updaten" onClick={handleSubmit(confirm)} />
        <ActionButton noIcon type="button" label="Annuleren" onClick={close} />
      </div>
    </>
  );
};
