import React, { useState } from "react";
import { RouteChildrenProps } from "react-router";
import { useForm } from "react-hook-form";
import useSignupLazyQuery, { ILoginReqData } from "../../data/api/useSignupLazyQuery";
import { useAsyncCallback } from "react-async-hook";
import { Button } from "../../components/Button/Button";
import { TextInput } from "components/Input/TextInput";
import useStore from "../../store";
import { SuccessNotification } from "./SuccessNotification";

const CreateUserPage: React.VFC<RouteChildrenProps<{}, { from: Window["location"] }>> = () => {
  const [showSuccessNotification, setShowSuccessNotification] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ILoginReqData>({ mode: "all" });
  const addError = useStore((state) => state.addError);
  const { mutateAsync } = useSignupLazyQuery({
    onError: (err) => addError(err.response?.data.message),
    onSuccess: () => setShowSuccessNotification(true),
  });

  const onSubmit = useAsyncCallback(async (data: ILoginReqData) => {
    await mutateAsync(data);
  });

  return (
    <div>
      <div className="flex  min-h-screen sm:px-20 sm:py-10">
        <div className="bg-white flex flex-col sm:overflow-hidden sm:rounded-lg sm:shadow-soft w-full">
          <div className="flex h-full">
            <div className="relative flex flex-1 flex-col justify-center lg:flex-none lg:px-20 px-4 py-12 sm:px-6 xl:px-24 xl:w-1/2">
              <div className="lg:w-104 max-w-md md:max-w-lg mx-auto w-full">
                <div>
                  <h2 className="font-bold mt-6 text-app-4xl text-app-dark-primary">Gebruiker Aanmaken</h2>
                  {/*<p className="font-normal max-w mt-4 text-app-dark-primary text-base">*/}
                  {/*  Om zeker te weten dat jij het bent willen we je vragen in te loggen met jouw gegevens. Dit doen we*/}
                  {/*  om te voorkomen dat anderen het toernooi kunnen wijzigen.*/}
                  {/*</p>*/}
                </div>
                <div>
                  <div className="mt-6">
                    <form onSubmit={handleSubmit(onSubmit.execute)} className="space-y-6">
                      <TextInput
                        id="email"
                        type="email"
                        label="E-mailadres"
                        autoComplete="email"
                        placeholder="E-mailadres"
                        error={errors.username?.message}
                        {...register("username", { required: "Dit veld is vereist" })}
                      />
                      <TextInput
                        id="password"
                        type="password"
                        label="Wachtwoord"
                        placeholder="Wachtwoord"
                        autoComplete="current-password"
                        className="space-y-1"
                        error={errors.password?.message}
                        {...register("password", { required: "Dit veld is vereist" })}
                      />
                      <div>
                        <Button
                          type="submit"
                          label={onSubmit.loading ? "Aanmelden" : "Inschrijven"}
                          loading={onSubmit.loading}
                          disabled={!isValid}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden lg:block relative w-0 flex-1 relative">
              <div className="app-background-gradient absolute inset-0 z-10 opacity-60" />
              <img
                className="absolute inset-0 h-full w-full object-cover"
                src="/images/visual-bg.png"
                alt="login form cover"
              />
            </div>
          </div>
        </div>
      </div>
      <SuccessNotification show={showSuccessNotification} onClose={() => setShowSuccessNotification(false)} />
    </div>
  );
};

export default CreateUserPage;
