import { Select } from "components/Select";
import { IPool, IPoolTeam } from "data/api/types";
import { map, sortBy, uniqBy } from "lodash";
import { useEffect, useState } from "react";

interface IPoolOptions {
  id: string;
  name: string;
  data: IPool;
}

export function SwapTeamsModal({
  poules,
  onSave,
  onClose,
}: {
  poules: IPool[];
  onSave: (teamIdA: string, teamIdB: string) => void;
  onClose: () => void;
}) {
  const [pouleValues, setPouleValues] = useState<IPoolOptions[]>();
  const [selectedPoolA, setSelectedPoolA] = useState<IPoolOptions>();
  const [selectedPoolB, setSelectedPoolB] = useState<IPoolOptions>();
  const [selectedTeamA, setSelectedTeamA] = useState<IPoolTeam>();
  const [selectedTeamB, setSelectedTeamB] = useState<IPoolTeam>();
  const [teamOptionsA, setTeamOptionsA] = useState<IPoolTeam[]>();
  const [teamOptionsB, setTeamOptionsB] = useState<IPoolTeam[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedValueForPoolA, setSelectedValueForPoolA] = useState(false);
  const [selectedValueForPoolB, setSelectedValueForPoolB] = useState(false);
  const [selectedValueForTeamA, setSelectedValueForTeamA] = useState(false);
  const [selectedValueForTeamB, setSelectedValueForTeamB] = useState(false);

  const onClickSave = () => {
    if (selectedTeamA && selectedTeamB) {
      setIsLoading(true);
      onSave(selectedTeamA?.team.id, selectedTeamB?.team.id);
    }
  };

  useEffect(() => {
    if (poules.length) {
      const values = poules.map((poule) => {
        return { id: poule.name, name: poule?.name, data: poule };
      });
      const options = sortBy<{ name: string; id: string; data: IPool }>(
        uniqBy(
          map(values, (p) => ({ id: p.name, name: `Poule ${p.name}`, data: p.data })),
          "id"
        ),
        (p) => p.id
      );
      setPouleValues(options);
      setSelectedPoolA({ id: options[0].name, name: options[0].name, data: options[0].data });
      setSelectedPoolB({ id: options[0].name, name: options[0].name, data: options[0].data });
    }
  }, [poules]);

  useEffect(() => {
    // @ts-ignore
    setSelectedTeamA();
  }, [teamOptionsA]);

  useEffect(() => {
    // @ts-ignore
    setSelectedTeamB();
  }, [teamOptionsB]);

  return (
    <div style={{ overflow: "unset", scrollbarWidth: "none" }} className="py-2 px-2 block w-[1000px] overflow-scroll">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-xl font-bold ">Teams wisselen</h1>
          <p className="text-md font-light mt-1 ">Kies 2 teams om met elkaar te wisselen.</p>
        </div>
        <div
          onClick={() => {
            onClose();
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
            <g data-name="Layer 2">
              <g data-name="close">
                <rect width="27" height="27" transform="rotate(180 12 12)" opacity="0" />
                <path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" />
              </g>
            </g>
          </svg>
        </div>
      </div>
      {pouleValues?.length && (
        <div className="flex justify-between mt-6">
          <div className="w-full">
            <div className="w-full">
              <Select
                variant={!selectedValueForPoolA ? "selected_value" : null}
                options={(pouleValues?.length && pouleValues.filter((poule) => poule.id !== selectedPoolB?.id)) || []}
                value={selectedPoolA || pouleValues[0]}
                onSelect={(option) => {
                  setSelectedValueForPoolA(true);
                  console.log(pouleValues.find((poule) => poule.id === option.id));
                  setSelectedPoolA({ ...option });
                  setTeamOptionsA(pouleValues.find((poule) => poule.id === option.id)?.data.teams);
                }}
                label="KIES POULE"
              />
            </div>
            <div className="mt-6">
              <Select
                variant={!selectedValueForTeamA ? "selected_value" : null}
                options={selectedPoolA?.data?.teams?.map((team) => ({ name: team.team.name, id: team.team.id })) || []}
                value={
                  selectedTeamA
                    ? { name: selectedTeamA.team.name.substring(0, 20), id: selectedTeamA.team.id }
                    : {
                        id: "Select team",
                        name: "Team",
                      }
                }
                onSelect={(option) => {
                  setSelectedValueForTeamA(true);
                  setSelectedTeamA(selectedPoolA?.data?.teams.find((team) => team.team.id === option.id));
                }}
                label="KIES TEAM"
              />
            </div>
          </div>
          <div className="self-center mx-5">
            <SwapIcon />
          </div>
          <div className="w-full">
            <div className="w-full">
              <Select
                variant={!selectedValueForPoolB ? "selected_value" : null}
                options={(pouleValues?.length && pouleValues.filter((poule) => poule.id !== selectedPoolA?.id)) || []}
                value={selectedPoolB || pouleValues[1]}
                onSelect={(option) => {
                  setSelectedValueForPoolB(true);
                  console.log(pouleValues.find((poule) => poule.id === option.id));
                  setSelectedPoolB({ ...option });
                  setTeamOptionsB(pouleValues.find((poule) => poule.id === option.id)?.data.teams);
                }}
                label="KIES POULE"
              />
            </div>
            <div className="mt-6">
              <Select
                variant={!selectedValueForTeamB ? "selected_value" : null}
                options={selectedPoolB?.data?.teams?.map((team) => ({ name: team.team.name, id: team.team.id })) || []}
                value={
                  selectedTeamB
                    ? { name: selectedTeamB.team.name.substring(0, 20), id: selectedTeamB.team.id }
                    : {
                        id: "Select team",
                        name: "Team",
                      }
                }
                onSelect={(option) => {
                  setSelectedValueForTeamB(true);
                  setSelectedTeamB(selectedPoolB?.data?.teams.find((team) => team.team.id === option.id));
                }}
                label="KIES TEAM"
              />
            </div>
          </div>
        </div>
      )}
      <div>
        <div className="float-right mt-10  flex items-center bottom-10 right-8">
          <div onClick={onClickSave} className="  px-5 py-3 bg-purple-primary text-white rounded-md  ">
            {isLoading ? "Even geduld ..." : "OPSLAAN"}
          </div>
        </div>
      </div>
    </div>
  );
}

export function SwapIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1_238)">
        <path
          d="M21.3333 4L26.6667 9.33333L21.3333 14.6667"
          stroke="#3D68F3"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.3333 9.33333H26.6667"
          stroke="#3D68F3"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.6667 17.3333L5.33333 22.6667L10.6667 28"
          stroke="#3D68F3"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.33333 22.6667H17.3333"
          stroke="#3D68F3"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_238">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
