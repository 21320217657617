import { useSortBy, useTable } from "react-table";
import moment from "moment";
import { IKnockoutGame } from "../../../data/api/types";
import { EditableCell } from "../../../components/EditableCell";

const columns = [
  {
    Header: "Tijd",
    accessor: (d: IKnockoutGame) =>
      `${moment.parseZone(d.startTime).format("HH:mm")} - ${moment.parseZone(d.endTime).format("HH:mm")}`,
  },
  {
    Header: "Team 1",
    accessor: (d: IKnockoutGame) => d.team1 || "-",
  },
  {
    Header: "Team 2",
    accessor: (d: IKnockoutGame) => d.team2 || "-",
  },
  {
    Header: "Scheidsrechter",
    accessor: "referee",
  },
  {
    id: "fieldNumber",
    Header: "Veld",
    Cell: EditableCell,
    accessor: (d: IKnockoutGame) => d.fieldNumber + 1,
  },
];

export function useKnockoutGamesTable(
  data: IKnockoutGame[] = [],
  updateData: (index: number, id: string, value: number) => void,
  rowToEdit?: string
) {
  return useTable(
    {
      // @ts-expect-error
      columns,
      data,

      initialState: {
        // @ts-expect-error
        sortBy: [
          {
            id: "fieldNumber",
            desc: false,
          },
        ],
      },
      autoResetSortBy: false,
      updateData,
      rowToEdit,
    },
    useSortBy
  );
}
