import { fuzzyTextFilter } from "../utils/filters/fuzzyTextFilter";
import { useGlobalFilter, useSortBy, useTable } from "react-table";
import { ITeam } from "../../../data/api/types";

const columns = [
  {
    Header: "Teamnaam",
    accessor: "name",
  },
  {
    Header: "Locatie team",
    accessor: (d: ITeam) => `${d.street} ${d.addition}\n ${d.postalCode}\n ${d.city}`,
  },
  {
    Header: "E-mailadres",
    accessor: "email",
  },
  {
    Header: "Telefoonnummer",
    accessor: "phoneNumber",
  },
];
const filterTypes = {
  fuzzyText: fuzzyTextFilter,
};

export function useTeamsTable(data: ITeam[] = []) {
  return useTable(
    {
      // @ts-expect-error
      columns,
      data,
      filterTypes,
      initialState: {
        // @ts-expect-error
        sortBy: [
          {
            id: "name",
            desc: false,
          },
        ],
      },
      autoResetSortBy: false,
      autoResetGlobalFilter: false,
    },
    useGlobalFilter,
    useSortBy
  );
}
