import React from "react";
import { ToggleButtons } from "../../../components/ToggleButtons";
import { SearchInput } from "../../../components/Input/SearchInput";
import { Button } from "../../../components/Button/Button";
import { TableInstance } from "react-table";
import { ITeam, TeamClass } from "../../../data/api/types";

interface ITeamsPageControlsProps {
  tableInstance: TableInstance<ITeam>;
  toggleTeamClass(teamClass: TeamClass): void;
  teamClass: TeamClass;
  onCreateBtnClick(): void;
  isMixedPoule: boolean;
}

export const TeamsPageControls: React.VFC<ITeamsPageControlsProps> = ({
  tableInstance,
  toggleTeamClass,
  teamClass,
  onCreateBtnClick,
  isMixedPoule,
}) => {
  const {
    state: {
      // @ts-expect-error
      globalFilter,
    },
    // @ts-expect-error
    setGlobalFilter,
  } = tableInstance;

  const handleSearch = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setGlobalFilter(evt.target.value);
  };

  return (
    <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between sm:space-y-0 space-y-3">
      <div className="flex flex-1 items-center space-x-3">
        <div className="-mt-2">
          {isMixedPoule && (
            <ToggleButtons<TeamClass>
              secondary
              onChange={toggleTeamClass}
              value={teamClass}
              buttons={[
                { value: TeamClass.MEN, label: "Heren" },
                { value: TeamClass.WOMEN, label: "Dames" },
              ]}
            />
          )}
        </div>
        <SearchInput
          className="flex-1 max-w-sm"
          id="search_teams"
          label="search_teams"
          placeholder="Zoek op teamnaam"
          value={globalFilter}
          onChange={handleSearch}
        />
      </div>
      <Button secondary className="self-end" label="Team toevoegen" onClick={onCreateBtnClick} />
    </div>
  );
};
