import React, { forwardRef, useState } from "react";
import classNames from "classnames";

type HTMLInputProps = React.ComponentPropsWithoutRef<"input">;

export interface IFileInput extends HTMLInputProps {
  id: string;
  name: string;
  label: string;
  accept?: string;
  uploadedPlaceholder: string;
  onUpload(files: FileList, name?: string): void;
  error?: string;
}

export const FileInput = forwardRef<HTMLInputElement, IFileInput>(
  ({ onUpload, uploadedPlaceholder, label, id, accept, placeholder, className, value, name, error, ...rest }, ref) => {
    const [fileInputKey, setFileInputKey] = useState(0);
    const handleOnUpload: React.ChangeEventHandler<HTMLInputElement> = (evt) => {
      const { files } = evt.target;
      if (files?.length) {
        onUpload(files, name);
        setFileInputKey(Math.random());
      }
    };

    return (
      <label htmlFor={id} className="cursor-pointer">
        <input type="hidden" ref={ref} value={value} {...rest} />
        <div className={classNames("flex flex-col", className)}>
          <label htmlFor={id} className="block font-bold mb-3 text-app-dark-primary text-gray-700 text-lg">
            {label}
          </label>
          <div
            className={classNames("flex-1 flex justify-center px-3 py-7 rounded-md", {
              "flex items-center": value,
              "border bg-app-purple-ultra-light border-app-purple-primary": value && !error,
              "border-2 border-app-dark-light border-dashed bg-app-dark-ultra-light": !value && !error,
              "text-red-error bg-app-red-ultra-light": error,
              "border border-red-error bg-app-red-ultra-light": value && error,
              "border-2 border-app-red-light border-dashed": !value && error,
            })}
          >
            <div className={classNames("space-y-1 text-center", { "flex flex-col justify-center": !value })}>
              {error ? (
                <svg
                  className="h-6 mb-4 mx-auto text-red-error w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0zm4.345 15.04a.924.924 0 11-1.305 1.305L12 13.305l-3.04 3.04a.923.923 0 01-1.305-1.305l3.04-3.04-3.04-3.04A.923.923 0 018.96 7.655l3.04 3.04 3.04-3.04a.923.923 0 011.305 1.305L13.305 12l3.04 3.04z" />
                </svg>
              ) : value ? (
                <svg
                  className="h-6 mb-4 mx-auto text-app-purple-primary w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0zm6.245 7.978l-7.754 9.231a.922.922 0 01-.692.33h-.016a.924.924 0 01-.685-.306L5.774 13.54a.923.923 0 111.372-1.234l2.613 2.903 7.073-8.418a.923.923 0 011.413 1.187z" />
                </svg>
              ) : (
                <svg
                  className="h-12 mb-4 mx-auto text-app-dark-soft w-12"
                  fill="currentColor"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path d="M39.295 9.323C37.76 3.937 32.882 0 27.1 0c-4.358 0-8.204 2.237-10.491 5.653a6.65 6.65 0 00-4.275-1.555c-3.73 0-6.755 3.081-6.755 6.882 0 .643.088 1.263.25 1.855C2.395 14.395 0 17.905 0 21.983 0 27.514 4.398 32 9.824 32h9.818v-8.822h-5.337L23.2 12.512l8.893 10.666h-5.336V32h8.072c6.392 0 11.572-5.282 11.572-11.796 0-4.902-2.931-9.102-7.106-10.88z" />
                </svg>
              )}
              <p
                className={classNames("font-normal text-base", {
                  "text-app-purple-primary": value && !error,
                  "text-app-dark-soft": !value && !error,
                  "text-red-error": !value && error,
                  "text-app-red-soft": !value && error,
                })}
              >
                {error ? error : value ? uploadedPlaceholder : placeholder}
                <input
                  key={fileInputKey}
                  id={id}
                  type="file"
                  accept={accept}
                  onChange={handleOnUpload}
                  className="sr-only"
                  disabled={!!value}
                />
              </p>
            </div>
          </div>
        </div>
      </label>
    );
  }
);
