export function calculateTotalTime(dates: any) {
  let totalMinutes = 0;

  if (dates.length > 0) {
    for (let dateIndex = 0; dateIndex < dates.length; dateIndex++) {
      let { startTime, endTime } = dates[dateIndex];
      let startTimeHours = startTime.split(":")[0];
      let startTimeMinutes = startTime.split(":")[1];
      let endTimeHours = endTime.split(":")[0];
      let endTimeMinutes = endTime.split(":")[1];
      const hourDifference = Math.abs(endTimeHours - startTimeHours);
      const minuteDifference = endTimeMinutes - startTimeMinutes;

      totalMinutes += hourDifference * 60 + minuteDifference;
    }
  }

  return totalMinutes;
}

console.log(
  calculateTotalTime([
    { startTime: "10:00", endTime: "19:00" },
    { startTime: "10:00", endTime: "23:00" },
  ])
);
