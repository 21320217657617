import client from "../client";
import { UseMutationOptions, useMutation } from "react-query";
import { AxiosError } from "axios";
import { ENDPOINTS } from "../../constants";

const QUERY_KEY = "delete-team";

export default function useDeleteTournamentMutation(options?: UseMutationOptions<any, AxiosError, string>) {
  return useMutation(
    [QUERY_KEY],
    async (tournamentId: string) => client.delete(ENDPOINTS.TOURNAMENT_DELETE.replace("{tournamentId}", tournamentId)),
    options
  );
}
