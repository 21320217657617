import { calculateAantalMatchesKnockoutFase } from "./calculateMatchesKnockoutFase";
import { calculateAantalMatchesPouleFase } from "./calculateMatchesPoulefase";
import { veldWisselPouleFase } from "./calculateVeldwisselMinutenPoulefase";
import { veldWisselKnockOutFase } from "./veldWisselMinutenKnockoutFase";

function calculatieMannen({ aantalPoules, variables }: { aantalPoules: number; variables: any }) {
  const veldWisselMinutenPouleFase =
    veldWisselPouleFase({
      aantalPoules: aantalPoules,
      teamsPerPoule: variables.teamsPerPouleMen,
      veldWisselMinuten: variables.swapTimeMinutesPoules,
    }) - variables.swapTimeMinutesPoules || 0;

  const veldWisselMinutenKnockOutFase =
    veldWisselKnockOutFase({
      fase: variables.knockoutStartMen || null,
      veldWisselMinuten: variables.swapTimeMinutesKnockout,
    }) - variables.swapTimeMinutesKnockout || 0;

  const knockoutFaseMinuten =
    calculateAantalMatchesKnockoutFase({ fase: variables.knockoutStartMen || null }) * variables.matchDurationMinutes;

  const pouleFaseMinuten =
    calculateAantalMatchesPouleFase({ aantalPoules: aantalPoules, teamsPerPoule: variables.teamsPerPouleMen }) *
    variables.matchDurationMinutes;

  const wedstrijdMinuten = knockoutFaseMinuten + pouleFaseMinuten;
  const veldWisselMinuten = veldWisselMinutenPouleFase + veldWisselMinutenKnockOutFase;

  return { wedstrijdMinuten, veldWisselMinuten };
}

function calculatieVrouwen({ aantalPoules, variables }: { aantalPoules: number; variables: any }) {
  console.log(aantalPoules, variables);

  const veldWisselMinutenPouleFase =
    veldWisselPouleFase({
      aantalPoules: aantalPoules,
      teamsPerPoule: variables.teamsPerPouleWomen,
      veldWisselMinuten: variables.swapTimeMinutesPoules,
    }) - variables.swapTimeMinutesPoules || 0;

  const veldWisselMinutenKnockOutFase =
    veldWisselKnockOutFase({
      fase: variables.knockoutStartWomen || null,
      veldWisselMinuten: variables.swapTimeMinutesKnockout,
    }) - variables.swapTimeMinutesKnockout || 0;

  const knockoutFaseMinuten =
    calculateAantalMatchesKnockoutFase({ fase: variables.knockoutStartWomen || null }) *
    Math.abs(variables.matchDurationMinutes);

  const pouleFaseMinuten =
    calculateAantalMatchesPouleFase({ aantalPoules: aantalPoules, teamsPerPoule: variables.teamsPerPouleWomen }) *
    Math.abs(variables.matchDurationMinutes);

  console.log(pouleFaseMinuten);

  const wedstrijdMinuten = knockoutFaseMinuten + pouleFaseMinuten;
  const veldWisselMinuten = veldWisselMinutenPouleFase + veldWisselMinutenKnockOutFase;

  return { wedstrijdMinuten, veldWisselMinuten };
}

export function berekenAantalTijdsloten({
  variables,
  teamsCountMen,
  teamsCountWomen,

  totaleTijdVanDagen,
  totaleTijdVanPauze,
}: {
  variables: any;
  teamsCountMen: number;
  teamsCountWomen: number;
  totaleTijdVanDagen: number;
  totaleTijdVanPauze: number;
}) {
  let veldWisselMinuten = 0;
  let totaalWedstrijdMinuten = 0;

  // CALCULATIE VOOR HEREN
  if (variables.teamsPerPouleMen) {
    const { wedstrijdMinuten: wedstrijdMinutenHeren, veldWisselMinuten: veldWisselMinutenHeren } = calculatieMannen({
      variables,
      aantalPoules: teamsCountMen / variables.teamsPerPouleMen,
    });
    totaalWedstrijdMinuten += wedstrijdMinutenHeren;
    veldWisselMinuten += veldWisselMinutenHeren;
  }

  // CALCULATIE VOOR DAMES
  if (variables.teamsPerPouleWomen) {
    const { wedstrijdMinuten: wedstrijdMinutenVrouwen, veldWisselMinuten: veldWisselMinutenVrouwen } =
      calculatieVrouwen({ variables, aantalPoules: teamsCountWomen / variables.teamsPerPouleWomen });
    totaalWedstrijdMinuten += wedstrijdMinutenVrouwen;

    veldWisselMinuten += veldWisselMinutenVrouwen;
  }

  const aantalTijdslotenBeschikbaar =
    ((totaleTijdVanDagen - totaleTijdVanPauze - veldWisselMinuten) / totaalWedstrijdMinuten) * variables.playingFields;

  return aantalTijdslotenBeschikbaar;
}
