import React from "react";
import { DeleteIcon, EditIcon } from "../../../components/icons";
import { TableInstance } from "react-table";
import { ITeam } from "../../../data/api/types";

interface ITeamsTableProps {
  tableInstance: TableInstance<ITeam>;
  loading: boolean;
  onDelete(teamId: string): void;
  onEdit(teamId: string): void;
}

export const TeamsTable: React.VFC<ITeamsTableProps> = ({ tableInstance, loading, onDelete, onEdit }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <div className="flex flex-col small-shadow">
      <div className="overflow-x-auto">
        <div className="align-middle inline-block min-w-full">
          <div className="overflow-y-auto border border-none sm:rounded-md" style={{ maxHeight: "75vh" }}>
            <table {...getTableProps()} className="min-w-full divide-y divide-neutral-100">
              <thead className="bg-neutral-200 sticky top-0">
                <tr>
                  {headerGroups.map((headerGroup) =>
                    headerGroup.headers.map((column) => (
                      <th
                        scope="col"
                        className="px-5 py-3 text-left font-semibold text-neutral-900 tracking-wider"
                        {...column.getHeaderProps()}
                      >
                        {column.render("Header")}
                      </th>
                    ))
                  )}
                  <th scope="col" className="relative px-3 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                  <th scope="col" className="relative px-3 py-3">
                    <span className="sr-only">Delete</span>
                  </th>
                </tr>
              </thead>
              <tbody {...getTableBodyProps()} className="bg-white divide-y divide-neutral-500">
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} className="">
                      {row.cells.map((cell) => {
                        return (
                          <td
                            className="px-5 py-6 whitespace-nowrap text-base text-neutral-900"
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                      <td className="px-3 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <div
                          onClick={() => onEdit(row.original.id)}
                          className="w-9 h-9 bg-purple-ultra-light hover:bg-purple-primary rounded-full border border-purple-primary"
                        >
                          <EditIcon className="w-9 h-9  hover:text-white text-purple-primary cursor-pointer" />
                        </div>
                      </td>
                      <td className="px-3 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <div
                          onClick={() => onDelete(row.original.id)}
                          className="w-9 h-9 bg-red-error rounded-full  border border-red-error"
                        >
                          <DeleteIcon className="w-9 h-9 text-white cursor-pointer" />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {!rows.length && (
              <div className="flex items-center justify-center py-3 capitalize">
                {loading ? "loading" : "Geen teams om weer te geven!"}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
