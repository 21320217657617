import client from "../client";
import { useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { AxiosError } from "axios";
import { ENDPOINTS } from "../../constants";

const QUERY_KEY = "auth";
const QUERY_FUN = () => client.get(ENDPOINTS.AUTH);

function useAuthQuery(options?: UseQueryOptions<any, AxiosError>) {
  return useQuery(QUERY_KEY, QUERY_FUN, options);
}

export function usePrefetchAuthQuery() {
  const client = useQueryClient();
  return () => client.prefetchQuery(QUERY_KEY, QUERY_FUN);
}

export default useAuthQuery;
