import client from "../../client";
import { UseMutationOptions, useMutation } from "react-query";
import { AxiosError } from "axios";
import { ENDPOINTS, MS_TIME } from "../../../constants";
import { TeamClass } from "../types";

const QUERY_KEY = "update-team";

export interface IUpdateTeamFormValues {
  name: string;
  email: string;
  phoneNumber: string;
  street: string;
  streetNumber: string;
  city: string;
  addition: string;
  postalCode: string;
}

export function useUpdateTeamMutation(
  options?: UseMutationOptions<any, AxiosError, { teamId: string; teamClass: TeamClass; data: IUpdateTeamFormValues }>
) {
  return useMutation(
    [QUERY_KEY],
    async ({ teamId, teamClass, data }) =>
      client.patch(ENDPOINTS.UPDATE_TEAM.replace("{teamClass}", teamClass).replace("{teamId}", teamId), data, {
        timeout: MS_TIME.HALF_HOUR,
      }),
    options
  );
}
