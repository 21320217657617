import client from "../../client";
import { UseMutationOptions, useMutation } from "react-query";
import { AxiosError } from "axios";
import { ENDPOINTS, MS_TIME } from "../../../constants";

const QUERY_KEY = "delete-team";

export function useDeleteTeamMutation(options?: UseMutationOptions<any, AxiosError, { teamId: string }>) {
  return useMutation(
    [QUERY_KEY],
    async ({ teamId }) =>
      client.delete(ENDPOINTS.DELETE_TEAM.replace("{teamId}", teamId), { timeout: MS_TIME.HALF_HOUR }),
    options
  );
}
